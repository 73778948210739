import {vi_locale} from 'dayjs/locale/vi';
import {
    calculateAmount,
    convertDayjsDate,
    DATE_SHORT_FORMAT,
    formatCustomerName,
    formatCustomerNameWithId,
    getResponsiveWidth,
    handleApiError,
    handleSuccessMessage,
    handleWarningMessage,
    readGoldWeightUnits,
    readMoneyInVietnameseDong,
    setUserTime
} from "../constants";
import React, {useEffect, useState} from "react";
import {Autocomplete, Grid, InputAdornment, styled, Switch, TextField} from "@mui/material";
import {CUSTOMERS_API_ENDPOINT} from "../pawn/PawnConstants";
import axios from '../axiosInstance';
import {
    CUSTOMER_JEWELRY_ITEM_MODE,
    DEFAULT_ORDER_DATA,
    fetchOrderDetails,
    getAmountDes,
    getExtraBuyJewelries,
    getOrderTypeLabel,
    getTotalAmount,
    getTotalWeight,
    ORDER_TYPE,
    ORDERS_API_ENDPOINT
} from "./OrderConstant";
import BuyItem from "./BuyItem";
import SellItem from "./SellItem";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import QuickCreateCustomer from "../customer/QuickCreateCustomer";
import {handlePrinting} from "./OrderStamp";
import {useAlert} from "../common/AlertProvider";
import CustomLoadingButton from "../common/CustomLoadingButton";
import CustomDialog from "../common/CustomDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import YesNoAlertDialog from "../common/YesNoAlertDialog";
import {useNavigate} from "react-router-dom";

const CreateOrder = ({open, onClose, orderType = ORDER_TYPE.EXEC_ORDER.code}) => {
    const {openAlert} = useAlert();
    const [orderData, setOrderData] = useState(DEFAULT_ORDER_DATA);
    const [isCustomerIdValid, setCustomerIdValid] = useState(true);
    const [customerSuggestions, setCustomerSuggestions] = useState([]);
    const [isTotalAmountValid, setTotalAmountValid] = useState(true);
    const [isBuyAmountValid, setBuyAmountValid] = useState(true);
    const [isSellAmountValid, setSellAmountValid] = useState(true);
    const [isQuickCreateCustomerOpen, setQuickCreateCustomerOpen] = useState(false);
    const [isInvoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceDialogContent, setInvoiceDialogContent] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        setOrderData((prev) => ({...prev, orderType: orderType}));
    }, [orderType]);

    useEffect(() => {
        const residualItems = orderData.buyItems.filter((item) => item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.RESIDUAL.code);
        const exchangeItems = orderData.buyItems.filter((item) => item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.EXCHANGE.code);
        const normalItems = orderData.buyItems.filter((item) => item.itemMode === undefined || item.itemMode === '');

        const extraBuyJewelries = getExtraBuyJewelries(orderData.buyItems);
        const buyAmount = getTotalAmount(residualItems) + getTotalAmount(exchangeItems) + getTotalAmount(normalItems);
        setOrderData((prev) => ({...prev, buyAmount: buyAmount}));

        const sellAmount = orderData.sellItems.reduce((total, item) => total + parseInt(item.amount), 0);
        setOrderData((prev) => ({...prev, sellAmount: sellAmount}));

        const discountAmount = orderData.sellItems.reduce((total, item) => total + parseInt(item.discount), 0);
        setOrderData((prev) => ({...prev, discount: discountAmount}));

        calculateTotalAmount(buyAmount, sellAmount, discountAmount);
        setOrderData((prev) => ({
            ...prev,
            extraGoldWeight: getTotalWeight(extraBuyJewelries),
            extraGoldAmount: getTotalAmount(extraBuyJewelries),
            extraBuyJewelries: extraBuyJewelries
        }));

        setTotalAmountValid(true);
        setBuyAmountValid(true);
        setSellAmountValid(true);
    }, [orderData.buyItems, orderData.sellItems]);

    const calculateTotalAmount = (buyAmount = 0, sellAmount = 0, discount = 0) => {
        const totalAmount = sellAmount - buyAmount - discount;
        setOrderData((prev) => ({...prev, totalAmount: totalAmount}));
    };

    const handleCustomerSelect = (event, selectedValue) => {
        const selectedCustomerId = selectedValue ? selectedValue.value : null;
        setCustomerIdValid(!!selectedCustomerId);
        setOrderData((prevData) => ({
            ...prevData,
            customerId: selectedCustomerId,
        }));
    };
    const fetchCustomerSuggestions = async (inputValue, visitingGuest = false) => {
        try {
            const response = await axios.get(CUSTOMERS_API_ENDPOINT + "/search", {
                params: {query: inputValue, guest: visitingGuest},
            });
            return response.data;
        } catch (error) {
            console.error('Error while fetching customer suggestions:', error);
            return [];
        }
    };
    const handleCustomerInputChange = async (event, newInputValue) => {
        setOrderData((prev) => ({
            ...prev,
            customerName: newInputValue,
        }));
        if (!orderData.visitingGuest && newInputValue && newInputValue.trim() !== '') {
            const suggestions = await fetchCustomerSuggestions(newInputValue, orderData.visitingGuest);
            setCustomerSuggestions(suggestions);
        }
    };
    const onCreateOrder = async (orderData) => {
        try {
            const response = await axios.post(ORDERS_API_ENDPOINT, orderData);
            const orderCompletedMsg = 'Đơn hàng số [' + response.data.orderId + '] được tạo thành công.';
            handleSuccessMessage(orderCompletedMsg, openAlert);

            onClose();
            setOrderData((prev) => ({
                ...prev,
                orderId: response.data.orderId,
            }));
            setInvoiceDialogContent(orderCompletedMsg + '\n Bạn có muốn xuất hóa đơn thuế không?')
            //setInvoiceDialogOpen(true);
            return response.data;
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const handleInvoiceDialogClose = () => {
        setInvoiceDialogOpen(false);
    };
    const handleQuickCreateCustomerSuccess = (createdCustomer) => {
        setOrderData((prevData) => ({
            ...prevData,
            customerId: createdCustomer.customerId,
        }));
        setCustomerIdValid(true);
        setQuickCreateCustomerOpen(false);
    };
    const handleSave = async () => {
        let validated = true;
        if (orderData.visitingGuest === false) {
            if (orderData.customerId === 0) {
                setCustomerIdValid(false);
                validated = false;
            } else {
                setCustomerIdValid(true);
            }
            if (orderData.customerId === 0 && orderData.customerName.trim() !== "") {
                setQuickCreateCustomerOpen(true);
            }
        } else if (orderData.customerName === '') {
            setCustomerIdValid(false);
            validated = false;
        }
        if (validated === true) {
            const savedOrder = await onCreateOrder(orderData);
            setOrderData(DEFAULT_ORDER_DATA);
            setOrderData((prevData) => ({
                ...prevData,
                orderType: orderType,
            }));
            return savedOrder;
        } else {
            handleWarningMessage("Vui lòng nhập đủ thông tin!", openAlert);
        }
    };
    const handleSaveAndPrint = async () => {
        const savedOrder = await handleSave();
        if (savedOrder) {
            const order = await fetchOrderDetails(savedOrder.orderId);
            const extraBuyJewelries = getExtraBuyJewelries(orderData.buyItems);
            const formattedData = {
                ...order,
                orderDate: convertDayjsDate(order.orderDate),
                customerName: formatCustomerName(order.customer),
                sellItems: order.sellItems?.map((item) => ({
                    ...item,
                    id: item.itemId,
                    goldAmount: calculateAmount(item.goldWeight, item.price),
                })),
                buyItems: order.buyItems?.map((item) => ({
                    ...item,
                    id: item.itemId,
                })),
                extraBuyJewelries: extraBuyJewelries,
                extraGoldWeight: getTotalWeight(extraBuyJewelries),
                extraGoldAmount: getTotalAmount(extraBuyJewelries),
            };
            await handlePrinting(formattedData);
        }
    }
    const Div = styled('div')(({theme}) => ({
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        textAlign: 'right',
        fontWeight: 'bold',
    }));
    const handleClose = async () => {
        onClose();
    };

    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleSaveAndPrint}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu và in"/>
            <CustomLoadingButton variant="contained" onClick={handleSave}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Chỉ Lưu"/>
        </>
    );
    const pageTitle = (
        <>
            {`Tạo mới đơn hàng: ${getOrderTypeLabel(orderType)}`}
        </>
    );
    const handleAcceptNotice = () => {
        setInvoiceDialogOpen(false);
        navigate('/invoice-mgmt', {state: {orderId: orderData.orderId, createInvoice: true}});
    };
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 3)}>
                        <FormControlLabel control={
                            <Switch
                                checked={orderData.visitingGuest}
                                onChange={(e) => {
                                    setOrderData((prev) => ({
                                        ...prev,
                                        visitingGuest: e.target.checked,
                                    }));
                                    handleCustomerInputChange(e, orderData.customerName);
                                }}/>
                        }
                                          label="Khách vãng lai?"/>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 9)}>
                        <Autocomplete
                            freeSolo
                            options={customerSuggestions.map((option) => ({
                                label: formatCustomerNameWithId(option),
                                value: option.customerId,
                            }))}
                            getOptionLabel={(option) => (option ? option.label : '')}
                            onChange={handleCustomerSelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e) => {
                                        handleCustomerInputChange(e, e.target.value);
                                    }}
                                    error={!isCustomerIdValid}
                                    label="Tên khách hàng"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    < /Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                            <DatePicker label="Ngày tạo"
                                        value={orderData.orderDate}
                                        onChange={(date) => {
                                            setOrderData((prev) => ({
                                                ...prev,
                                                orderDate: setUserTime(date),
                                            }));
                                        }}
                                        fullWidth
                                        format={DATE_SHORT_FORMAT}
                                        slotProps={{
                                            field: {shouldRespectLeadingZeros: true},
                                            textField: {fullWidth: true}
                                        }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Ghi chú"
                            value={orderData.orderDescription}
                            onChange={(e) =>
                                setOrderData((prev) => ({
                                    ...prev,
                                    orderDescription: e.target.value,
                                }))
                            }
                            fullWidth
                        />
                    </Grid>
                    {(ORDER_TYPE.SELL_ORDER.code === orderType || ORDER_TYPE.EXEC_ORDER.code === orderType) && (
                        <Grid item xs={12}><SellItem orderData={orderData} setOrderData={setOrderData}/></Grid>)}
                    {(ORDER_TYPE.BUY_ORDER.code === orderType || ORDER_TYPE.EXEC_ORDER.code === orderType) && (
                        <Grid item xs={12}><BuyItem orderData={orderData} setOrderData={setOrderData}/></Grid>)}
                    {(ORDER_TYPE.SELL_ORDER.code === orderType || ORDER_TYPE.EXEC_ORDER.code === orderType) && (
                        <Grid item xs={12} container>
                            <Grid item xs={7}><Div>{"Tiền vàng mới(Công): "}</Div></Grid>
                            <Grid item xs={5}>
                                <TextField
                                    size="small"
                                    value={Number(orderData.sellAmount).toLocaleString()}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                        setOrderData((prev) => ({
                                            ...prev,
                                            sellAmount: numericInput,
                                        }));
                                        calculateTotalAmount(orderData.buyAmount, numericInput, orderData.discount);
                                    }}
                                    fullWidth
                                    error={!isSellAmountValid}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>)}
                    {(ORDER_TYPE.EXEC_ORDER.code === orderType) && (
                        <Grid item xs={12} container>
                            <Grid item
                                  xs={7}><Div>{`Vàng thêm/dư: ${readGoldWeightUnits(orderData.extraGoldWeight)}`}</Div>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    size="small"
                                    readOnly disabled
                                    value={Number(orderData.extraGoldAmount).toLocaleString()}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>)}
                    {(ORDER_TYPE.BUY_ORDER.code === orderType || ORDER_TYPE.EXEC_ORDER.code === orderType) && (
                        <Grid item xs={12} container>
                            <Grid item xs={7}><Div>{"Tiền vàng khách: "}</Div></Grid>
                            <Grid item xs={5}>
                                <TextField
                                    size="small"
                                    value={Number(orderData.buyAmount).toLocaleString()}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                        setOrderData((prev) => ({
                                            ...prev,
                                            buyAmount: numericInput,
                                        }));
                                        calculateTotalAmount(numericInput, orderData.sellAmount, orderData.discount);
                                    }}
                                    fullWidth
                                    error={!isBuyAmountValid}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>)}
                    {(ORDER_TYPE.SELL_ORDER.code === orderType || ORDER_TYPE.EXEC_ORDER.code === orderType) && (
                        <Grid item xs={12} container>
                            <Grid item xs={7}><Div>{"Tiền giảm giá: "}</Div></Grid>
                            <Grid item xs={5}>
                                <TextField
                                    size="small"
                                    value={Number(orderData.discount).toLocaleString()}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                        setOrderData((prev) => ({
                                            ...prev,
                                            discount: numericInput,
                                        }));
                                        calculateTotalAmount(orderData.buyAmount, orderData.sellAmount, numericInput);
                                    }}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid></Grid>
                    )}
                    <Grid item xs={12} container>
                        <Grid item
                              xs={7}><Div>{"Tổng thanh toán"} {getAmountDes(orderData.totalAmount)}{": "}</Div></Grid>
                        <Grid item xs={5}>
                            <TextField
                                size="small"
                                value={Number(orderData.totalAmount).toLocaleString()}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                    setOrderData((prev) => ({
                                        ...prev,
                                        totalAmount: numericInput,
                                    }));
                                    setTotalAmountValid(true);
                                }}
                                fullWidth
                                error={!isTotalAmountValid}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">vnđ</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid></Grid>
                    <Grid item
                          xs={12}><Div>{"Bằng chữ:"} {readMoneyInVietnameseDong(orderData.totalAmount)}</Div></Grid>
                </Grid>
            </CustomDialog>
            <QuickCreateCustomer
                open={isQuickCreateCustomerOpen}
                onClose={() => setQuickCreateCustomerOpen(false)}
                onCreateSuccess={handleQuickCreateCustomerSuccess}
                inputValue={orderData.customerName}
            />
            <YesNoAlertDialog open={isInvoiceDialogOpen} onClose={handleInvoiceDialogClose}
                              content={invoiceDialogContent}
                              onAccept={handleAcceptNotice}/>
        </div>
    );
}

export default CreateOrder;
