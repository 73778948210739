import React, {useEffect, useState} from "react";
import CustomLoadingButton from "../common/CustomLoadingButton";
import {Grid, IconButton, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import CustomDialog from "../common/CustomDialog";
import {getResponsiveWidth, JewelryStampTypes} from "../constants";
import CustomAccordion from "../common/CustomAccordion";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {usePermissions} from "../route/PermissionProvider";

const ShopInfoDetails = ({open, onClose, sourceData, onItemUpdated}) => {
    const permissions = usePermissions();
    const [shopInfo, setShopInfo] = useState(sourceData);
    const [expanded, setExpanded] = React.useState('panel0');
    const [showPassword, setShowPassword] = useState(false);
    const handleChangeAccordion = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        setShopInfo(sourceData);
    }, [open, sourceData]);
    const handleClose = async () => {
        onClose();
    };

    const handleUpdatedItem = async () => {
        let validated = true;
        //todo to validate request data
        if (validated) {
            return onItemUpdated(shopInfo);
        }
    }

    const formActions = (
        <>
            {permissions.SYSTEMS?.includes('UPDATE_SHOP_INFO') && (
                <CustomLoadingButton variant="contained" onClick={handleUpdatedItem}
                                     sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu thay đổi"/>
            )}
        </>
    );
    const pageTitle = (
        <>
            {`Xem/Cập nhật thông tin tiệm`}
        </>
    );
    return (
        <>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <CustomAccordion
                    title="Thông tin chung cửa tiệm"
                    panel='panel0'
                    expanded={expanded}
                    onChangeAccordion={handleChangeAccordion}>
                    <Grid container spacing={2}>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                readOnly disabled
                                label="Ký hiệu tiệm"
                                value={shopInfo.code ? shopInfo.code : ''}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                label="Tên tiệm rút gọn"
                                value={shopInfo.shortName ? shopInfo.shortName : ''}
                                fullWidth
                                InputProps={{type: 'search'}}
                                onChange={(e) => {
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        shortName: e.target.value,
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                label="Tên tiệm đầy đủ"
                                value={shopInfo.name ? shopInfo.name : ''}
                                fullWidth
                                InputProps={{type: 'search'}}
                                onChange={(e) => {
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        name: e.target.value,
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                label="Địa chỉ đầy đủ"
                                value={shopInfo.address ? shopInfo.address : ''}
                                fullWidth
                                InputProps={{type: 'search'}}
                                onChange={(e) => {
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        address: e.target.value,
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                label="Địa chỉ rút gọn"
                                value={shopInfo.shortAddress ? shopInfo.shortAddress : ''}
                                fullWidth
                                InputProps={{type: 'search'}}
                                onChange={(e) => {
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        shortAddress: e.target.value,
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                label="Ghi chú"
                                value={shopInfo.note ? shopInfo.note : ''}
                                fullWidth
                                InputProps={{type: 'search'}}
                                onChange={(e) => {
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        note: e.target.value,
                                    }));
                                }}
                            />
                        </Grid>
                    </Grid>
                </CustomAccordion>
                <CustomAccordion
                    title="Thông tin hệ thống hóa đơn điện tử"
                    panel='panel1'
                    expanded={expanded}
                    onChangeAccordion={handleChangeAccordion}>
                    <Grid container spacing={2}>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                required={true}
                                label="Mã số thuế doanh nghiệp"
                                value={shopInfo.supplierTaxCode ? shopInfo.supplierTaxCode : ''}
                                fullWidth
                                InputProps={{type: 'search'}}
                                onChange={(e) => {
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        supplierTaxCode: e.target.value,
                                    }));
                                }}
                                inputProps={{maxLength: 15}}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 6)}>
                            <TextField
                                required={true}
                                label="Mẫu số hóa đơn"
                                value={shopInfo.templateCode ? shopInfo.templateCode : ''}
                                fullWidth
                                InputProps={{type: 'search'}}
                                onChange={(e) => {
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        templateCode: e.target.value,
                                    }));
                                }}
                                inputProps={{maxLength: 15}}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 6)}>
                            <TextField
                                required={true}
                                label="Mẫu số - Ký hiệu (Serial No.)"
                                value={shopInfo.invoiceSeries ? shopInfo.invoiceSeries : ''}
                                fullWidth
                                InputProps={{type: 'search'}}
                                onChange={(e) => {
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        invoiceSeries: e.target.value,
                                    }));
                                }}
                                inputProps={{maxLength: 15}}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 6)}>
                            <TextField
                                required={true}
                                label="Tên đăng nhập hệ thống HĐĐT"
                                value={shopInfo.invoiceUserName ? shopInfo.invoiceUserName : ''}
                                fullWidth
                                InputProps={{type: 'search'}}
                                onChange={(e) => {
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        invoiceUserName: e.target.value,
                                    }));
                                }}
                                inputProps={{maxLength: 50}}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 6)}>
                            <TextField
                                required
                                fullWidth
                                value={shopInfo.invoicePassword ? shopInfo.invoicePassword : ''}
                                label="Mật khẩu hệ thống HĐĐT"
                                type={showPassword ? "text" : "password"}
                                onChange={(e) => {
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        invoicePassword: e.target.value,
                                    }));
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Nhấn vào hiện mật khẩu"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </CustomAccordion>
                <CustomAccordion
                    title="Thông tin tem và biên nhận"
                    panel='panel2'
                    expanded={expanded}
                    onChangeAccordion={handleChangeAccordion}>
                    <Grid container spacing={2}>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <Select
                                value={shopInfo.jewelryStampType}
                                fullWidth
                                onChange={(e) =>
                                    setShopInfo((prev) => ({
                                        ...prev,
                                        jewelryStampType: e.target.value,
                                    }))
                                }
                            >
                                {JewelryStampTypes.map((type) => (
                                    <MenuItem key={type.code} value={type.code}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </CustomAccordion>
            </CustomDialog>
        </>
    )
}
export default ShopInfoDetails;
