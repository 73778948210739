import React, {useEffect, useState} from 'react';
import Link from '@mui/material/Link';
import KpiTitle from "./KpiTitle";
import Box from "@mui/material/Box";
import axios from "../axiosInstance";
import {PAWNS_API_ENDPOINT, PawnStatus} from "../pawn/PawnConstants";
import {createDateFilter, formatCurrency, handleApiError, setEndOfTheDate, setStartOfTheDate} from "../constants";
import {useAlert} from "../common/AlertProvider";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import EastIcon from "@mui/icons-material/East";
import {CircularProgress} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

const renderTypography = (label, count, amount, filterLabel, onClick) => (
    <Typography variant="overline" display="block">
        {label}{filterLabel ? ' ' + filterLabel : ''}: {count} | {amount}
        <EastIcon color="primary" onClick={onClick} sx={{fontSize: 15, cursor: 'pointer'}}/>
    </Typography>
);

const createPawnFilter = (filter = undefined, pawnStatus = [PawnStatus.PAWNED]) => {
    return {
        ...(filter && {...filter}),
        pawnStatuses: pawnStatus
    }
}
const renderDetailSection = (pawnKPI, filterLabel, navigate, dateFilter) => {
    const filter = dateFilter ? dateFilter : {
        fromDate: setStartOfTheDate(new Date()),
        toDate: setEndOfTheDate(new Date())
    };
    return (
        <>
            {renderTypography(`Đơn mới`, pawnKPI.newPawnsCount, formatCurrency(pawnKPI.newPawnsAmount), filterLabel, () => navigate(`/pawn-mgmt`, {state: {filter: createPawnFilter(createDateFilter("pawnDate", filter.fromDate, filter.toDate), [PawnStatus.PAWNED])}}))}
            {renderTypography(`Chuộc`, pawnKPI.completedPawnCount, formatCurrency(pawnKPI.completedPawnAmount), filterLabel, () => navigate(`/pawn-mgmt`, {state: {filter: createPawnFilter(createDateFilter("redeemDate", filter.fromDate, filter.toDate), [PawnStatus.REDEEMED])}}))}
            {renderTypography(`Thanh lý`, pawnKPI.forfeitedPawnCount, formatCurrency(pawnKPI.forfeitedPawnAmount), filterLabel, () => navigate(`/pawn-mgmt`, {state: {filter: createPawnFilter(createDateFilter("forfeitedDate", filter.fromDate, filter.toDate), [PawnStatus.FORFEITED])}}))}
            {renderTypography(`Lấy thêm`, pawnKPI.newRequestMoneyCount, formatCurrency(pawnKPI.newRequestMoneyAmount), filterLabel, () => navigate(`/pawn-mgmt`, {state: {filter: createPawnFilter(createDateFilter("requestDate", filter.fromDate, filter.toDate), [PawnStatus.PAWNED])}}))}
            {renderTypography(`Quá hạn`, pawnKPI.overdueCount, formatCurrency(pawnKPI.overdueAmount), undefined, () => navigate(`/pawn-mgmt`, {state: {filter: createPawnFilter(createDateFilter("pawnDueDate", undefined, filter.fromDate), [PawnStatus.PAWNED])}}))}
            {renderTypography(`Đến hạn`, pawnKPI.dueTodayCount, formatCurrency(pawnKPI.dueTodayAmount), filterLabel, () => navigate(`/pawn-mgmt`, {state: {filter: createPawnFilter(createDateFilter("pawnDueDate", filter.fromDate, filter.toDate), [PawnStatus.PAWNED])}}))}
            <Typography variant="overline" display="block">
                Thu lãi {filterLabel}: {formatCurrency(pawnKPI.interestPawnAmount)}
            </Typography>
        </>
    )
};
const PawnKpiSection = () => {
    const {openAlert} = useAlert();
    const [loading, setLoading] = useState(true);
    const [pawnKPI, setPawnKPI] = useState({
        totalPawnedCount: 0,
        totalPawnedAmount: 0,
        dueTodayCount: 0,
        dueTodayAmount: 0,
        overdueCount: 0,
        overdueAmount: 0,
        newPawnsCount: 0,
        newPawnsAmount: 0,
        completedPawnCount: 0,
        completedPawnAmount: 0,
        newRequestMoneyCount: 0,
        newRequestMoneyAmount: 0,
        interestPawnAmount: 0,
        forfeitedPawnAmount: 0,
        forfeitedPawnCount: 0,
    });
    const navigate = useNavigate();
    const [filterLabel, setFilterLabel] = useState('[Hôm nay]');
    const [dateFilter, setDateFilter] = useState();
    const fetchPawnKPIs = async (filter = undefined) => {
        try {
            setLoading(true);
            const now = new Date();
            const requestBody = filter ? filter : {fromDate: setStartOfTheDate(now), toDate: setEndOfTheDate(now)}
            const response = await axios.post(`${PAWNS_API_ENDPOINT}/kpi-section`, requestBody);
            setPawnKPI(response.data);
            return response.data;
        } catch (error) {
            handleApiError(error, openAlert)
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            await fetchPawnKPIs();
        };
        fetchData();
        // eslint-disable-next-line
    }, []);
    const onMarkChanged = async (mark) => {
        const filter = {fromDate: mark.fromDate, toDate: mark.toDate}
        await fetchPawnKPIs(filter);
        setDateFilter(filter);
        setFilterLabel(mark.text);
    }
    return (
        <>
            <KpiTitle
                label="Cầm đồ"
                showSlider={true}
                onMarkSelected={onMarkChanged}
            >
            </KpiTitle>
            {(loading) ? (
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            ) : (<>
                    <Typography component="p" variant="h6">
                        Tổng: {pawnKPI.totalPawnedCount} đơn |
                        {formatCurrency(pawnKPI.totalPawnedAmount)}
                    </Typography>
                    {renderDetailSection(pawnKPI, filterLabel, navigate, dateFilter)}
                    <Box component="section" sx={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', mt: 2 }}>
                        <Link color="primary" sx={{cursor: 'pointer'}} onClick={() => navigate(`/pawn-mgmt`)}>
                            Xem thêm
                        </Link>
                    </Box></>
            )}
        </>
    );
};

export default PawnKpiSection;
