import {Box, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    CREATE_JEWELRY_ACTIONS,
    DEFAULT_FILTER,
    DEFAULT_JEWELRY_DATA,
    exportJewelries,
    fetchJewelryDetails,
    findJewelries,
    getCounterLabelById,
    JEWELRY_API_ENDPOINT,
    mapSellStatusToText,
    mapStatusToText,
    SELECTED_JEWELRY_ACTIONS
} from "./JewelryConstants";
import CreateJewelry from "./CreateJewelry";
import {
    ADMINISTRATOR_USER_NAME,
    buildFilterObject,
    convertDayjsDate,
    DATE_LONG_FORMAT,
    fetchSystemInfo,
    formatCurrency,
    formatDateTime,
    getCurrentUser,
    getGoldBrandLabelByCode, getGoldTypeByCode,
    getGoldTypeLabelByCode,
    getJewelryStampTypeByCode,
    getResponsiveWidth,
    handleApiError,
    handleErrorMessage,
    handleSuccessMessage,
    readGoldWeightUnits,
    UNKNOWN_ACTIONS
} from "../constants";
import UpdateJewelry from "./UpdateJewelry";
import {useAlert} from "../common/AlertProvider";
import DropDownButton from "../common/DropDownButton";
import {useLocation} from "react-router-dom";
import CustomDataGrid from "../common/CustomDataGrid";
import {handlePrinting16x30} from "./JewelryStamp16x30";
import {handlePrinting} from "./JewelryStamp";
import {usePermissions} from "../route/PermissionProvider";
import YesNoAlertDialog from "../common/YesNoAlertDialog";
import axios from "../axiosInstance";
import CreateSilverJewelry from "./CreateSilverJewelry";
import {handleSilverPrinting} from "./JewelrySilverStamp";
import {handleSilverPrinting16x30} from "./JewelrySilverStamp16x30";
import UpdateSilverJewelry from "./UpdateSilverJewelry";

const columns = [
    {field: 'jewelryId', headerName: 'Mã số', width: 120},
    {field: 'name', headerName: 'Món hàng', width: 150},
    {
        field: 'goldType', headerName: 'Tuổi', width: 150,
        valueFormatter: (params) => getGoldTypeLabelByCode(params.value)
    },
    {
        field: 'brand', headerName: 'Chành', width: 150,
        valueFormatter: (params) => getGoldBrandLabelByCode(params.value)
    },
    {
        field: 'goldWeight',
        headerName: 'TL Vàng',
        width: 150,
        type: 'number',
        valueFormatter: (params) => readGoldWeightUnits(params.value)
    },
    {field: 'gemWeight', headerName: 'TL Hột', width: 150},
    {
        field: 'totalWeight',
        headerName: 'TL Tổng',
        width: 150,
        type: 'number',
        valueFormatter: (params) => readGoldWeightUnits(params.value)
    },
    {
        field: 'goldPrice',
        headerName: 'Đơn giá',
        width: 150,
        type: 'number',
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'price',
        headerName: 'Thành tiền',
        width: 150,
        type: 'number',
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'procPrice',
        headerName: 'Tiền công',
        width: 150,
        type: 'number',
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'totalPrice',
        headerName: 'Tổng tiền',
        width: 150,
        type: 'number',
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'sellProcPrice', headerName: 'Công bán', width: 150,
        type: 'number',
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'exchangeProcPrice', headerName: 'Công đổi', width: 150,
        type: 'number',
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {field: 'quantity', headerName: 'Số lượng', width: 150},
    {
        field: 'counterId', headerName: 'Quầy', width: 150,
        valueFormatter: (params) => getCounterLabelById(params.value)
    },
    {field: 'symbol', headerName: 'Ký hiệu', width: 150},
    {
        field: 'status', headerName: 'Trạng thái', width: 150,
        valueFormatter: (params) => mapStatusToText(params.value)
    },
    {
        field: 'sellStatus', headerName: 'Tình trạng', width: 150,
        valueFormatter: (params) => mapSellStatusToText(params.value)
    },
    {field: 'description', headerName: 'Mô tả'},
    {field: 'createdBy', headerName: 'Người tạo', width: 150},
    {
        field: 'createdAt', headerName: 'Ngày tạo', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
    {field: 'updatedBy', headerName: 'Người cập nhật', width: 150},
    {
        field: 'updatedAt', headerName: 'Ngày cập nhật', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
];
const JewelryGrid = () => {
    const permissions = usePermissions();
    const {openAlert} = useAlert();
    const [jewelries, setJewelries] = useState([]);
    const [jewelryData, setJewelryData] = useState(DEFAULT_JEWELRY_DATA);
    const [loading, setLoading] = useState(true);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openCreateSilverDialog, setOpenCreateSilverDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [statusUpdateDialog, setStatusUpdateDialog] = useState(false);
    const [openUpdateJewelryDialog, setOpenUpdateJewelryDialog] = useState(false);
    const [openUpdateSilverJewelry, setOpenUpdateSilverJewelry] = useState(false);
    const [selectedAction, setSelectedAction] = React.useState('RECEIPT');
    const [selectedJewelry, setSelectedJewelry] = useState();
    const location = useLocation();
    const [statusUpdateContent, setStatusUpdateContent] = useState('');
    const [selectedCreateAction, setSelectedCreateAction] = React.useState('GOLD');
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 100,
        totalRowCount: 100,
        summary: {}
    });

    const fetchJewelries = async (pageNumber, pageSize, requestFilter) => {
        try {
            setLoading(true);
            const response = await findJewelries(pageNumber, pageSize, requestFilter);
            const formattedJewelries = response.data.content.map((jewelry) => ({
                ...jewelry,
                id: jewelry.jewelryId,
                goldWeight: jewelry.goldWeight,
                gemWeight: jewelry.gemWeight,
                totalWeight: jewelry.totalWeight,
                goldPrice: jewelry.goldPrice,
                price: jewelry.price,
                procPrice: jewelry.procPrice,
                totalPrice: jewelry.totalPrice,
                totalPriceRaw: jewelry.totalPrice,
                sellProcPrice: jewelry.sellProcPrice,
                exchangeProcPrice: jewelry.exchangeProcPrice,
                goldType: jewelry.goldType,
                brand: jewelry.brand,
                status: jewelry.status,
                sellStatus: jewelry.sellStatus,
                counterId: jewelry.counterId,
            }))
            setJewelries(formattedJewelries);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalRowCount: response?.data?.totalElements,
                pageSize: response?.data?.size,
                totalPages: response.data.totalPages,
                currentPage: response.data.number,
                summary: calculateSummary(response.data.content, response?.data?.totalElements),
            }));
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setLoading(false);
        }
    }
    const calculateSummary = (jewelries, totalElements) => {
        const totalItem = 'Số món: ' + jewelries.length;
        const amount = jewelries.reduce((sum, item) => sum + (item.goldWeight || 0), 0);
        const totalAmount = ' Tổng TL: ' + readGoldWeightUnits(amount);
        return {totalItem, totalAmount, totalElements};
    };
    const getJewelryDetails = async (jewelryId) => {
        try {
            const response = await fetchJewelryDetails(jewelryId);
            setJewelryData(response);
            return response;
        } catch (error) {
            handleApiError(error, openAlert);
            throw error;
        }
    };
    const handleUpdateClick = async (jewelryId) => {
        const jewelry = await getJewelryDetails(jewelryId);
        const goldType = getGoldTypeByCode(jewelry.goldType);
        if (goldType.silverType) {
            setOpenUpdateSilverJewelry(true);
        } else {
            setOpenUpdateJewelryDialog(true);
        }
    };
    const handleChangePage = async (page, event) => {
        setPagination((prevPage) => ({
            ...prevPage,
            page: page.page,
            pageSize: page.pageSize,
        }));
    };
    useEffect(() => {
        const {filter} = location.state || {};
        setPagination((prevPage) => ({
            ...prevPage,
            filter: (filter ? buildFilterObject(filter) : DEFAULT_FILTER),
        }));
    }, [location.state]);

    useEffect(() => {
        const fetchData = async () => {
            const requestFilter = pagination.filter ? pagination.filter : DEFAULT_FILTER;
            await fetchJewelries(pagination.page, pagination.pageSize, requestFilter);
        };
        fetchData();
        // eslint-disable-next-line
    }, [pagination.pageSize, pagination.page, pagination.filter]);

    const handlePrintClick = async (jewelryId, action) => {
        const jewelry = await getJewelryDetails(jewelryId);
        if (jewelry) {
            const jewelryStampType = getJewelryStampTypeByCode();
            const goldType = getGoldTypeByCode(jewelry.goldType);
            if (goldType.silverType) {
                jewelry.silverType = goldType.silverType;
                jewelry.goldTypeName = goldType.name ? goldType.name : goldType.code;
                if (jewelryStampType.code === '16x30') {
                    await handleSilverPrinting16x30(jewelry);
                } else {
                    await handleSilverPrinting(jewelry);
                }
            } else {
                if (jewelryStampType.code === '16x30') {
                    await handlePrinting16x30(jewelry);
                } else {
                    await handlePrinting(jewelry);
                }
            }
        }
    }
    const handleDeleteClick = async (jewelryId) => {
        await getJewelryDetails(jewelryId)
        setOpenDeleteDialog(true);
    }

    const handleStatusUpdateClick = async (jewelryId) => {
        const item = await getJewelryDetails(jewelryId);
        if (item.visible) {
            setJewelryData(prev => ({
                ...prev,
                visible: false,
            }));
            setStatusUpdateContent(`Món hàng [${item.name}] đang ở trạng thái [hiển thị], Bạn muốn ẩn nó?`);
        } else {
            setJewelryData(prev => ({
                ...prev,
                visible: true,
            }));
            setStatusUpdateContent(`Món hàng [${item.name}] đang ở trạng thái [ẩn], Bạn muốn hiển thị nó?`)
        }
        setStatusUpdateDialog(true);
    }
    const handleSetSelectedRow = async (selectedRow, rows) => {
        try {
            const selectedId = selectedRow.length === 1 ? selectedRow[0] : 0;
            const rowData = rows.find((row) => row.jewelryId === selectedId);
            setSelectedJewelry(rowData);
        } catch (error) {
            console.log("Error at process selected item", selectedRow);
        }
    };
    const handleActionClick = async (action) => {
        try {
            setLoading(true);
            if (!selectedJewelry) return;
            const jewelryId = selectedJewelry.jewelryId;
            await fetchSystemInfo(openAlert);
            switch (action) {
                case 'UPDATED': {
                    return await handleUpdateClick(jewelryId);
                }
                case 'DELETE': {
                    return await handleDeleteClick(jewelryId);
                }
                case 'RECEIPT': {
                    return await handlePrintClick(jewelryId, action);
                }
                case 'UPDATED_STATUS': {
                    return await handleStatusUpdateClick(jewelryId);
                }
                case 'UNKNOWN': {
                    return;
                }
                default: {
                    return;
                }
            }
        } catch (error) {
            handleErrorMessage(error.message, openAlert);
        } finally {
            setLoading(false);
        }
    };
    const handleCreateGoldClick = () => {
        setOpenAddDialog(true);
    };
    const handleCreateSilverClick = () => {
        setOpenCreateSilverDialog(true);
    };
    const handleCreateActionClick = async (action) => {
        await fetchSystemInfo(openAlert);
        switch (action) {
            case 'GOLD': {
                return handleCreateGoldClick();
            }
            case 'SILVER': {
                return handleCreateSilverClick();
            }
            default: {
                return handleCreateGoldClick();
            }
        }
    };

    const handleMenuItemClick = (event, selectedAction) => {
        setSelectedAction(selectedAction);
    };
    const handleCreateMenuItemClick = (event, selectedAction) => {
        setSelectedCreateAction(selectedAction);
    };
    const getAvailableOptions = () => {
        if (!permissions || !permissions['INVENTORY']) {
            return UNKNOWN_ACTIONS;
        }
        const actions = SELECTED_JEWELRY_ACTIONS.filter(action =>
            permissions.INVENTORY?.includes(action.permission) &&
            (!action.administration || (action.administration && ADMINISTRATOR_USER_NAME === getCurrentUser()))
        );
        if (actions.length > 0 && !actions.find(action => action.code === selectedAction)) {
            setSelectedAction(actions[0].code);
        } else if (actions.length === 0) {
            return UNKNOWN_ACTIONS;
        }
        return actions;
    }
    const disableButton = () => {
        return !selectedJewelry;
    }

    const handleFindById = async (jewelryId) => {
        const jewelry = await getJewelryDetails(jewelryId);
        if (jewelry) {
            const goldType = getGoldTypeByCode(jewelry.goldType);
            if (goldType.silverType) {
                setOpenUpdateSilverJewelry(true);
            } else {
                setOpenUpdateJewelryDialog(true);
            }
        }
    };
    const handleSearch = async (searchFilter) => {
        setPagination((prevPage) => ({
            ...prevPage,
            filter: (searchFilter ? searchFilter : DEFAULT_FILTER),
        }));
        const searchResult = await fetchJewelries(pagination.page, pagination.pageSize, searchFilter);
        if (searchResult && searchResult.totalElements === 1 && searchResult.content[0].jewelryId) {
            await handleFindById(searchResult.content[0].jewelryId);
        }
    };
    const exportExcelFile = async () => {
        try {
            const response = await exportJewelries(pagination.page, pagination.pageSize, undefined);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `jewelry_data_${formatDateTime()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            handleErrorMessage("Không thể xuất dữ liệu Excel lúc này. Thử lại sau!", openAlert);
        }
    };
    const onClose = async () => {
        await fetchJewelries(pagination.page, pagination.pageSize);
    }
    const processUpdateStatus = async () => {
        try {
            await axios.patch(`${JEWELRY_API_ENDPOINT}/${jewelryData.jewelryId}/visible`, jewelryData);
            handleSuccessMessage(`Thay đổi thành công món hàng [${jewelryData.name}]`, openAlert);
            await onClose();
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setStatusUpdateDialog(false);
        }
    };
    const processDeleteJewelry = async () => {
        try {
            await axios.delete(`${JEWELRY_API_ENDPOINT}/${jewelryData.jewelryId}`);
            handleSuccessMessage('Món hàng số [' + jewelryData.jewelryId + '] được xóa thành công', openAlert);
            await onClose();
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setOpenDeleteDialog(false);
        }
    };
    const getAvailableCreateOptions = () => {
        return CREATE_JEWELRY_ACTIONS;
    }
    return (
        <div>
            <Box>
                <Grid container spacing={2} sx={{paddingBottom: 2}}>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        {permissions.INVENTORY?.includes('CREATE') && (
                            <DropDownButton
                                fullWidth={true}
                                disabled={false}
                                options={getAvailableCreateOptions()}
                                action={selectedCreateAction}
                                handleActionClick={handleCreateActionClick}
                                handleMenuItemClick={handleCreateMenuItemClick}
                                isLoading={false}
                            />)}
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <DropDownButton
                            fullWidth={true}
                            disabled={disableButton()}
                            options={getAvailableOptions()}
                            action={selectedAction}
                            handleActionClick={handleActionClick}
                            handleMenuItemClick={handleMenuItemClick}
                            isLoading={false}
                        />
                    </Grid>
                </Grid>
            </Box>
            <CustomDataGrid
                rows={jewelries}
                pagination={pagination}
                loading={loading}
                setSelectedRows={handleSetSelectedRow}
                columns={columns}
                onSearch={handleSearch}
                onPaginationModelChange={handleChangePage}
                onFindById={handleFindById}
                pageSummary={pagination.summary}
                onExportData={exportExcelFile}
            />
            <CreateJewelry open={openAddDialog} onClose={async () => {
                setOpenAddDialog(false);
                await fetchJewelries(pagination.page, pagination.pageSize);
            }}/>
            <CreateSilverJewelry open={openCreateSilverDialog} onClose={async () => {
                setOpenCreateSilverDialog(false);
                await fetchJewelries(pagination.page, pagination.pageSize);
            }}/>
            <UpdateJewelry open={openUpdateJewelryDialog} onClose={async () => {
                setOpenUpdateJewelryDialog(false);
                await fetchJewelries(pagination.page, pagination.pageSize);
            }}
                           sourceJewelry={jewelryData}/>
            <UpdateSilverJewelry open={openUpdateSilverJewelry} onClose={async () => {
                setOpenUpdateSilverJewelry(false);
                await fetchJewelries(pagination.page, pagination.pageSize);
            }}
                           sourceJewelry={jewelryData}/>
            <YesNoAlertDialog open={statusUpdateDialog} onClose={() => {
                setStatusUpdateDialog(false);
            }}
                              content={statusUpdateContent}
                              onAccept={processUpdateStatus}/>

            <YesNoAlertDialog open={openDeleteDialog} onClose={() => {
                setOpenDeleteDialog(false);
            }}
                              content={`Xóa món hàng [${jewelryData.name}], mã số: ${jewelryData.jewelryId}?`}
                              onAccept={processDeleteJewelry}/>
        </div>
    );
};
export default JewelryGrid;
