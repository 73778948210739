import * as React from 'react';
import {useEffect} from 'react';
import Backdrop from '@mui/material/Backdrop';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import SellIcon from '@mui/icons-material/Sell';
import SpeedDial from "@mui/material/SpeedDial";
import {useLocation, useNavigate} from "react-router-dom";
import {
    getItemFromSourcesByCode,
    QUICK_ACTION_CREATE_BUY_ORDER,
    QUICK_ACTION_CREATE_EXCHANGE_ORDER,
    QUICK_ACTION_CREATE_PAWN,
    QUICK_ACTION_CREATE_SELL_ORDER
} from "../constants";

import '../css/QuickAction.css';
import Stack from "@mui/material/Stack";
import {usePermissions} from "../route/PermissionProvider";

const actions = [
    {icon: <MonetizationOnIcon/>, label: 'Cầm đồ', code: QUICK_ACTION_CREATE_PAWN, navigate: '/pawn-mgmt'},
    {icon: <AddShoppingCartIcon/>, label: 'Đơn mua', code: QUICK_ACTION_CREATE_BUY_ORDER, navigate: `/order-mgmt`},
    {icon: <SellIcon/>, label: 'Đơn bán', code: QUICK_ACTION_CREATE_SELL_ORDER, navigate: `/order-mgmt`},
    {icon: <ShopTwoIcon/>, label: 'Trao đổi', code: QUICK_ACTION_CREATE_EXCHANGE_ORDER, navigate: `/order-mgmt`},
];

const QuickAction = () => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [pathname, setPathname] = React.useState('/');
    const permissions = usePermissions();
    const getAvailableActions = () => {
        return actions.filter(action => {
            if (action.navigate === pathname) return false;
            switch (action.code) {
                case QUICK_ACTION_CREATE_PAWN:
                    return permissions.PAWN && permissions.PAWN.includes("CREATE");
                case QUICK_ACTION_CREATE_BUY_ORDER:
                case QUICK_ACTION_CREATE_SELL_ORDER:
                case QUICK_ACTION_CREATE_EXCHANGE_ORDER:
                    return permissions.ORDER && permissions.ORDER.includes("CREATE");
                default:
                    return false;
            }
        });
    };

    useEffect(() => {
        setPathname(location.pathname);
    }, [location]);
    const handleOpen = async () => {
        setOpen(true);
    };
    const handleClose = async () => {
        setOpen(false);
    };
    const handleActionClick = (action) => {
        const actionItem = getItemFromSourcesByCode(actions, action);
        if (actionItem) {
            navigate(actionItem.navigate, {state: {quickAction: actionItem.code}});
        }
    }

    return (
        <Stack sx={{transform: 'translateZ(1000px)', flexGrow: 1, zIndex: 100}}>
            <Backdrop open={open}/>
            <SpeedDial
                ariaLabel="Tạo nhanh đơn"
                sx={{
                    position: 'absolute',
                    zIndex: 1,
                    top: 20,
                    left: 0,
                    right: 0,
                    margin: '0 auto',
                    marginRight: -1,
                    width: 35,
                    height: 35,
                }}
                color="primary"
                icon={<SpeedDialIcon/>}
                onClose={handleClose}
                direction={"down"}
                onOpen={handleOpen}
            >
                {getAvailableActions().map((action) => (
                    <SpeedDialAction
                        key={action.label}
                        icon={action.icon}
                        tooltipOpen={true}
                        tooltipTitle={action.label}
                        onClick={() => {
                            handleActionClick(action.code);
                        }}
                    />
                ))}
            </SpeedDial>
        </Stack>
    );
}
export default QuickAction;
