import {connect} from "react-redux";
import CustomDataGrid from "../common/CustomDataGrid";
import React, {useEffect, useState} from "react";
import {DEFAULT_INVOICE_DATA, INVOICE_COLUMNS, SELECTED_INVOICE_ACTIONS} from "./InvoiceConstant";
import {downloadInvoiceFile, getInvoiceInfo, loadInvoices} from "./invoiceThunks";
import CreateInvoice from "./CreateInvoice";
import {Box, Button, ButtonGroup, Grid} from "@mui/material";
import {
    convertDayjsDate,
    fetchSystemInfo,
    getInvoiceSeries,
    getResponsiveWidth,
    getTemplateCode,
    handleApiError,
    UNKNOWN_ACTIONS
} from "../constants";
import DropDownButton from "../common/DropDownButton";
import {useAlert} from "../common/AlertProvider";
import InvoiceDetails from "./InvoiceDetails";
import DeleteInvoice from "./DeleteInvoice";
import {usePermissions} from "../route/PermissionProvider";
import {useLocation, useNavigate} from "react-router-dom";


const InvoiceGrid = ({invoices = [], isLoading, onLoadInvoices, onGetInvoiceDetail, onDownloadInvoice}) => {
    const permissions = usePermissions();
    const {openAlert} = useAlert();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 100,
        totalRowCount: 100
    });
    const [selectedInvoice, setSelectedInvoice] = useState(undefined);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [selectedAction, setSelectedAction] = React.useState('VIEW');
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [invoiceData, setInvoiceData] = useState(DEFAULT_INVOICE_DATA);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        onLoadInvoices(pagination);
    }, [pagination, onLoadInvoices]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        async function processCreateRequest() {
            const requestAction = params.get('requestAction');
            const orderId = params.get('orderId');
            if (requestAction === 'create' && orderId > 0) {
                await fetchSystemInfo(openAlert);
                setInvoiceData({
                    ...DEFAULT_INVOICE_DATA,
                    orderId: orderId,
                    invoiceSeries: getInvoiceSeries(),
                    templateCode: getTemplateCode(),
                });
                setOpenCreateDialog(true);
            }
        }

        processCreateRequest();
    }, [location.search, navigate, openAlert]);

    const handleSetSelectedRow = async (selectedRow, rows) => {
        try {
            const selectedId = selectedRow.length === 1 ? selectedRow[0] : 0;
            const rowData = rows.find((row) => row.invoiceId === selectedId);
            setSelectedInvoice(rowData);
        } catch (error) {
            console.log("Error at process selected item", selectedRow);
        }
    };
    const handleSearch = async (searchFilter) => {
        console.log('searchFilter', searchFilter);
    };

    const disableButton = () => {
        return !selectedInvoice;
    }
    const getAvailableOptions = () => {
        const status = selectedInvoice ? selectedInvoice.status : undefined;
        if (!status || !permissions || !permissions['INVOICE']) {
            return UNKNOWN_ACTIONS;
        }
        const actions = SELECTED_INVOICE_ACTIONS.filter(action =>
            action.actStatus.includes(status) && permissions.INVOICE?.includes(action.permission)
        );
        if (actions.length > 0 && !actions.find(action => action.code === selectedAction)) {
            setSelectedAction(actions[0].code);
        } else if (actions.length === 0) {
            return UNKNOWN_ACTIONS;
        }
        return actions;
    }
    const fetchInvoiceDetails = async (invoiceId) => {
        try {
            const response = await onGetInvoiceDetail(invoiceId);
            const formatted = {
                ...response,
                id: response.invoiceId,
                buyerInfo: response.buyerInfo ? {...response.buyerInfo} : {},
                itemInfo: response.itemInfo?.map((item) => ({
                    ...item,
                    id: item.itemId
                })),
                invoiceIssuedDate: convertDayjsDate(response.invoiceIssuedDate),
            };
            setInvoiceData(formatted);
            return formatted;
        } catch (error) {
            console.error("Error while fetching invoice details:", error);
            handleApiError(error, openAlert);
        }
    };
    const handleViewClick = async (invoiceId) => {
        await fetchInvoiceDetails(invoiceId)
        setOpenViewDialog(true);
    }
    const handleUpdateClick = async (invoiceId) => {
        await fetchInvoiceDetails(invoiceId)
        setOpenUpdateDialog(true);
    }
    const handleDownloadInvoice = async (invoiceId) => {
        try {
            const response = await onDownloadInvoice(invoiceId);
            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice_${invoiceId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error while downloading invoice:", error);
            handleApiError("Không thể tải hóa đơn, vui lòng thử lại sau!", openAlert);
        }
    }
    const handleDeleteClick = async (invoiceId) => {
        await fetchInvoiceDetails(invoiceId)
        setOpenDeleteDialog(true);
    }
    const handleActActionClick = async (action) => {
        try {
            setLoading(true);
            if (!selectedInvoice) return;
            const invoiceId = selectedInvoice.invoiceId;
            await fetchSystemInfo(openAlert);
            switch (action) {
                case 'DOWNLOAD': {
                    return await handleDownloadInvoice(invoiceId);
                }
                case 'UPDATE': {
                    return await handleUpdateClick(invoiceId);
                }
                case 'DELETE': {
                    return await handleDeleteClick(invoiceId);
                }
                case 'UNKNOWN': {
                    break;
                }
                default: {
                    return await handleViewClick(invoiceId);
                }
            }
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setLoading(false);
        }
    };
    const handleActMenuItemClick = (event, selectedAction) => {
        setSelectedAction(selectedAction);
    };
    return (
        <>
            <Box>
                <Grid spacing={2} sx={{paddingBottom: 2}} container>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        {permissions.INVOICE?.includes('CREATE') && (
                            <ButtonGroup variant="contained" fullWidth>
                                <Button onClick={async () => {
                                    await fetchSystemInfo(openAlert);
                                    setInvoiceData({
                                        ...DEFAULT_INVOICE_DATA,
                                        invoiceSeries: getInvoiceSeries(),
                                        templateCode: getTemplateCode(),
                                    });
                                    setOpenCreateDialog(true);
                                }} fullWidth>
                                    Tạo hóa đơn
                                </Button>
                            </ButtonGroup>
                        )}
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <DropDownButton
                            fullWidth={true}
                            disabled={disableButton()}
                            options={getAvailableOptions()}
                            action={selectedAction}
                            handleActionClick={handleActActionClick}
                            handleMenuItemClick={handleActMenuItemClick}
                            isLoading={loading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <CustomDataGrid
                rows={invoices}
                pagination={pagination}
                loading={isLoading}
                setSelectedRows={handleSetSelectedRow}
                columns={INVOICE_COLUMNS}
                onSearch={handleSearch}
                onPaginationModelChange={(page, event) => {
                    setPagination((prevPage) => ({
                        ...prevPage,
                        page: page.page,
                        pageSize: page.pageSize,
                    }));
                }}
            />
            <CreateInvoice open={openCreateDialog} onClose={async () => {
                setOpenCreateDialog(false);
            }} sourceInvoice={invoiceData}/>
            <InvoiceDetails open={openViewDialog} onClose={() => setOpenViewDialog(false)} invoiceData={invoiceData}/>
            <InvoiceDetails open={openUpdateDialog} onClose={() => setOpenUpdateDialog(false)} invoiceData={invoiceData}
                            viewMode={false}/>
            <DeleteInvoice open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}
                           invoiceData={invoiceData}/>
        </>
    )
}
const mapStateToProps = state => ({
    isLoading: state.isLoading,
    invoices: state.invoices,
});

const mapDispatchToProps = dispatch => ({
    onLoadInvoices: pagination => dispatch(loadInvoices(pagination)),
    onGetInvoiceDetail: invoiceId => dispatch(getInvoiceInfo(invoiceId)),
    onDownloadInvoice: invoiceId => dispatch(downloadInvoiceFile(invoiceId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceGrid);
