export const LOAD_INVOICES_IN_PROGRESS = 'LOAD_INVOICES_IN_PROGRESS';
export const loadInvoiceInProgress = () => ({
    type: LOAD_INVOICES_IN_PROGRESS,
});

export const LOAD_INVOICES_SUCCESS = 'LOAD_INVOICES_SUCCESS';
export const loadInvoicesSuccess = invoices => ({
    type: LOAD_INVOICES_SUCCESS,
    payload: {invoices},
});

export const CALL_REQUEST_SUCCESS = 'CALL_REQUEST_SUCCESS';
export const requestInvoiceSuccess = () => ({
    type: CALL_REQUEST_SUCCESS,
});
export const LOAD_INVOICES_FAILURE = 'LOAD_INVOICES_FAILURE';
export const loadInvoicesFailure = error => ({
    type: LOAD_INVOICES_FAILURE,
    payload: {error},
});
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const createInvoice = invoice => ({
    type: CREATE_INVOICE,
    payload: {invoice},
});

export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const updateInvoice = invoice => ({
    type: UPDATE_INVOICE,
    payload: {invoice},
});
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const deleteInvoice = invoiceId => ({
    type: DELETE_INVOICE,
    payload: { invoiceId },
});
