import React from 'react';
import {Navigate} from 'react-router-dom';
import {usePermissions} from "./PermissionProvider";

const ProtectedRoute = ({auth = false, requiredPermission, children}) => {
    const permissions = usePermissions();

    if (!auth) {
        return <Navigate to="/login"/>;
    }

    if (requiredPermission) {
        const [entity, action] = requiredPermission.split(':');
        if (!permissions[entity] || !permissions[entity].includes(action)) {
            return <Navigate to="/not-authorized" />;
        }
    }

    return children;
};

export default ProtectedRoute;
