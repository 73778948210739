import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import KpiTitle from "./KpiTitle";
import Box from "@mui/material/Box";
import axios from "../axiosInstance";
import {createEqualFilter, getGoldTypeByCode, handleApiError, readGoldWeightUnits} from "../constants";
import {useAlert} from "../common/AlertProvider";
import CircularIndeterminate from "../common/CircularIndeterminate";
import {JEWELRY_API_ENDPOINT} from "../jewelry/JewelryConstants";
import EastIcon from '@mui/icons-material/East';
import {useNavigate} from 'react-router-dom';

const JewelryKPISection = () => {
    const {openAlert} = useAlert();
    const [loading, setLoading] = useState(true);
    const [kpiData, setKpiData] = useState({
        totalJewelryCount: 0,
        totalJewelryAmount: 0,
        kpis: [],
    });
    const navigate = useNavigate();

    const fetchKPIsData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${JEWELRY_API_ENDPOINT}/kpi-section`);
            setKpiData(response.data);
            return response.data;
        } catch (error) {
            handleApiError(error, openAlert)
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            await fetchKPIsData();
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return <CircularIndeterminate/>;
    }
    return (
        <>
            <KpiTitle
                label="Quầy hàng"
                showSlider={false}
                onMarkSelected={() => {
                }}
            ></KpiTitle>
            <Typography component="p" variant="h6">
                Tổng: {kpiData.totalJewelryCount} món | {readGoldWeightUnits(kpiData.totalJewelryAmount)}
            </Typography>
            {kpiData.kpis.map((kpi, index) => (
                <Typography key={index} variant="overline" display="block">
                    {getGoldTypeByCode(kpi.goldType).label} | {kpi.totalCount} món
                    | {readGoldWeightUnits(kpi.totalAmount)}
                    <EastIcon color="primary" sx={{fontSize: 15, cursor: 'pointer'}}
                              onClick={() => navigate(`/inventory-mgmt`, {
                                  state: {filter: createEqualFilter("goldType", kpi.goldType)}
                              })}/>
                </Typography>
            ))}
            <Box component="section" sx={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', mt: 2 }}>
                <Link color="primary" onClick={() => navigate(`/inventory-mgmt`)}>
                    Xem thêm
                </Link>
            </Box>
        </>
    );
};

export default JewelryKPISection;
