import React, {memo, useEffect, useRef} from 'react';
import {Box, Typography} from "@mui/material";

function TradingViewWidget() {
    const container = useRef();

    useEffect(() => {
        if (!container.current) return;
        setTimeout(() => {
            if (container.current.querySelector("script")) return;

            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
            {
              "autosize": true,
              "symbol": "OANDA:XAUUSD",
              "interval": "60",
              "timezone": "Asia/Ho_Chi_Minh",
              "theme": "light",
              "style": "1",
              "locale": "vi_VN",
              "withdateranges": true,
              "allow_symbol_change": false,
              "calendar": false,
              "support_host": "https://www.tradingview.com"
            }`;

            container.current.appendChild(script);
        }, 500);

        return () => {
            if (container.current) {
                container.current.innerHTML = '';
            }
        };
    }, []);

    return (
        <Box display="flex" flexDirection="column" style={{height: '100%'}}>
            <Box flex={1} style={{minHeight: '400px', maxHeight: '50%'}}>
                <Typography variant="h5" component="div" sx={{mt: 2}}>
                    Giá vàng thế giới
                </Typography>
                <div className="tradingview-widget-container" ref={container} style={{height: "100%", width: "100%"}}>
                    <div className="tradingview-widget-container__widget"
                         style={{height: "100%", width: "100%"}}></div>
                </div>
            </Box>
        </Box>
    );
}

export default memo(TradingViewWidget);
