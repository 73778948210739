import {applyMiddleware, combineReducers, legacy_createStore as createStore} from 'redux';
import {thunk} from 'redux-thunk';
import {error, isLoading, users} from './user/userReducers'
import {activities, errorRecentActivity, isRecentActivityLoading} from "./dashboard/dashboardReducers";
import {errorInvoices, invoices, isInvoicesLoading} from "./invoice/invoiceReducers";

const reducers = {
    users,
    isLoading,
    error,
    activities,
    isRecentActivityLoading,
    errorRecentActivity,
    invoices,
    isInvoicesLoading,
    errorInvoices
};

const rootReducer = combineReducers(reducers);
const middlewareEnhancer = applyMiddleware(thunk)

export const configureStore = () =>
    createStore(rootReducer, undefined, middlewareEnhancer);
