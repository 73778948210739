import * as React from 'react';
import {useState} from 'react';
import Typography from '@mui/material/Typography';
import {Grid, Slider} from "@mui/material";
import Stack from "@mui/material/Stack";
import {setEndOfTheDate, setStartOfTheDate} from "../constants";
import DatetimeDialog from "./DatetimeDialog";

const dashboardMarks = [
    {
        value: 0,
        text: '[Tháng trước]',
    },
    {
        value: 20,
        text: '[Hôm nay]',
    },
    {
        value: 40,
        text: '[Tháng này]',
    },
    {
        value: 60,
        text: '[Quý này]',
    },
    {
        value: 80,
        text: '[Năm nay]',
    },
    {
        value: 100,
        text: '[Tùy chọn]',
    },
];

const getMarkByValue = (value) => {
    return dashboardMarks.find(mark => value === mark.value);
};

const getCurrentMonthRange = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    const endDate = new Date(year, month + 1, 0);
    return {startDate, endDate};
};


const getCurrentQuarterRange = () => {
    const now = new Date();
    const quarterMonth = Math.floor(now.getMonth() / 3) * 3;
    const startDate = new Date(now.getFullYear(), quarterMonth, 1);
    const nextQuarterFirstMonth = quarterMonth + 3;
    const lastDayOfCurrentQuarter = new Date(now.getFullYear(), nextQuarterFirstMonth, 0).getDate();
    const endDate = new Date(now.getFullYear(), nextQuarterFirstMonth - 1, lastDayOfCurrentQuarter);
    return {startDate, endDate};
};
const getCurrentYearRange = () => {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), 0, 1);
    const endDate = new Date(now.getFullYear(), 11, 31);
    return {startDate, endDate};
};
const getLastMonthRange = () => {
    const now = new Date();
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 0).getDate();
    const startDate = lastMonth;
    const endDate = new Date(now.getFullYear(), now.getMonth() - 1, lastDayOfMonth);
    return {startDate, endDate};
};
const KpiTitle = ({label, showSlider, onMarkSelected}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [currentMark, setCurrentMark] = useState({});
    const getTextByValue = (value) => {
        const mark = getMarkByValue(value);
        return mark ? mark.text : '';
    }
    const handleChange = async (event, newValue) => {
        const newMark = getMarkByValue(newValue);
        if (currentMark !== newMark) {
            setCurrentMark(newMark);
            const filter = getFilterByMark(newMark);
            (filter) && await onMarkSelected({...newMark, ...filter});
        }
    };
    const handleDateSelected = async (selectedDate = {}) => {
        const fromDate = setStartOfTheDate(selectedDate.fromDate);
        const toDate = setEndOfTheDate(selectedDate.toDate);
        const newText = `[${new Date(fromDate).toLocaleDateString("vi-VN")}-${new Date(toDate).toLocaleDateString("vi-VN")}]`;
        const newMark = {value: currentMark.value, text: newText};
        setCurrentMark(newMark);
        await onMarkSelected({...newMark, fromDate: fromDate, toDate: toDate});
        setOpenDialog(false);
    }
    const getFilterByMark = (mark) => {
        switch (mark.value) {
            case 0: {
                const {startDate, endDate} = getLastMonthRange();
                return {fromDate: setStartOfTheDate(startDate), toDate: setEndOfTheDate(endDate)};
            }
            case 20: {
                const fromDate = setStartOfTheDate(new Date());
                const toDate = setEndOfTheDate(new Date());
                return {fromDate: fromDate, toDate: toDate};
            }
            case 40: {
                const {startDate, endDate} = getCurrentMonthRange();
                return {fromDate: setStartOfTheDate(startDate), toDate: setEndOfTheDate(endDate)};
            }
            case 60: {
                const {startDate, endDate} = getCurrentQuarterRange();
                return {fromDate: setStartOfTheDate(startDate), toDate: setEndOfTheDate(endDate)};
            }
            case 80: {
                const {startDate, endDate} = getCurrentYearRange();
                return {fromDate: setStartOfTheDate(startDate), toDate: setEndOfTheDate(endDate)};
            }
            case 100: {
                setOpenDialog(true);
                return;
            }
            default: {
                return;
            }
        }
    };

    return (
        <Stack>
            <Grid container>
                <Grid item xs={4}>
                    <Typography component="h2" variant="h6" color="primary" gutterBottom>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    {showSlider && (
                        <Slider
                            defaultValue={dashboardMarks[1].value}
                            step={null}
                            valueLabelDisplay="auto"
                            valueLabelFormat={getTextByValue}
                            marks={dashboardMarks}
                            size="small"
                            onChange={handleChange}
                            aria-label="small"
                        />
                    )}
                </Grid>
            </Grid>
            <DatetimeDialog open={openDialog} onSelected={handleDateSelected}/>
        </Stack>
    );
}
export default KpiTitle;
