import React, {useState} from "react";
import {DEFAULT_JEWELRY_DATA, initDefaultJewelry, JEWELRY_API_ENDPOINT} from "./JewelryConstants";
import {Autocomplete, Grid, InputAdornment, MenuItem, Select, Switch, TextField} from "@mui/material";
import axios from '../axiosInstance';
import {
    calculateGoldWeight,
    getCounters,
    getJewelryStampTypeByCode,
    getProcAmounts,
    getResponsiveWidth,
    getSortedGoldBrand,
    getSortedGoldType,
    getTopProducts,
    handleApiError,
    handleSuccessMessage,
    isNumber,
    setProcAmounts,
    setTopProducts
} from "../constants";
import {isMobile} from "react-device-detect";
import {useAlert} from "../common/AlertProvider";
import CustomLoadingButton from "../common/CustomLoadingButton";
import CustomDialog from "../common/CustomDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import {handleSilverPrinting} from "./JewelrySilverStamp";
import {handleSilverPrinting16x30} from "./JewelrySilverStamp16x30";

const CreateSilverJewelry = ({open, onClose}) => {
    const {openAlert} = useAlert();
    const [jewelryData, setJewelryData] = useState(initDefaultJewelry());
    const [isItemNameValid, setItemNameValid] = useState(true);
    const [isGoldWeightValid, setGoldWeightValid] = useState(true);
    const [isGemWeightValid, setGemWeightValid] = useState(true);
    const [isPriceValid, setPriceValid] = useState(true);
    const [isProcPriceValid, setProcPriceValid] = useState(true);
    const [isSellProcPriceValid, setSellProcPriceValid] = useState(true);

    const submitJewelryData = async (jewelryData) => {
        try {
            const response = await axios.post(JEWELRY_API_ENDPOINT, jewelryData);
            handleSuccessMessage('Sản phẩm [' + response.data.jewelryId + '] được thêm thành công', openAlert);
            onClose();
            return response.data;
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const handleSave = async () => {
        let validated = true;
        if (jewelryData.name.trim() === "") {
            setItemNameValid(false);
            validated = false;
        } else {
            setItemNameValid(true);
        }
        if (!jewelryData.sellByItem) {
            if (jewelryData.goldWeight && jewelryData.goldWeight !== 0) {
                setGoldWeightValid(true);
            } else {
                setGoldWeightValid(false);
                validated = false;
            }
            if (isNumber(jewelryData.gemWeight)) {
                setGemWeightValid(true);
            } else {
                validated = false;
                setGemWeightValid(false);
            }
            if (isNumber(jewelryData.price)) {
                setPriceValid(true);
            } else {
                setPriceValid(false);
                validated = false;
            }
        }
        if (isNumber(jewelryData.procPrice)) {
            setProcPriceValid(true);
        } else {
            setProcPriceValid(false);
            validated = false;
        }
        if (isNumber(jewelryData.sellProcPrice)) {
            setSellProcPriceValid(true);
        } else {
            setSellProcPriceValid(false);
            validated = false;
        }

        if (validated) {
            const response = await submitJewelryData(jewelryData);
            setJewelryData((prev) => ({
                ...prev,
                goldWeight: DEFAULT_JEWELRY_DATA.goldWeight,
                gemWeight: DEFAULT_JEWELRY_DATA.gemWeight,
                totalWeight: DEFAULT_JEWELRY_DATA.totalWeight,
                name: DEFAULT_JEWELRY_DATA.name,
                description: DEFAULT_JEWELRY_DATA.description,
                price: DEFAULT_JEWELRY_DATA.price,
                procPrice: DEFAULT_JEWELRY_DATA.procPrice,
                totalPrice: DEFAULT_JEWELRY_DATA.totalPrice,
                sellProcPrice: DEFAULT_JEWELRY_DATA.sellProcPrice,
                exchangeProcPrice: DEFAULT_JEWELRY_DATA.exchangeProcPrice,
            }));
            setTopProducts(jewelryData.name);
            setProcAmounts(jewelryData.sellProcPrice);
            setProcAmounts(jewelryData.exchangeProcPrice);
            return response;
        }
    };

    const handleSaveAndPrint = async () => {
        const jewelry = await handleSave();
        if (jewelry) {
            const jewelryStampType = getJewelryStampTypeByCode();
            if (jewelryStampType.code === '16x30') {
                await handleSilverPrinting16x30(jewelry);
            } else {
                await handleSilverPrinting(jewelry);
            }
        }
    }

    const weightChanges = (totalWeight = 0, gemWeight = 0) => {
        const goldWeight = calculateGoldWeight(totalWeight, gemWeight);
        setJewelryData((prev) => ({
            ...prev,
            goldWeight: goldWeight,
        }));
        goldPriceChanges(goldWeight, jewelryData.goldPrice);
    };

    const goldPriceChanges = (goldWeight = 0, goldPrice = 0) => {
        let price = parseFloat(goldWeight) * parseInt(goldPrice);
        if (isNaN(price)) {
            price = 0;
        }
        setJewelryData((prev) => ({
            ...prev,
            price: price,
        }));

        priceChanges(price, jewelryData.procPrice);
    }
    const priceChanges = (price = 0, procPrice = 0) => {
        let totalPrice = parseFloat(price) + parseFloat(procPrice);
        if (isNaN(totalPrice)) {
            totalPrice = 0;
        }
        setJewelryData((prev) => ({
            ...prev,
            totalPrice: totalPrice,
        }));
    };
    const handleProductSelect = (event, selectedValue) => {
        setJewelryData((prevData) => ({
            ...prevData,
            name: selectedValue,
        }));
    };
    const handleClose = async () => {
        onClose();
    };
    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleSaveAndPrint}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu và In"/>
            <CustomLoadingButton variant="contained" onClick={handleSave} sx={{minWidth: 170, marginBottom: 1}}
                                 fullWidth buttonLabel="Chỉ Lưu"/>
        </>
    );
    const pageTitle = (
        <>
            {`Thêm món hàng [bạc]`}
        </>
    );
    const handleSellByItemChange = async (event) => {
        const isChecked = event.target.checked;
        setJewelryData((prev) => ({
            ...prev,
            sellByItem: isChecked,
        }));

        if (isChecked) {
            setGoldWeightValid(true);
            setGemWeightValid(true);
            setPriceValid(true);
        }
    }
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 12)} justifyContent="center">
                        <FormControlLabel control={
                            <Switch
                                checked={jewelryData.sellByItem}
                                onChange={(e) => {
                                    setJewelryData((prev) => ({
                                        ...prev,
                                        sellByItem: e.target.checked,
                                    }));
                                    handleSellByItemChange(e, jewelryData.sellByItem);
                                }}/>
                        }
                                          label="Bán theo món?"/>
                    </Grid>
                    <Grid item xs={getResponsiveWidth()}>
                        <Autocomplete
                            freeSolo
                            options={getTopProducts().map((option) => option)}
                            onChange={handleProductSelect}
                            renderInput={(params) =>
                                <TextField {...params}
                                           value={jewelryData.name}
                                           onChange={(e) => {
                                               setJewelryData((prev) => ({
                                                   ...prev,
                                                   name: e.target.value,
                                               }));
                                               setItemNameValid(true)
                                           }}
                                           label="Tên món hàng"
                                           InputProps={{
                                               ...params.InputProps,
                                               type: 'search',
                                           }}
                                           error={!isItemNameValid}
                                />}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <Select
                            value={jewelryData.goldType}
                            fullWidth
                            MenuProps={{
                                disableAutoFocusItem: true,
                            }}
                            onChange={(e) => {
                                setJewelryData((prev) => ({
                                    ...prev,
                                    goldType: e.target.value,
                                }));
                            }}
                        >
                            {getSortedGoldType()
                                .map((goldType) => (
                                    <MenuItem key={goldType.code} value={goldType.code}>
                                        {goldType.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <Select
                            value={jewelryData.brand}
                            fullWidth
                            MenuProps={{
                                disableAutoFocusItem: true,
                            }}
                            onChange={(e) => {
                                setJewelryData((prev) => ({
                                    ...prev,
                                    brand: e.target.value,
                                }));
                            }}
                        >
                            {getSortedGoldBrand()
                                .map((goldBrand) => (
                                    <MenuItem key={goldBrand.code} value={goldBrand.code}>
                                        {goldBrand.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <Select
                            value={jewelryData.counterId}
                            fullWidth
                            MenuProps={{
                                disableAutoFocusItem: true,
                            }}
                            onChange={(e) => {
                                setJewelryData((prev) => ({
                                    ...prev,
                                    counterId: e.target.value,
                                }));
                            }}
                        >
                            {Object.values(getCounters()).map((counter) => (
                                <MenuItem key={counter.code} value={counter.code}>
                                    {counter.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Ký hiệu"
                            value={jewelryData.symbol}
                            onChange={(e) => {
                                setJewelryData((prev) => ({
                                    ...prev,
                                    symbol: e.target.value,
                                }))
                            }}
                            fullWidth
                        />
                    </Grid>
                    {!jewelryData.sellByItem && (
                        <>
                            <Grid item xs={getResponsiveWidth()}>
                                <TextField
                                    label={(isMobile) ? "TL tổng" : "Trọng lượng tổng"}
                                    value={jewelryData.totalWeight}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                        setJewelryData((prev) => ({
                                            ...prev,
                                            totalWeight: numericInput,
                                        }));
                                        weightChanges(numericInput, jewelryData.gemWeight);
                                    }}
                                    fullWidth
                                    InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(6, 6)}>
                                <TextField
                                    label={(isMobile) ? "TL hột" : "Trọng lượng hột"}
                                    value={jewelryData.gemWeight}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                        setJewelryData((prev) => ({
                                            ...prev,
                                            gemWeight: numericInput,
                                        }));
                                        weightChanges(jewelryData.totalWeight, numericInput);
                                    }}
                                    fullWidth
                                    InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                                    error={!isGemWeightValid}
                                />
                            </Grid>

                            < Grid item xs={getResponsiveWidth(6, 6)}>
                                <TextField
                                    label={(isMobile) ? "TL bạc" : "Trọng lượng bạc"}
                                    readOnly disabled
                                    value={jewelryData.goldWeight}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                        setJewelryData((prev) => ({
                                            ...prev,
                                            goldWeight: numericInput,
                                        }));
                                        setGoldWeightValid(true);
                                    }}
                                    fullWidth
                                    InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                                    error={!isGoldWeightValid}
                                />
                            </Grid>
                        </>)}
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Số lượng" readOnly
                            disabled
                            value={Number(jewelryData.quantity).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    quantity: numericInput,
                                }))
                            }}
                            fullWidth
                        />
                    </Grid>
                    {!jewelryData.sellByItem && (
                        <>
                            <Grid item xs={getResponsiveWidth(6, 6)}>
                                <TextField
                                    label="Giá bạc"
                                    value={Number(jewelryData.goldPrice).toLocaleString()}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                        setJewelryData((prev) => ({
                                            ...prev,
                                            goldPrice: numericInput,
                                        }));
                                        goldPriceChanges(jewelryData.goldWeight, numericInput);
                                    }}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    label="Thành tiền(ĐG x TL bạc) " readOnly disabled
                                    value={Number(jewelryData.price).toLocaleString()}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                                    }}
                                    error={!isPriceValid}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label={!jewelryData.sellByItem ? "Giá công(nhập)" : "Giá nhập"}
                            value={Number(jewelryData.procPrice).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    procPrice: numericInput,
                                }));
                                if (isNumber(numericInput)) {
                                    setProcPriceValid(true);
                                }
                                priceChanges(jewelryData.price, numericInput);
                            }}
                            fullWidth
                            InputProps={{endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),}}
                            error={!isProcPriceValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField readOnly disabled
                                   label="Tổng giá nhập((ĐG * TL) + Công)"
                                   value={Number(jewelryData.totalPrice).toLocaleString()}
                                   fullWidth
                                   InputProps={{
                                       endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                                   }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <Autocomplete
                            freeSolo
                            value={!isNaN(jewelryData.sellProcPrice) ? Number(jewelryData.sellProcPrice).toLocaleString() : '0'}
                            onChange={(event, selectedValue) => {
                                setJewelryData((prev) => ({
                                    ...prev,
                                    sellProcPrice: selectedValue ? selectedValue.value : 0,
                                }));
                            }}
                            options={getProcAmounts().map((option) => ({
                                label: Number(option).toLocaleString(),
                                value: parseInt(option),
                            }))}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    value={Number(jewelryData.sellProcPrice ? jewelryData.sellProcPrice : 0).toLocaleString()}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                        setJewelryData((prev) => ({
                                            ...prev,
                                            sellProcPrice: numericInput,
                                        }));
                                        if (isNumber(numericInput)) {
                                            setSellProcPriceValid(true);
                                        }
                                    }}
                                    fullWidth
                                    label={!jewelryData.sellByItem ? "Tiền công bán" : "Giá bán"}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                    error={!isSellProcPriceValid}
                                />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Mô tả thêm"
                            value={jewelryData.description}
                            onChange={(e) =>
                                setJewelryData((prev) => ({
                                    ...prev,
                                    description: e.target.value,
                                }))
                            }
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </CustomDialog>
        </div>
    )
}
export default CreateSilverJewelry;
