export const LOAD_RECENT_ACTIVITIES_IN_PROGRESS = 'LOAD_RECENT_ACTIVITIES_IN_PROGRESS';
export const loadRecentActivitiesInProgress = () => ({
    type: LOAD_RECENT_ACTIVITIES_IN_PROGRESS,
});

export const LOAD_RECENT_ACTIVITIES_SUCCESS = 'LOAD_RECENT_ACTIVITIES_SUCCESS';
export const loadRecentActivitiesSuccess = activities => ({
    type: LOAD_RECENT_ACTIVITIES_SUCCESS,
    payload: {activities},
});

export const LOAD_RECENT_ACTIVITIES_FAILURE = 'LOAD_RECENT_ACTIVITIES_FAILURE';
export const loadRecentActivitiesFailure = error => ({
    type: LOAD_RECENT_ACTIVITIES_FAILURE,
    payload: {error},
});
