import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import QuickAction from "../common/QuickAction";
import {isMobile} from "react-device-detect";
import {API_ENDPOINT, LOCAL_STORAGE_AVATAR, LOCAL_STORAGE_KEY_ACCOUNT_NAME} from "../constants";
import UserSettings from "../user/UserSettings";
import axios from "../axiosInstance";
import {AccountCircle} from "@mui/icons-material";

const AccountMenu = ({onLogout: handleLogout}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSettings, setOpenSettings] = React.useState(false);
    const open = Boolean(anchorEl);
    const [userData, setUserData] = useState({});
    const avatarBase64 = localStorage.getItem(LOCAL_STORAGE_AVATAR); // Retrieve avatar URL from local storage

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenSetting = async () => {
        const response = await axios.get(`${API_ENDPOINT}/profiles`);
        setUserData(response.data);
        setOpenSettings(true);
        handleClose();
    };
    const onLogoutClick = () => {
        handleLogout();
        handleClose();
    };
    const handleCloseSettings = () => {
        setOpenSettings(false);
    };
    return (
        <React.Fragment>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ml: 2}}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {avatarBase64 ? (
                            <Avatar src={`data:image/jpeg;base64,${avatarBase64}`} sx={{width: 32, height: 32}}/>
                        ) : (
                            <AccountCircle sx={{width: 32, height: 32}}/>
                        )}
                    </IconButton>

                </Tooltip>
                {isMobile && (<QuickAction/>)}
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgColor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem onClick={handleClose}>
                    {avatarBase64 ? (
                        <Avatar src={`data:image/jpeg;base64,${avatarBase64}`} sx={{width: 32, height: 32}}/>
                    ) : (
                        <AccountCircle sx={{width: 32, height: 32}}/>
                    )}
                    {localStorage.getItem(LOCAL_STORAGE_KEY_ACCOUNT_NAME)}
                </MenuItem>
                <Divider/>
                <MenuItem onClick={handleOpenSetting}>
                    <ListItemIcon>
                        <Settings fontSize="small"/>
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={onLogoutClick}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    Thoát
                </MenuItem>
            </Menu>
            <UserSettings
                userData={userData}
                open={openSettings}
                onClose={handleCloseSettings}
            />
        </React.Fragment>
    );
}
export default AccountMenu;
