import {DataGrid} from "@mui/x-data-grid";
import {Box, LinearProgress} from "@mui/material";
import React from "react";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";

const GridSummary = ({rowData, columnData}) => {
    return (
        rowData ? (<Box sx={{
            width: 1,
            '& .due-date-warn': {backgroundColor: '#EEE482FF'},
            '& .due-date-red': {backgroundColor: '#F8978EFF'},
            '& .due-date-green': {backgroundColor: '#3AA622FF'},
        }}>
            <DataGrid
                slots={{
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: CustomNoRowsOverlay,
                }}
                autoHeight
                rows={rowData}
                columns={columnData}
                hideFooter
            />
        </Box>) : null
    );
}
export default GridSummary;
