import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import axios from '../axiosInstance';
import {
    API_ENDPOINT,
    DEFAULT_PRIMARY_COLOR,
    fetchSystemInfo,
    handleErrorMessage,
    handleSuccessMessage,
    handleWarningMessage,
    LOCAL_STORAGE_AVATAR,
    LOCAL_STORAGE_PRIMARY_COLOR
} from "../constants";
import {isUserAuthenticated, LOGIN_API_ENDPOINT, storeAuthenticatedInfo} from "./auth";
import CircularIndeterminate from "../common/CircularIndeterminate";
import {useAlert} from "../common/AlertProvider";
import Copyright from "../common/Copyright";
import {IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const defaultTheme = createTheme();
const defaultLoginData = {
    username: '',
    password: '',
    rememberMe: true,
}
export default function SignIn() {
    const {openAlert} = useAlert();
    const [loginData, setLoginData] = useState(defaultLoginData);
    const [isUsernameValid, setUsernameValid] = useState(true);
    const [isPasswordValid, setPasswordValid] = useState(true);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        async function checkAuthentication() {
            const authenticated = await isUserAuthenticated();
            setLoading(false);
            if (authenticated) {
                navigate('/dashboard');
            }
        }

        checkAuthentication();
    }, [navigate]);
    const fetchUserProfile = async () => {
        try {
            const response = await axios.get(`${API_ENDPOINT}/profiles`);
            const {color, avatar} = response.data;
            if (color) {
                localStorage.setItem(LOCAL_STORAGE_PRIMARY_COLOR, color);
            } else {
                localStorage.setItem(LOCAL_STORAGE_PRIMARY_COLOR, DEFAULT_PRIMARY_COLOR);
            }
            if (avatar) {
                localStorage.setItem(LOCAL_STORAGE_AVATAR, avatar);
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };
    const handleSubmit = async (event) => {
        let validated = true;
        if (loginData.username === '') {
            setUsernameValid(false);
            validated = false;
        } else {
            setUsernameValid(true);
        }
        if (loginData.password === '') {
            setPasswordValid(false);
            validated = false;
        } else {
            setPasswordValid(true);
        }
        if (validated) {
            try {
                const response = await axios.post(LOGIN_API_ENDPOINT, loginData);
                const {access_token, password_change_required, password_change_token, full_name} = response.data;
                if (access_token) {
                    await storeAuthenticatedInfo(access_token, loginData.username, full_name);
                    await fetchSystemInfo(openAlert);
                    await fetchUserProfile(); // Fetch user profile after successful login
                    handleSuccessMessage('Đăng nhập hệ thống thành công!', openAlert);
                    window.location.href = '/dashboard';
                } else if (password_change_required && password_change_token) {
                    handleWarningMessage('Hệ thống yêu cầu thay đổi mật khẩu!', openAlert);
                    navigate('/change-password', {state: {loginData, passwordChangeToken: password_change_token}});
                }
            } catch (error) {
                //TODO to handle the message for 500
                handleErrorMessage('Tên đăng nhập hoặc mật khẩu không đúng!', openAlert);
                console.error(error);
            }
        }
    };
    if (loading) {
        return <CircularIndeterminate/>;
    }
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Đăng nhâp hệ thống
                    </Typography>
                    <Box sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Tên đăng nhập"
                            autoFocus
                            onChange={(e) => {
                                setLoginData((prev) => ({
                                    ...prev,
                                    username: e.target.value,
                                }));
                            }}
                            error={!isUsernameValid}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Mật khẩu"
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => {
                                setLoginData((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                }));
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Nhấn vào hiện mật khẩu"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={!isPasswordValid}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={loginData.rememberMe}
                                               color="primary"
                                               onChange={(e) =>
                                                   setLoginData((prev) => ({
                                                       ...prev,
                                                       rememberMe: e.target.checked,
                                                   }))
                                               }
                            />}
                            label="Ghi nhớ tôi"
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            onClick={(event) => handleSubmit(event)}
                        >
                            Đăng nhập
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}
