import {
    LOAD_RECENT_ACTIVITIES_FAILURE,
    LOAD_RECENT_ACTIVITIES_IN_PROGRESS,
    LOAD_RECENT_ACTIVITIES_SUCCESS
} from "./dashboardActions";

export const isRecentActivityLoading = (state = false, action) => {
    const {type} = action;
    switch (type) {
        case LOAD_RECENT_ACTIVITIES_IN_PROGRESS:
            return true;
        case LOAD_RECENT_ACTIVITIES_FAILURE:
        case LOAD_RECENT_ACTIVITIES_SUCCESS:
            return false;
        default:
            return state;
    }
}

export const activities = (state = [], action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_RECENT_ACTIVITIES_SUCCESS: {
            const {activities} = payload;
            return activities;
        }
        case LOAD_RECENT_ACTIVITIES_IN_PROGRESS:
        case LOAD_RECENT_ACTIVITIES_FAILURE:
        default:
            return state;
    }
}
export const errorRecentActivity = (state = {}, action) => {
    const {type} = action;
    switch (type) {
        case LOAD_RECENT_ACTIVITIES_IN_PROGRESS:
        case LOAD_RECENT_ACTIVITIES_SUCCESS:
            return {};
        case LOAD_RECENT_ACTIVITIES_FAILURE:
            return state;
        default:
            return {};
    }
}

