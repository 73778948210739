import {PieChart} from "@mui/x-charts";
import {useAlert} from "../common/AlertProvider";
import React, {useEffect, useState} from "react";
import {getGoldTypeByCode, handleApiError, readGoldWeightUnits} from "../constants";
import axios from "../axiosInstance";
import {JEWELRY_API_ENDPOINT} from "../jewelry/JewelryConstants";
import {randomId} from "@mui/x-data-grid-generator";

const JewelryChart = () => {
    const [chartData, setChartData] = useState([]);
    const {openAlert} = useAlert();
    const fetchJewelryBars = async () => {
        try {
            const response = await axios.get(`${JEWELRY_API_ENDPOINT}/kpi-section`);
            const kpis = response.data.kpis
            const charts = kpis.map(({totalCount, totalAmount, goldType}) => {
                return {
                    value: totalAmount,
                    label: getGoldTypeByCode(goldType).label,
                    id: randomId(),
                };
            });
            setChartData(charts);
        } catch (error) {
            console.error(error);
            handleApiError(error, openAlert);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            await fetchJewelryBars();
        };
        fetchData().then(() => console.log("Jewelry chart loaded!"));
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <PieChart
                series={[
                    {
                        data: chartData,
                        highlightScope: {faded: 'global', highlighted: 'item'},
                        faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
                        arcLabel: (item) => `${item.label}: ${readGoldWeightUnits(item.value)}`,
                    },
                ]}
                width={400}
                height={400}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: {vertical: 'top', horizontal: 'middle'},
                        paddingTop: 20,
                    },
                }}
            />
        </>
    );
}
export default JewelryChart;
