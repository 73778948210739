import React, {useEffect, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Select, TextField,} from "@mui/material";
import axios from '../axiosInstance';
import {CUSTOMERS_API_ENDPOINT, DEFAULT_CUSTOMER_DATA} from "./CustomerConstants";
import {handleApiError, handleSuccessMessage} from "../constants";
import {useAlert} from "../common/AlertProvider";
import CustomLoadingButton from "../common/CustomLoadingButton";
import CustomDialog from "../common/CustomDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NationalCard from "./NationalCard";

const DEFAULT_PANEL = 'panel0';
const QuickCreateCustomer = ({open, onClose, onCreateSuccess, inputValue}) => {
    const {openAlert} = useAlert();
    const [newCustomerData, setNewCustomerData] = useState(DEFAULT_CUSTOMER_DATA);
    const [isLastNameValid, setLastNameValid] = useState(true);
    const [expanded, setExpanded] = React.useState(DEFAULT_PANEL);

    useEffect(() => {
        if (!isNaN(inputValue)) {
            setNewCustomerData((prevData) => ({
                ...prevData,
                phone: inputValue,
            }));
        } else {
            setNewCustomerData((prevData) => ({
                ...prevData,
                lastName: inputValue,
            }));
        }
    }, [inputValue]);
    const handleCreateCustomer = async () => {
        try {
            const createdCustomer = await axios.post(CUSTOMERS_API_ENDPOINT, newCustomerData);
            handleSuccessMessage('Khách hàng [' + createdCustomer.data.customerId + '] được tạo thành công', openAlert);
            onCreateSuccess(createdCustomer.data);
            onClose();
            setExpanded(DEFAULT_PANEL);
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const handleClose = async () => {
        onClose();
        setExpanded(DEFAULT_PANEL);
    };
    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleCreateCustomer}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Tạo khách hàng"/>
            <CustomLoadingButton variant="contained" onClick={onClose}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Hủy"/>
        </>
    );
    const pageTitle = (
        <>
            {`Bạn cần tạo khách hàng:[${inputValue}] trước!`}
        </>
    );
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Accordion expanded={expanded === 'panel0'} onChange={handleChangeAccordion('panel0')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        Thông tin chung
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Tên khách hàng"
                                    value={newCustomerData.lastName}
                                    onChange={(e) => {
                                        setNewCustomerData((prev) => ({...prev, lastName: e.target.value}));
                                        setLastNameValid(true)
                                    }}
                                    fullWidth
                                    error={!isLastNameValid}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Số điện thoại"
                                    value={newCustomerData.phone}
                                    onChange={(e) =>
                                        setNewCustomerData((prev) => ({...prev, phone: e.target.value}))
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    value={newCustomerData.gender}
                                    onChange={(e) =>
                                        setNewCustomerData((prev) => ({...prev, gender: e.target.value}))
                                    }
                                    fullWidth
                                >
                                    <MenuItem value="MALE">Nam</MenuItem>
                                    <MenuItem value="FEMALE">Nữ</MenuItem>
                                    <MenuItem value="UNKNOWN">Giới tính</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        Thông tin CCCD
                    </AccordionSummary>
                    <AccordionDetails>
                        <NationalCard customerData={newCustomerData} setCustomerData={setNewCustomerData}/>
                    </AccordionDetails>
                </Accordion>
            </CustomDialog>
        </div>
    )
}

export default QuickCreateCustomer;
