import {Box, Button, Grid, LinearProgress} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../common/CustomNoRowsOverlay";
import React, {useEffect, useState} from "react";
import {DEFAULT_USER_DATA, SELECTED_USER_ACTIONS, USER_COLUMNS} from "./UserConstant";
import {getUserDetail, loadUsers} from "./userThunks";
import {connect} from 'react-redux';
import CreateUser from "./CreateUser";
import UpdateUser from "./UpdateUser";
import ResetUserPassword from "./ResetUserPassword";
import DeactiveUser from "./DeactiveUser";
import {useAlert} from "../common/AlertProvider";
import {getResponsiveWidth, handleApiError, UNKNOWN_ACTIONS} from "../constants";
import DropDownButton from "../common/DropDownButton";
import ManagePermissions from "./ManagePermissions";
import {usePermissions} from "../route/PermissionProvider";

const UserGrid = ({users = [], isLoading, startLoadUser, onGetUserDetail}) => {
    const permissions = usePermissions();
    const {openAlert} = useAlert();
    const [userData, setUserData] = useState(DEFAULT_USER_DATA);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [openDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [selectedAction, setSelectedAction] = React.useState('UPDATED');
    const [openPermissionsDialog, setOpenPermissionsDialog] = useState(false);
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 100,
        totalRowCount: 100
    });
    const handleChangePage = async (page, event) => {
        setPagination((prevPage) => ({
            ...prevPage,
            page: page?.page,
            pageSize: page?.pageSize,
        }));
    };
    useEffect(() => {
        startLoadUser(pagination);
    }, [pagination, startLoadUser]);
    const handleAddNewClick = () => {
        setOpenAddDialog(true);
    };
    const fetchUserDetails = async (username) => {
        try {
            const response = await onGetUserDetail(username)
            const formatted = {
                ...response,
                username: response.username,
            };
            setUserData(formatted);
            return formatted;
        } catch (error) {
            console.error("Error while fetching pawn details:", error);
            handleApiError(error, openAlert);
        }
    };
    const handleUpdateClick = async (username) => {
        await fetchUserDetails(username)
        setOpenUpdateDialog(true);
    };
    const handleResetPasswordClick = async (username) => {
        await fetchUserDetails(username)
        setOpenResetDialog(true);
    };

    const handleDeactiveClick = async (username) => {
        await fetchUserDetails(username)
        setOpenDeactiveDialog(true);
    };
    const getAvailableOptions = () => {
        const status = selectedUser ? selectedUser.status : undefined;
        if (!status || !permissions || !permissions['USER']) {
            return UNKNOWN_ACTIONS;
        }
        const actions = SELECTED_USER_ACTIONS.filter(action =>
            action.actStatus.includes(status) && permissions.USER?.includes(action.permission)
        );
        if (actions.length > 0 && !actions.find(action => action.code === selectedAction)) {
            setSelectedAction(actions[0].code);
        } else if (actions.length === 0) {
            return UNKNOWN_ACTIONS;
        }
        return actions;
    }
    const handleMenuItemClick = (event, selectedAction) => {
        setSelectedAction(selectedAction);
    };
    const handlePermissionsClick = async (username) => {
        await fetchUserDetails(username);
        setOpenPermissionsDialog(true);
    };
    const handleActionClick = async (action) => {
        try {
            if (!selectedUser) return;
            const username = selectedUser.username;
            switch (action) {
                case 'UPDATED': {
                    return await handleUpdateClick(username);
                }
                case 'UPDATED_PASSWORD': {
                    return await handleResetPasswordClick(username);
                }
                case 'LOCK_ACCOUNT': {
                    return await handleDeactiveClick(username);
                }
                case 'MANAGE_PERMISSIONS': { // New case for managing permissions
                    return await handlePermissionsClick(username);
                }
                case 'UNKNOWN': {
                    break;
                }
                default: {
                    return await handleUpdateClick(username);
                }
            }
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const disableButton = () => {
        return !selectedUser;
    }
    const handleSetSelectedRow = async (selectedRow, rows) => {
        try {
            const selectedId = selectedRow.length === 1 ? selectedRow[0] : 0;
            const rowData = rows.find((row) => row.username === selectedId);
            setSelectedUser(rowData);
        } catch (error) {
            console.log("Error at process selected item", selectedRow);
        }
    };
    return (
        <div>
            <Box>
                <Grid container spacing={2} sx={{paddingBottom: 2}}>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        {permissions.USER?.includes('CREATE') && (
                            <Button variant="contained" fullWidth onClick={handleAddNewClick} sx={{minWidth: 170}}>
                                Tạo mới
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <DropDownButton
                            fullWidth={true}
                            disabled={disableButton()}
                            options={getAvailableOptions()}
                            action={selectedAction}
                            handleActionClick={handleActionClick}
                            handleMenuItemClick={handleMenuItemClick}
                            isLoading={false}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{width: '100%', height: '60vh',}}>
                <DataGrid
                    slots={{
                        toolbar: GridToolbar,
                        loadingOverlay: LinearProgress,
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        toolbar: {
                            csvOptions: {disableToolbarButton: true},
                            printOptions: {disableToolbarButton: true},
                            showQuickFilter: true,
                        },
                    }}
                    disableColumnFilter={true}
                    disableDensitySelector={true}
                    paginationMode="server"
                    onRowSelectionModelChange={(selection) => handleSetSelectedRow(selection, users)}
                    rows={users}
                    columns={USER_COLUMNS}
                    pageSizeOptions={[100]}
                    rowCount={pagination.totalRowCount}
                    loading={isLoading}
                    paginationModel={pagination}
                    onPaginationModelChange={handleChangePage}
                />
            </Box>
            <CreateUser open={openAddDialog} onClose={async () => {
                setOpenAddDialog(false);
            }}/>
            <UpdateUser open={openUpdateDialog} onClose={() => {
                setOpenUpdateDialog(false);
            }} userData={userData}/>
            <ResetUserPassword open={openResetDialog} onClose={async () => {
                startLoadUser(pagination);
                setOpenResetDialog(false);
            }} userData={userData}/>
            <DeactiveUser open={openDeactiveDialog} onClose={async () => {
                startLoadUser(pagination);
                setOpenDeactiveDialog(false);
            }} userData={userData}/>
            <ManagePermissions open={openPermissionsDialog} onClose={() => {
                setOpenPermissionsDialog(false);
            }} userData={userData}/>
        </div>
    );
}
const mapStateToProps = state => ({
    isLoading: state.isLoading,
    users: state.users,
});

const mapDispatchToProps = dispatch => ({
    startLoadUser: pagination => dispatch(loadUsers(pagination)),
    onGetUserDetail: username => dispatch(getUserDetail(username)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserGrid);
