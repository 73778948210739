import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";


const CustomAccordion = ({panel, expanded, title, children, onChangeAccordion}) => {
    return (
        <Accordion expanded={expanded === panel} onChange={onChangeAccordion(panel)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                {title}
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
};
export default CustomAccordion;
