import React from "react";
import {AppBar, Box, Dialog, Grid, IconButton, Slide, Toolbar, Typography,} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CustomLoadingButton from "./CustomLoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const CustomDialog = ({open, onClose, title, children, dialogActions}) => {
    const handleClose = async () => {
        await onClose();
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullScreen TransitionComponent={Transition}>
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {title}
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box p={2}>
                    {children}
                </Box>
                <Box p={2}>
                    <Grid spacing={2} container>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            {dialogActions}
                            <CustomLoadingButton
                                sx={{minWidth: 170, marginBottom: 1}}
                                fullWidth
                                variant="contained"
                                buttonLabel={"Đóng"} onClick={handleClose}/>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};

export default CustomDialog;
