import * as React from 'react';
import {useEffect, useState} from 'react';
import {BarChart} from '@mui/x-charts/BarChart';
import {axisClasses} from '@mui/x-charts';
import {useAlert} from "../common/AlertProvider";
import {formatBarsData, formatCurrency, handleApiError, setEndOfTheDate, setStartOfTheDate} from "../constants";
import axios from "../axiosInstance";
import {PAWNS_API_ENDPOINT} from "../pawn/PawnConstants";
import {endOfMonth, startOfMonth, subMonths} from 'date-fns';
import {FormControl, Grid, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const chartSetting = {
    xAxis: [
        {
            label: 'Cầm đồ(x1000vnđ)',
        },
    ],
    maxWidth: 400,
    height: 400,
    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 0)',
        },
    },
};

const valueCountFormatter = (value) => `${value} đơn`;
const valueFormatter = (value) => `${formatCurrency(value)}`;
const TYPE_COUNT = {type: 'COUNT', label: 'Theo số đơn', valueCountFormatter};
const TYPE_AMOUNT = {type: 'AMOUNT', label: 'Theo số tiền', valueFormatter};
const FILTER_PAWNED = {dataKey: 'pawned', label: 'Đơn mới', valueFormatter};
const FILTER_REDEEMED = {dataKey: 'redeemed', label: 'Chuộc', valueFormatter};
const FILTER_FORFEITED = {dataKey: 'forfeited', label: 'Thanh lý', valueFormatter};
const FILTER_INTEREST = {dataKey: 'interest', label: 'Thu lãi', valueFormatter};

const PawnChart = () => {
    const {openAlert} = useAlert();
    const [barsData, setBarsData] = useState([]);
    const [chartDataType, setChartDataType] = useState(TYPE_AMOUNT);
    const [chartSeries, setChartSeries] = useState(FILTER_PAWNED)

    const formatChartData = (type = TYPE_AMOUNT, pawnedBars = [], redeemedBars = [], forfeitedBars = [], interestBars = []) => {
        const maxLength = Math.max(pawnedBars.length, redeemedBars.length, forfeitedBars.length, interestBars.length);
        return Array.from({length: maxLength}, (_, index) => {
            const {year, month, amount, count} = pawnedBars[index] || {};
            const redeemedEntry = redeemedBars.find(item => item?.year === year && item?.month === month) || {};
            const redeemedAmount = redeemedEntry.amount || 0;
            const redeemedCount = redeemedEntry.count || 0;
            const forfeitedEntry = forfeitedBars.find(item => item?.year === year && item?.month === month) || {};
            const forfeitedAmount = forfeitedEntry.amount || 0;
            const forfeitedCount = forfeitedEntry.count || 0;
            const interestEntry = interestBars.find(item => item?.year === year && item?.month === month) || {};
            const interest = interestEntry.amount || 0;
            const formatMonth = `${(month || 0).toString().padStart(2, '0')}/${year || ''}`;

            return {
                month: formatMonth,
                pawned: type === TYPE_AMOUNT ? amount || 0 : count || 0,
                redeemed: type === TYPE_AMOUNT ? redeemedAmount : redeemedCount,
                forfeited: type === TYPE_AMOUNT ? forfeitedAmount : forfeitedCount,
                interest: type === TYPE_AMOUNT ? interest : 0
            };
        });
    };
    const fetchPawnBars = async (filter = undefined) => {
        try {
            const now = new Date();
            const fromDate = startOfMonth(subMonths(now, 11)); // 12 months ago
            const toDate = endOfMonth(now);
            const currentYear = now.getFullYear();
            const currentMonth = now.getMonth() + 1; // JavaScript months are zero-based
            const requestBody = {fromDate: setStartOfTheDate(fromDate), toDate: setEndOfTheDate(toDate)}
            const response = await axios.post(`${PAWNS_API_ENDPOINT}/charts`, requestBody);
            const pawnedBars = formatBarsData(response.data.pawnedBars, currentYear, currentMonth);
            const redeemedBars = formatBarsData(response.data.redeemBars, currentYear, currentMonth);
            const forfeitedBars = formatBarsData(response.data.forfeitedBars, currentYear, currentMonth);
            const interestBars = formatBarsData(response.data.interestBars, currentYear, currentMonth);
            const bars = formatChartData(chartDataType, pawnedBars, redeemedBars, forfeitedBars, interestBars);
            setBarsData(bars);
        } catch (error) {
            console.error(error);
            handleApiError(error, openAlert);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            await fetchPawnBars();
        };
        fetchData().then(() => console.log("Pawn chart loaded!"));
        // eslint-disable-next-line
    }, [chartDataType, chartSeries]);

    const handleChange = (event) => {
        const type = event.target.value;
        if (TYPE_COUNT.type === type) {
            setChartDataType(TYPE_COUNT);
            setChartSeries({...chartSeries, valueFormatter: valueCountFormatter});
        } else {
            setChartDataType(TYPE_AMOUNT);
            setChartSeries({...chartSeries, valueFormatter: valueFormatter});
        }
    };
    const handleFilterChange = (event) => {
        const type = event.target.value;
        switch (type) {
            case FILTER_REDEEMED.dataKey: {
                setChartSeries(FILTER_REDEEMED);
                return;
            }
            case FILTER_FORFEITED.dataKey: {
                setChartSeries(FILTER_FORFEITED);
                return;
            }
            case FILTER_INTEREST.dataKey: {
                setChartSeries(FILTER_INTEREST);
                return;
            }
            default: {
                setChartSeries(FILTER_PAWNED);
                return;
            }
        }
    };
    return (
        <>
            <Grid container>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Chọn loại dữ liệu</InputLabel>
                            <Select
                                value={chartDataType.type}
                                label="Chọn loại dữ liệu"
                                onChange={handleChange}
                            >
                                <MenuItem value={TYPE_COUNT.type}>{TYPE_COUNT.label}</MenuItem>
                                <MenuItem value={TYPE_AMOUNT.type}>{TYPE_AMOUNT.label}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Lọc dữ liệu theo</InputLabel>
                            <Select
                                value={chartSeries.dataKey}
                                label="Lọc dữ liệu theo"
                                onChange={handleFilterChange}
                            >
                                <MenuItem value={FILTER_PAWNED.dataKey}>{FILTER_PAWNED.label}</MenuItem>
                                <MenuItem value={FILTER_REDEEMED.dataKey}>{FILTER_REDEEMED.label}</MenuItem>
                                <MenuItem value={FILTER_FORFEITED.dataKey}>{FILTER_FORFEITED.label}</MenuItem>
                                <MenuItem value={FILTER_INTEREST.dataKey}>{FILTER_INTEREST.label}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <BarChart
                        dataset={barsData}
                        yAxis={[{scaleType: 'band', dataKey: 'month'}]}
                        series={[chartSeries]}
                        layout="horizontal"
                        {...chartSetting}
                    />
                </Grid>
            </Grid>
        </>
    );
}
export default PawnChart
