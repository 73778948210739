import {Grid, Switch, TextField} from "@mui/material";
import {getResponsiveWidth} from "../constants";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import {DEFAULT_INVOICE_DATA, DEFAULT_VISITING_GUEST_NAME} from "./InvoiceConstant";

const BuyerInfo = forwardRef(({invoiceData, setInvoiceData, viewMode = false}, ref) => {
    const [isBuyerNameValid, setBuyerNameValid] = useState(true);
    const [isBuyerTaxCodeValid, setBuyerTaxCodeValid] = useState(true);
    const [isBuyerLegalNameValid, setBuyerLegalNameValid] = useState(true);
    const [isEditable, setEditable] = useState(true);
    useEffect(() => {
        if (viewMode) {
            setEditable(false);
        } else {
            setEditable(true);
        }
    }, [viewMode]);
    const validateRequest = () => {
        let validated = true;
        const visitingGuest = invoiceData.buyerInfo.visitingGuest;
        if (!visitingGuest) {
            if (invoiceData.buyerInfo.buyerName && invoiceData.buyerInfo.buyerName !== '') {
                setBuyerNameValid(true);
            } else {
                validated = false;
                setBuyerNameValid(false);
            }
            if (invoiceData.buyerInfo.buyerTaxCode && invoiceData.buyerInfo.buyerTaxCode !== '') {
                setBuyerTaxCodeValid(true);
            } else {
                validated = false;
                setBuyerTaxCodeValid(false);
            }
            if (invoiceData.buyerInfo.buyerLegalName && invoiceData.buyerInfo.buyerLegalName !== '') {
                setBuyerLegalNameValid(true);
            } else {
                validated = false;
                setBuyerLegalNameValid(false);
            }
        }
        return validated;
    }
    useImperativeHandle(ref, () => ({validateRequest}));

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={getResponsiveWidth(12, 12)} justifyContent="center">
                    <FormControlLabel control={
                        <Switch
                            disabled={!isEditable}
                            checked={invoiceData.buyerInfo.visitingGuest}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setInvoiceData(prevState => ({
                                        ...prevState,
                                        buyerInfo: {
                                            ...DEFAULT_INVOICE_DATA.buyerInfo,
                                            buyerName: DEFAULT_VISITING_GUEST_NAME,
                                        },
                                    }));
                                } else {
                                    setInvoiceData(prevState => ({
                                        ...prevState,
                                        buyerInfo: {
                                            ...prevState.buyerInfo,
                                            visitingGuest: e.target.checked,
                                            buyerName: '',
                                        },
                                    }));
                                }
                            }}/>
                    }
                                      label="Khách lẻ không lấy hóa đơn?"/>
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <TextField
                        readOnly={invoiceData.buyerInfo.visitingGuest}
                        disabled={!isEditable || invoiceData.buyerInfo.visitingGuest}
                        required={!invoiceData.buyerInfo.visitingGuest}
                        label="Họ tên người mua hàng"
                        value={invoiceData.buyerInfo.buyerName || ''}
                        onChange={(e) => {
                            setInvoiceData(prevState => ({
                                ...prevState,
                                buyerInfo: {
                                    ...prevState.buyerInfo,
                                    buyerName: e.target.value,
                                },
                            }));
                            setBuyerNameValid(true);
                        }}
                        fullWidth
                        inputProps={{maxLength: 150}}
                        error={!isBuyerNameValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <TextField
                        readOnly={invoiceData.buyerInfo.visitingGuest}
                        disabled={!isEditable || invoiceData.buyerInfo.visitingGuest}
                        label="Số CCCD/CMND người mua"
                        value={invoiceData.buyerInfo.buyerIdNo || ''}
                        onChange={(e) => {
                            setInvoiceData(prevState => ({
                                ...prevState,
                                buyerInfo: {
                                    ...prevState.buyerInfo,
                                    buyerIdNo: e.target.value,
                                },
                            }));
                        }}
                        fullWidth
                        inputProps={{maxLength: 15}}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <TextField
                        label="Tên công ty"
                        readOnly={invoiceData.buyerInfo.visitingGuest}
                        disabled={!isEditable || invoiceData.buyerInfo.visitingGuest}
                        value={invoiceData.buyerInfo.buyerLegalName || ''}
                        onChange={(e) => {
                            setInvoiceData(prevState => ({
                                ...prevState,
                                buyerInfo: {
                                    ...prevState.buyerInfo,
                                    buyerLegalName: e.target.value,
                                },
                            }));
                            setBuyerLegalNameValid(true);
                        }}
                        fullWidth
                        error={!isBuyerLegalNameValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <TextField
                        label="Mã số thuế"
                        required={!invoiceData.buyerInfo.visitingGuest}
                        disabled={!isEditable || invoiceData.buyerInfo.visitingGuest}
                        value={invoiceData.buyerInfo.buyerTaxCode || ''}
                        onChange={(e) => {
                            setInvoiceData(prevState => ({
                                ...prevState,
                                buyerInfo: {
                                    ...prevState.buyerInfo,
                                    buyerTaxCode: e.target.value,
                                },
                            }));
                            setBuyerTaxCodeValid(true);
                        }}
                        fullWidth
                        inputProps={{maxLength: 15}}
                        error={!isBuyerTaxCodeValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <TextField
                        label="Số điện thoại"
                        readOnly={invoiceData.buyerInfo.visitingGuest}
                        disabled={!isEditable || invoiceData.buyerInfo.visitingGuest}
                        value={invoiceData.buyerInfo.buyerPhoneNumber || ''}
                        onChange={(e) => {
                            setInvoiceData(prevState => ({
                                ...prevState,
                                buyerInfo: {
                                    ...prevState.buyerInfo,
                                    buyerPhoneNumber: e.target.value,
                                },
                            }));
                        }}
                        fullWidth
                        inputProps={{maxLength: 12}}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <TextField
                        label="Số fax"
                        readOnly={invoiceData.buyerInfo.visitingGuest}
                        disabled={!isEditable || invoiceData.buyerInfo.visitingGuest}
                        value={invoiceData.buyerInfo.buyerPhoneNumber || ''}
                        onChange={(e) => {
                            setInvoiceData(prevState => ({
                                ...prevState,
                                buyerInfo: {
                                    ...prevState.buyerInfo,
                                    buyerPhoneNumber: e.target.value,
                                },
                            }));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <TextField
                        label="Địa chỉ email"
                        readOnly={invoiceData.buyerInfo.visitingGuest}
                        disabled={!isEditable}
                        value={invoiceData.buyerInfo.buyerEmail || ''}
                        onChange={(e) => {
                            setInvoiceData(prevState => ({
                                ...prevState,
                                buyerInfo: {
                                    ...prevState.buyerInfo,
                                    buyerEmail: e.target.value,
                                },
                            }));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Địa chỉ"
                        readOnly={invoiceData.buyerInfo.visitingGuest}
                        disabled={!isEditable || invoiceData.buyerInfo.visitingGuest}
                        value={invoiceData.buyerInfo.buyerAddressLine || ''}
                        onChange={(e) => {
                            setInvoiceData(prevState => ({
                                ...prevState,
                                buyerInfo: {
                                    ...prevState.buyerInfo,
                                    buyerAddressLine: e.target.value,
                                },
                            }));
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </>
    );
})
export default BuyerInfo;
