import React, {useEffect, useState} from "react";
import CustomLoadingButton from "../common/CustomLoadingButton";
import CustomDialog from "../common/CustomDialog";
import {Grid, MenuItem, Select, TextField} from "@mui/material";
import {InterestTypes} from "../pawn/PawnConstants";
import {getResponsiveWidth} from "../constants";

const SysConfigDetails = ({open, onClose, sourceData, onItemUpdated}) => {
    const [systemConfig, setSystemConfig] = useState(sourceData);

    useEffect(() => {
        setSystemConfig(sourceData);
    }, [open, sourceData]);
    const handleClose = async () => {
        onClose();
    };

    const handleUpdatedItem = async () => {
        let validated = true;
        //todo to validate request data
        if (validated) {
            return onItemUpdated(systemConfig);
        }
    }

    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleUpdatedItem}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu thay đổi"/>
            <CustomLoadingButton variant="contained" onClick={handleClose}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Hủy"/>
        </>
    );
    const pageTitle = (
        <>
            {`Cập nhật thông tin: [${systemConfig.description}]`}
        </>
    );
    return (
        <>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Ký hiệu"
                            value={systemConfig.key ? systemConfig.key : ''}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Loại cấu hình"
                            value={systemConfig.description ? systemConfig.description : ''}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        {systemConfig.key === 'PAWN_INTEREST_CALCULATION' ? (
                            <Select
                                value={systemConfig.value ? systemConfig.value : 30}
                                fullWidth
                                onChange={(e) => {
                                    setSystemConfig((prev) => ({
                                        ...prev,
                                        value: e.target.value,
                                    }));
                                }}
                            >
                                {Object.values(InterestTypes).map((interestType) => (
                                    <MenuItem key={interestType.code} value={interestType.code}>
                                        {interestType.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : systemConfig.key === 'EXCLUDE_VISIBLE_ITEM' ? (
                            <Select
                                value={systemConfig.value ? systemConfig.value : 'NO'}
                                fullWidth
                                onChange={(e) => {
                                    setSystemConfig((prev) => ({
                                        ...prev,
                                        value: e.target.value,
                                    }));
                                }}
                            >
                                <MenuItem value="YES">YES</MenuItem>
                                <MenuItem value="NO">NO</MenuItem>
                            </Select>
                        ) : (
                            <TextField
                                label="Giá trị"
                                value={systemConfig.value ? systemConfig.value : ''}
                                fullWidth
                                InputProps={{ type: 'search' }}
                                onChange={(e) => {
                                    setSystemConfig((prev) => ({
                                        ...prev,
                                        value: e.target.value,
                                    }));
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </CustomDialog>
        </>
    );
}
export default SysConfigDetails;
