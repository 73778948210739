import * as React from "react";
import {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {vi_locale} from "dayjs/locale/vi";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {convertDayjsDate, DATE_SHORT_FORMAT, setUserTime} from "../constants";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const DatetimeDialog = ({open, onSelected}) => {
    const [filter, setFilter] = useState({
        fromDate: convertDayjsDate(new Date()),
        toDate: convertDayjsDate(new Date())
    });
    const handleSelect = () => {
        onSelected({fromDate: filter.fromDate.toDate(), toDate: filter.toDate.toDate()});
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
        >
            <DialogTitle>{"Vui lòng chọn ngày"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} justifyContent="center" mt={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                            <DatePicker label="Từ ngày"
                                        size="small"
                                        value={filter.fromDate}
                                        onChange={(date) => {
                                            const selectedDate = setUserTime(date);
                                            setFilter((prev) => ({
                                                ...prev,
                                                fromDate: selectedDate,
                                                // Ensure toDate is not less than fromDate
                                                toDate: selectedDate > filter.toDate ? selectedDate : filter.toDate,
                                            }));
                                        }}
                                        fullWidth
                                        format={DATE_SHORT_FORMAT}
                                        slotProps={{
                                            field: {shouldRespectLeadingZeros: true},
                                            textField: {fullWidth: true}
                                        }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                            <DatePicker label="Đến ngày"
                                        value={filter.toDate}
                                        onChange={(date) => {
                                            const selectedDate = setUserTime(date);
                                            setFilter((prev) => ({
                                                ...prev,
                                                toDate: selectedDate,
                                            }));
                                        }}
                                        fullWidth
                                        minDate={filter.fromDate}
                                        format={DATE_SHORT_FORMAT}
                                        slotProps={{
                                            field: {shouldRespectLeadingZeros: true},
                                            textField: {fullWidth: true}
                                        }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSelect}>Chọn</Button>
            </DialogActions>
        </Dialog>
    );
}
export default DatetimeDialog;
