import {connect} from "react-redux";
import {deleteInvoiceRequest} from "./invoiceThunks";
import React, {useRef} from "react";
import CustomDialog from "../common/CustomDialog";
import GeneralInvoiceInfo from "./GeneralInvoiceInfo";
import {useAlert} from "../common/AlertProvider";
import CustomLoadingButton from "../common/CustomLoadingButton";
import {handleApiError, handleSuccessMessage} from "../constants";

const DeleteInvoice = ({open, onClose, invoiceData, onDeletePressed}) => {
    const {openAlert} = useAlert();
    const generalInfoRef = useRef();

    const handleClose = async () => {
        onClose();
    };
    const onDeleteInvoiceClick = async () => {
        try {
            const response = await onDeletePressed(invoiceData);
            handleSuccessMessage(`Hóa đơn số ${invoiceData.invoiceId} được xóa thành công!`, openAlert);
            onClose(response);
        } catch (error) {
            handleApiError(error, openAlert);
        }

    }
    const formActions = (
        <>
            {(invoiceData.status !== 'COMPLETED') && (
                <CustomLoadingButton variant="contained" onClick={onDeleteInvoiceClick}
                                     sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Xóa hóa đơn"/>)}
        </>
    );
    const pageTitle = (
        <>
            {`Xóa hóa đơn số ${invoiceData.invoiceId}?`}
        </>
    );

    return (
        <>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <GeneralInvoiceInfo invoiceData={invoiceData}
                                    setInvoiceData={() => console.log("Set Invoice")}
                                    onSearchOrder={() => console.log('Search clicked!')}
                                    ref={generalInfoRef} viewMode={true}/>
            </CustomDialog>
        </>
    )
}
const mapStateToProps = state => ({
    invoices: state.invoices,
});

const mapDispatchToProps = dispatch => ({
    onDeletePressed: invoice => dispatch(deleteInvoiceRequest(invoice.invoiceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInvoice)
