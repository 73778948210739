import {connect} from "react-redux";
import {
    downloadInvoiceFile,
    sendEmailInvoiceRequest,
    updateAndCompleteInvoiceRequest,
    updateInvoiceRequest
} from "./invoiceThunks";
import React, {useEffect, useRef} from "react";
import CustomDialog from "../common/CustomDialog";
import CustomAccordion from "../common/CustomAccordion";
import GeneralInvoiceInfo from "./GeneralInvoiceInfo";
import {useAlert} from "../common/AlertProvider";
import BuyerInfo from "./BuyerInfo";
import InvoiceItem from "./InvoiceItem";
import CustomLoadingButton from "../common/CustomLoadingButton";
import {handleApiError, handleErrorMessage, handleSuccessMessage} from "../constants";

const InvoiceDetails = ({
                            open, onClose, invoiceData,
                            onUpdatePressed,
                            viewMode = true,
                            onDownloadPressed,
                            onSendEmailPressed,
                            onUpdateAndCompletePressed
                        }) => {
    const {openAlert} = useAlert();
    const [invoice, setInvoice] = React.useState(invoiceData);
    const [expanded, setExpanded] = React.useState('panel0');
    const buyerInfoRef = useRef();
    const generalInfoRef = useRef();
    const handleChangeAccordion = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        setInvoice(invoiceData);
    }, [open, invoiceData])

    const handleClose = async () => {
        onClose();
    };
    const validateInvoiceForUpdate = () => {
        let validated = true;
        const isBuyerInfoValid = buyerInfoRef.current.validateRequest();
        const isGeneralInfoValid = generalInfoRef.current.validateRequest();
        if (!isBuyerInfoValid) {
            setExpanded("panel1");
            validated = false;
        }
        if (invoiceData.itemInfo.length <= 0) {
            validated = false;
            setExpanded("panel2");
            handleErrorMessage('Chi tiết hóa đơn không được để trống', openAlert);
        }
        if (!isGeneralInfoValid) {
            setExpanded("panel0");
            validated = false;
        }
        return validated;
    }
    const onUpdateInvoiceClick = async () => {
        let validated = validateInvoiceForUpdate();
        if (validated) {
            try {
                const response = await onUpdatePressed(invoice);
                handleSuccessMessage('Hóa đơn được lưu thành công!', openAlert);
                onClose(response);
            } catch (error) {
                handleApiError(error, openAlert);
            }
        }
    }
    const onUpdateAndCompleteInvoiceClick = async () => {
        let validated = validateInvoiceForUpdate();
        if (validated) {
            try {
                const response = await onUpdateAndCompletePressed(invoice);
                handleSuccessMessage('Hóa đơn được lưu thành công!', openAlert);
                onClose(response);
            } catch (error) {
                handleApiError(error, openAlert);
            }
        }
    }
    const onDownloadInvoiceClick = async () => {
        try {
            if (invoice.status !== 'COMPLETED' && invoice.invoiceNo === '') {
                handleErrorMessage('Hóa đơn chưa hoàn thành, không thể tải file!', openAlert);
                return;
            }
            const response = await onDownloadPressed(invoice);
            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice_${invoice.invoiceId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            handleSuccessMessage(`Tải file hóa đơn thành công. Vui lòng kiểm tra trong thư mục 'Tải Về/Download'!`, openAlert);
            onClose();
        } catch (error) {
            handleApiError(error, openAlert);
        }
    }
    const onSendEmailInvoiceClick = async () => {
        try {
            if (invoice.status !== 'COMPLETED' && invoice.invoiceNo === '') {
                handleErrorMessage('Hóa đơn chưa hoàn thành, không thể gửi email!', openAlert);
                return;
            }
            if (invoice.buyerInfo.buyerEmail === '') {
                handleErrorMessage('Hóa đơn lập bỏ trống email, không thể gửi email!', openAlert);
                return;
            }
            await onSendEmailPressed(invoice);
            handleSuccessMessage(`Gửi email file hóa đơn thành công. Vui lòng kiểm tra trong thư mục email!`, openAlert);
            onClose();
        } catch (error) {
            handleApiError(error, openAlert);
        }
    }
    const formActions = (
        <>
            {(viewMode === false) && (
                <CustomLoadingButton variant="contained" onClick={onUpdateInvoiceClick}
                                     sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Chỉ lưu thay đổi"/>)}
            {(viewMode === false) && (
                <CustomLoadingButton variant="contained" onClick={onUpdateAndCompleteInvoiceClick}
                                     sx={{minWidth: 170, marginBottom: 1}} fullWidth
                                     buttonLabel="Lưu và xuất hóa đơn thuế"/>)}
            {(viewMode === true && invoiceData.status === 'COMPLETED') && (
                <CustomLoadingButton variant="contained" onClick={onDownloadInvoiceClick}
                                     sx={{minWidth: 170, marginBottom: 1}} fullWidth
                                     buttonLabel="Tải file hóa đơn"/>)}
            {(viewMode === true && invoiceData.status === 'COMPLETED' && invoiceData.buyerInfo?.buyerEmail !== '') && (
                <CustomLoadingButton variant="contained" onClick={onSendEmailInvoiceClick}
                                     sx={{minWidth: 170, marginBottom: 1}} fullWidth
                                     buttonLabel="Gửi email hóa đơn"/>)}
        </>
    );
    const pageTitle = (
        <>
            {(viewMode === false) ? `Cập nhật thông tin hóa đơn số ${invoiceData.invoiceId}` : `Thông tin chi tiết hóa đơn số ${invoiceData.invoiceId}`}
        </>
    );

    return (
        <>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <CustomAccordion
                    title="Thông tin hóa đơn"
                    panel='panel0'
                    expanded={expanded}
                    onChangeAccordion={handleChangeAccordion}>
                    <GeneralInvoiceInfo invoiceData={invoice}
                                        setInvoiceData={setInvoice} onSearchOrder={() => {
                        console.log('Search clicked!')
                    }}
                                        ref={generalInfoRef} viewMode={viewMode}/>
                </CustomAccordion>
                <CustomAccordion expanded={expanded}
                                 title="Thông tin khách hàng"
                                 panel='panel1'
                                 onChangeAccordion={handleChangeAccordion}>
                    <BuyerInfo invoiceData={invoice}
                               setInvoiceData={setInvoice}
                               ref={buyerInfoRef}
                               viewMode={viewMode}
                    />
                </CustomAccordion>
                <CustomAccordion expanded={expanded}
                                 title="Chi tiết hóa đơn"
                                 panel='panel2'
                                 onChangeAccordion={handleChangeAccordion}>
                    <InvoiceItem invoiceData={invoice} setInvoiceData={setInvoice} viewMode={viewMode}></InvoiceItem>
                </CustomAccordion>
            </CustomDialog>
        </>
    )
}
const mapStateToProps = state => ({
    invoices: state.invoices,
});

const mapDispatchToProps = dispatch => ({
    onUpdatePressed: invoice => dispatch(updateInvoiceRequest(invoice.invoiceId, invoice)),
    onDownloadPressed: invoice => dispatch(downloadInvoiceFile(invoice.invoiceId)),
    onSendEmailPressed: invoice => dispatch(sendEmailInvoiceRequest(invoice.invoiceId)),
    onUpdateAndCompletePressed: invoice => dispatch(updateAndCompleteInvoiceRequest(invoice.invoiceId, invoice)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails)
