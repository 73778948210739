import {Grid, TextField} from "@mui/material";
import React from "react";
import {DATE_SHORT_FORMAT, getResponsiveWidth, setUserTime} from "../constants";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {vi_locale} from "dayjs/locale/vi";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const NationalCard = ({customerData, setCustomerData, isReadOnly = false}) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        disabled={isReadOnly}
                        label="Số CCCD"
                        value={customerData.nationalNumber || ''}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/[^0-9]/g, '');
                            setCustomerData((prev) => ({
                                ...prev,
                                nationalNumber: numericInput,
                            }));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        disabled={isReadOnly}
                        label="Họ và Tên(CCCD)"
                        value={customerData.nationalName || ''}
                        onChange={(e) => {
                            setCustomerData((prev) => ({
                                ...prev,
                                nationalName: e.target.value,
                            }));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        disabled={isReadOnly}
                        label="Quê quán"
                        value={customerData.homeTown || ''}
                        onChange={(e) => {
                            setCustomerData((prev) => ({
                                ...prev,
                                homeTown: e.target.value,
                            }));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        disabled={isReadOnly}
                        label="Nơi thường trú"
                        value={customerData.residenceAddress || ''}
                        onChange={(e) => {
                            setCustomerData((prev) => ({
                                ...prev,
                                residenceAddress: e.target.value,
                            }));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth()}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                        <DatePicker label="Ngày cấp"
                                    disabled={isReadOnly}
                                    value={customerData.issuedDate ? customerData.issuedDate : null}
                                    onChange={(date) => {
                                        const selectedDate = setUserTime(date);
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            issuedDate: selectedDate,
                                        }));
                                    }}
                                    fullWidth
                                    format={DATE_SHORT_FORMAT}
                                    slotProps={{
                                        field: {shouldRespectLeadingZeros: true, clearable: true},
                                        textField: {fullWidth: true}
                                    }}
                                    disableFuture={true}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Nơi cấp"
                        disabled={isReadOnly}
                        value={customerData.issuedBy || ''}
                        onChange={(e) => {
                            setCustomerData((prev) => ({
                                ...prev,
                                issuedBy: e.target.value,
                            }));
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </>
    );
}
export default NationalCard;
