import React, {useEffect, useState} from "react";
import CustomLoadingButton from "../common/CustomLoadingButton";
import CustomDialog from "../common/CustomDialog";
import {Grid, Switch, TextField} from "@mui/material";
import {getResponsiveWidth} from "../constants";
import FormControlLabel from "@mui/material/FormControlLabel";

const GoldTypeDetails = ({open, onClose, sourceData, onItemUpdated}) => {
    const [goldType, setGoldType] = useState(sourceData);
    useEffect(() => {
        setGoldType(sourceData);
    }, [open, sourceData]);
    const handleClose = async () => {
        onClose();
    };

    const handleUpdatedItem = async () => {
        let validated = true;
        //todo to validate request data
        if (validated) {
            return onItemUpdated(goldType);
        }
    }

    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleUpdatedItem}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu thay đổi"/>
            <CustomLoadingButton variant="contained" onClick={handleClose}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Hủy"/>
        </>
    );
    const pageTitle = (
        <>
            {`Cập nhật thông tin loại vàng/bạc: [${goldType.label}]`}
        </>
    );
    return (
        <>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Mã số"
                            value={goldType.id ? goldType.id : 0}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Ký hiệu"
                            value={goldType.code ? goldType.code : ''}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Tên hiển thị"
                            value={goldType.label ? goldType.label : ''}
                            fullWidth
                            InputProps={{type: 'search'}}
                            onChange={(e) => {
                                setGoldType((prev) => ({
                                    ...prev,
                                    label: e.target.value,
                                }));
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Thứ tự hiển thị"
                            value={goldType.order ? goldType.order : 0}
                            fullWidth
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                setGoldType((prev) => ({
                                    ...prev,
                                    order: numericInput,
                                }));
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)} justifyContent="center">
                        <FormControlLabel control={
                            <Switch
                                checked={goldType.silverType ? goldType.silverType : false}
                                onChange={(e) => {
                                    setGoldType((prev) => {
                                        return {
                                            ...prev,
                                            silverType: e.target.checked,
                                        };
                                    });
                                }}/>
                        }
                                          label="Bạc?"/>
                    </Grid>
                </Grid>
            </CustomDialog>
        </>
    )
}
export default GoldTypeDetails;
