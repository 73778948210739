import React, {useEffect, useState} from 'react';
import {Avatar, Box, Divider, Grid, IconButton, TextField} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {useAlert} from '../common/AlertProvider';
import CustomLoadingButton from '../common/CustomLoadingButton';
import CustomDialog from '../common/CustomDialog';
import {
    API_ENDPOINT,
    DEFAULT_PRIMARY_COLOR,
    getResponsiveWidth,
    handleApiError,
    handleErrorMessage,
    handleSuccessMessage,
    LOCAL_STORAGE_AVATAR,
    LOCAL_STORAGE_PRIMARY_COLOR
} from '../constants';
import axios from "../axiosInstance";
import heic2any from "heic2any";
import ImageCompressor from 'image-compressor.js';

const UserSettings = ({userData, open, onClose}) => {
    const {openAlert} = useAlert();
    const [isColorChanged, setIsColorChanged] = useState(false);
    const [isAvatarChanged, setIsAvatarChanged] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [userProfile, setUserProfile] = useState(userData);
    const [isDefaultColor, setIsDefaultColor] = useState(true);
    const [passwordMissMatch, setPasswordMissMatch] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);

    useEffect(() => {
        async function getUserProfile() {
            setUserProfile({
                ...userData,
                color: userData.color || DEFAULT_PRIMARY_COLOR,
                password: '',
                confirmPassword: '',
                currentPassword: ''
            });
            if (userData.color && userData.color !== DEFAULT_PRIMARY_COLOR) {
                setIsDefaultColor(false);
            }
        }

        getUserProfile();
    }, [userData, open]);

    const handleColorChange = (event) => {
        setUserProfile((prev) => ({
            ...prev,
            color: event.target.value,
        }));
        setIsColorChanged(true);
    };

    const handleAvatarChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();

            // Function to handle HEIC conversion and compression
            const handleHeicAndCompress = async (heicFile) => {
                try {
                    const convertedBlob = await heic2any({
                        blob: heicFile,
                        toType: 'image/jpeg',
                        quality: 1
                    });
                    const compressedFile = await compressImage(convertedBlob);
                    const base64String = await convertBlobToBase64(compressedFile);
                    setUserProfile((prev) => ({
                        ...prev,
                        avatar: base64String,
                    }));
                    setIsAvatarChanged(true);
                } catch (error) {
                    console.error('Error converting and compressing HEIC image:', error);
                    handleErrorMessage('Error converting and compressing HEIC image. Please try again.', openAlert);
                }
            };

            // Function to handle compression for JPEG and PNG
            const handleJpegPngAndCompress = async (jpegPngFile) => {
                try {
                    const compressedFile = await compressImage(jpegPngFile);
                    const base64String = await convertBlobToBase64(compressedFile);
                    setUserProfile((prev) => ({
                        ...prev,
                        avatar: base64String,
                    }));
                    setIsAvatarChanged(true);
                } catch (error) {
                    console.error('Error compressing JPEG or PNG image:', error);
                    handleErrorMessage('Error compressing JPEG or PNG image. Please try again.', openAlert);
                }
            };

            // Compress image function using image-compressor.js
            const compressImage = async (imageBlob) => {
                return new Promise((resolve, reject) => {
                    const imageCompressor = new ImageCompressor();
                    imageCompressor.compress(imageBlob, {
                        quality: 0.7, // Adjust quality as needed
                        maxWidth: 600, // Adjust maxWidth as needed
                        maxHeight: 600, // Adjust maxHeight as needed
                        success: (compressedFile) => {
                            resolve(compressedFile);
                        },
                        error: (error) => {
                            reject(error);
                        },
                    });
                });
            };

            // Function to convert Blob to base64 string
            const convertBlobToBase64 = (blob) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64String = reader.result.split(',')[1];
                        resolve(base64String);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            };
            const reader = new FileReader();
            reader.onload = async (e) => {
                if (file.type === 'image/heic' || fileExtension === 'heic') {
                    await handleHeicAndCompress(file);
                } else if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' ||
                    fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'jpg') {
                    await handleJpegPngAndCompress(file);
                } else {
                    handleErrorMessage('Định đang file không đúng. Vui lòng chọn file HEIC, JPEG hoặc PNG ảnh.', openAlert);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePasswordChange = (event) => {
        setUserProfile((prev) => ({
            ...prev,
            password: event.target.value,
        }));
        setIsPasswordChanged(true);
    };

    const handleCurrentPasswordChange = (event) => {
        setUserProfile((prev) => ({
            ...prev,
            currentPassword: event.target.value,
        }));
        setIsPasswordChanged(true);
        setPasswordInvalid(false);
    };

    const handleConfirmPasswordChange = (event) => {
        setUserProfile((prev) => ({
            ...prev,
            confirmPassword: event.target.value,
        }));
        if (event.target.value !== userProfile.password) {
            setPasswordMissMatch(true);
        } else {
            setPasswordMissMatch(false);
        }
    };

    const handleSaveColorChanges = async () => {
        await axios.put(`${API_ENDPOINT}/profiles/color`, userProfile);
        handleSuccessMessage("Thay đổi màu thành công", openAlert);
        localStorage.setItem(LOCAL_STORAGE_PRIMARY_COLOR, userProfile.color);
        setIsColorChanged(false);
        window.dispatchEvent(new Event("storage"));
    };

    const handleSetDefaultColor = async () => {
        setUserProfile((prev) => ({
            ...prev,
            color: DEFAULT_PRIMARY_COLOR,
        }));
        setIsColorChanged(true);
        setIsDefaultColor(true);
    };
    const handleSaveColor = async () => {
        try {
            await handleSaveColorChanges();
        } catch (error) {
            console.error('Error while updating color:', error);
            handleApiError(error, openAlert);
        }
    };

    const handleSaveAvatar = async () => {
        try {
            await axios.put(`${API_ENDPOINT}/profiles/avatar`, userProfile);
            handleSuccessMessage("Thay đổi avatar thành công", openAlert)
            localStorage.setItem(LOCAL_STORAGE_AVATAR, userProfile.avatar);
            setIsAvatarChanged(false);
        } catch (error) {
            console.error('Error while updating avatar:', error);
            handleApiError(error, openAlert);
        }
    };

    const handleSavePassword = async () => {
        if (userProfile.password !== userProfile.confirmPassword) {
            handleErrorMessage("Mật khẩu mới trùng không khớp. Vui lòng kiểm tra lại!", openAlert);
            return;
        }

        try {
            await axios.put(`${API_ENDPOINT}/profiles/password`, userProfile);
            handleSuccessMessage("Thay đổi mật khẩu thành công", openAlert)
            setUserProfile((prev) => ({
                ...prev,
                password: '',
                currentPassword: '',
                confirmPassword: ''
            }));
            setIsPasswordChanged(false);
            setPasswordMissMatch(false);
            setPasswordInvalid(false);
        } catch (error) {
            console.error('Error while updating password:', error);
            if (error.response && error.response.status === 400 && error.response.data && error.response.data.errorCode === 'WRONG_PASSWORD_EXCEPTION') {
                setPasswordInvalid(true);
                handleErrorMessage('Mật khẩu cũ không khớp. Vui lòng kiểm tra lại!', openAlert);
            } else {
                handleApiError(error, openAlert);
            }
        }
    };

    const handleClose = () => {
        setIsColorChanged(false);
        setIsAvatarChanged(false);
        setIsPasswordChanged(false);
        setIsDefaultColor(false);
        setPasswordMissMatch(false);
        setPasswordInvalid(false);
        onClose();
    };

    const formActions = (
        <>
        </>
    );

    const pageTitle = <>Cập nhật thông tin của bạn</>;
    return (
        <>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Divider>Thay đổi màu ứng dụng</Divider>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Màu chính"
                            type="color"
                            value={userProfile.color}
                            onChange={handleColorChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <CustomLoadingButton
                            variant="contained"
                            onClick={handleSaveColor}
                            sx={{minWidth: 170, marginBottom: 1}}
                            fullWidth
                            buttonLabel="Lưu thay đổi màu"
                            disabled={!isColorChanged}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <CustomLoadingButton
                            variant="contained"
                            onClick={handleSetDefaultColor}
                            sx={{minWidth: 170, marginBottom: 1}}
                            fullWidth
                            buttonLabel="Đặt về mặc định"
                            disabled={isDefaultColor}
                        />
                    </Grid>
                </Grid>
                <Divider>Thay đổi Avatar</Divider>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {userProfile.avatar ? (
                                <Avatar src={`data:image/png;base64,${userProfile.avatar}`}
                                        sx={{width: 100, height: 100, marginBottom: 2}}/>
                            ) : (
                                <Avatar sx={{width: 100, height: 100, marginBottom: 2}}>
                                </Avatar>
                            )}
                            <IconButton color="primary" aria-label="upload picture" component="label">
                                <input hidden accept="image/*" type="file" onChange={handleAvatarChange}/>
                                <PhotoCamera/>
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <CustomLoadingButton
                            variant="contained"
                            onClick={handleSaveAvatar}
                            sx={{minWidth: 170, marginBottom: 1}}
                            fullWidth
                            buttonLabel="Lưu Avatar"
                            disabled={!isAvatarChanged}
                        />
                    </Grid>
                </Grid>
                <Divider>Thay đổi mật khẩu</Divider>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Mật khẩu cũ"
                            type="password"
                            value={userProfile.currentPassword}
                            onChange={handleCurrentPasswordChange}
                            variant="outlined"
                            fullWidth
                            error={passwordInvalid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Mật khẩu mới"
                            type="password"
                            value={userProfile.password}
                            onChange={handlePasswordChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Xác nhận mật khẩu mới"
                            type="password"
                            value={userProfile.confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            variant="outlined"
                            fullWidth
                            error={passwordMissMatch}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <CustomLoadingButton
                            variant="contained"
                            onClick={handleSavePassword}
                            sx={{minWidth: 170}}
                            fullWidth
                            buttonLabel="Đổi mật khẩu"
                            disabled={!isPasswordChanged}
                        />
                    </Grid>
                </Grid>
            </CustomDialog>
        </>
    );
};

export default UserSettings;
