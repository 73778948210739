import {Grid, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import {getResponsiveWidth, getSortedPaymentTypes} from "../constants";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {vi_locale} from "dayjs/locale/vi";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import {useLocation, useNavigate} from "react-router-dom";

const GeneralInvoiceInfo = forwardRef(({invoiceData, setInvoiceData, onSearchOrder, viewMode = false}, ref) => {
    const [isInvoiceSeriesValid, setInvoiceSeriesValid] = useState(true);
    const [isEditable, setEditable] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (viewMode) {
            setEditable(false);
        } else {
            setEditable(true);
        }
    }, [viewMode]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        async function checkInvoiceRequest() {
            const orderId = params.get('orderId');
            if (orderId > 0) {
                onSearchOrder(orderId);
                navigate('/invoice-mgmt');
            }
        }
        checkInvoiceRequest();
    }, [location.search, onSearchOrder, navigate]);
    const handleSearchClick = async () => {
        onSearchOrder(invoiceData.orderId);
    };
    const validateRequest = () => {
        let validated = true;
        if (invoiceData.invoiceSeries && invoiceData.invoiceSeries !== '') {
            setInvoiceSeriesValid(true);
        } else {
            validated = false;
            setInvoiceSeriesValid(false);
        }
        return validated;
    }
    useImperativeHandle(ref, () => ({validateRequest}));
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <TextField
                        label="Ký hiệu hóa đơn"
                        required
                        disabled={!isEditable}
                        value={invoiceData.invoiceSeries || ''}
                        onChange={(e) => {
                            setInvoiceData((prev) => ({
                                ...prev,
                                invoiceSeries: e.target.value,
                            }));
                            setInvoiceSeriesValid(true);
                        }}
                        fullWidth
                        error={!isInvoiceSeriesValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <TextField
                        label="Nhập đơn đơn hàng tìm kiếm"
                        required
                        disabled={!isEditable}
                        value={invoiceData.orderId === 0 ? '' : invoiceData.orderId}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/[^0-9]/g, '');
                            setInvoiceData((prev) => ({
                                ...prev,
                                orderId: numericInput,
                            }));
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{cursor: 'pointer'}} onClick={handleSearchClick}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <Select
                        value={invoiceData.paymentType}
                        fullWidth
                        disabled={!isEditable}
                        onChange={(e) => {
                            setInvoiceData((prev) => ({
                                ...prev,
                                paymentType: e.target.value,
                            }));
                        }}
                    >
                        {getSortedPaymentTypes().map((type) => (
                            <MenuItem key={type.code} value={type.code}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>

                <Grid item xs={getResponsiveWidth(12, 6)}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                        <DateTimePicker
                            disabled={!isEditable}
                            label="Ngày giờ xuất hóa đơn"
                            onChange={(date) => {
                                setInvoiceData((prev) => ({
                                    ...prev,
                                    invoiceIssuedDate: date,
                                }));
                            }}
                            fullWidth
                            disableFuture={true}
                            defaultValue={invoiceData.invoiceIssuedDate ? invoiceData.invoiceIssuedDate : dayjs()}
                            value={invoiceData.invoiceIssuedDate}
                            slotProps={{field: {shouldRespectLeadingZeros: true}, textField: {fullWidth: true}}}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </>
    );
})
export default GeneralInvoiceInfo;
