export const CREATE_USER = 'CREATE_USER';
export const createUser = user => ({
    type: CREATE_USER,
    payload: {user},
});

export const UPDATE_USER = 'UPDATE_USER';
export const updateUser = user => ({
    type: UPDATE_USER,
    payload: {user},
});

export const GET_USER = 'GET_USER';
export const getUserDetail = user => ({
    type: GET_USER,
    payload: {user},
});

export const REMOVE_USER = 'REMOVE_USER';
export const removeUser = user => ({
    type: REMOVE_USER,
    payload: {user},
});

export const LOAD_USERS_IN_PROGRESS = 'LOAD_USERS_IN_PROGRESS';
export const loadUsersInProgress = () => ({
    type: LOAD_USERS_IN_PROGRESS,
});

export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const loadUsersSuccess = users => ({
    type: LOAD_USERS_SUCCESS,
    payload: {users},
});

export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';
export const loadUsersFailure = error => ({
    type: LOAD_USERS_FAILURE,
    payload: {error},
});


export const CALL_USER_REQUEST_SUCCESS = 'CALL_USER_REQUEST_SUCCESS';
export const requestUserSuccess = () => ({
    type: CALL_USER_REQUEST_SUCCESS,
});

export const CALL_USER_REQUEST_FAILURE = 'CALL_USER_REQUEST_FAILURE';
export const requestUserFailure = error => ({
    type: CALL_USER_REQUEST_FAILURE,
    payload: {error},
});
