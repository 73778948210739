import * as React from 'react';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {logout} from "./auth";
import {useAlert} from "../common/AlertProvider";
import Copyright from "../common/Copyright";
import LogoutIcon from '@mui/icons-material/Logout';
import {handleSuccessMessage} from "../constants";

const defaultTheme = createTheme();
export default function Logout() {
    const {openAlert} = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        async function processLogout() {
            try {
                await logout();
                handleSuccessMessage("Đăng xuất thành công!", openAlert);
            } catch (error) {
                console.log(error);
            }
        }

        processLogout();
    }, [navigate, openAlert]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LogoutIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Đăng xuất hệ thống
                    </Typography>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}
