import React, {useEffect, useState} from 'react';
import {
    calculateRedeemPawn,
    fetchPawnData,
    formattedPawnData,
    initDefaultForfeitedPawn,
    initDefaultRedeemPawn,
    mapPawnStatusToText,
    PAWN_ACTIONS,
    PAWNS_API_ENDPOINT
} from "./PawnConstants";
import {
    ADMINISTRATOR_USER_NAME,
    fetchSystemInfo,
    getCurrentUser,
    handleApiError,
    handleSuccessMessage
} from "../constants";
import DropDownButton from "../common/DropDownButton";
import {useAlert} from "../common/AlertProvider";
import RedeemPawn from "./RedeemPawn";
import UpdatePawn from "./UpdatePawn";
import ForfeitPawn from "./ForfeitPawn";
import RequestMoney from "./RequestMoney";
import ExtendPawn from "./ExtendPawn";
import CancelPawn from "./CancelPawn";
import CustomDialog from "../common/CustomDialog";
import {handlePrinting} from "./PawnStamp";
import ViewPawn from "./ViewPawn";
import YesNoAlertDialog from "../common/YesNoAlertDialog";
import axios from "../axiosInstance";
import {usePermissions} from "../route/PermissionProvider";
import {DEFAULT_CUSTOMER_DATA, getCustomerDetailsById} from "../customer/CustomerConstants";
import UpdateCustomer from "../customer/UpdateCustomer";

const ReviewPawn = ({open, onClose, sourcePawn}) => {
    const [loading, setLoading] = useState(false);
    const {openAlert} = useAlert();
    const [pawnData, setPawnData] = useState(sourcePawn);
    const [selectedAction, setSelectedAction] = React.useState('REDEEM');
    const [openRedeemDialog, setOpenRedeemDialog] = useState(false);
    const [openUpdatePawnDialog, setOpenUpdatePawnDialog] = useState(false);
    const [openDeletePawnsDialog, setOpenDeletePawnsDialog] = useState(false);
    const [openCancelPawnDialog, setOpenCancelPawnDialog] = useState(false);
    const [openForfeitDialog, setOpenForfeitDialog] = useState(false);
    const [openRequestMoneyDialog, setOpenRequestMoneyDialog] = useState(false);
    const [openExtendPawnDialog, setOpenExtendPawnDialog] = useState(false);
    const [redeemPawnData, setRedeemPawnData] = useState(initDefaultRedeemPawn());
    const [forfeitPawnData, setForfeitPawnData] = useState(initDefaultForfeitedPawn());
    const [expanded, setExpanded] = React.useState('panel100');
    const permissions = usePermissions();
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [customerData, setCustomerData] = useState(DEFAULT_CUSTOMER_DATA);
    const [statusUpdateDialog, setStatusUpdateDialog] = useState(false);
    const [statusUpdateContent, setStatusUpdateContent] = useState('');
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const getAvailableOptions = (pawnStatus) => {
        const actions = PAWN_ACTIONS.filter(action =>
                action.actStatus.includes(pawnStatus)
                && (
                    (permissions.PAWN?.includes(action.permission) &&
                        (!action.administration || (action.administration && ADMINISTRATOR_USER_NAME === getCurrentUser())))
                    || permissions.CUSTOMER?.includes(action.customerPermission)
                )
        );
        return actions.filter(action => action.code !== "VIEW");
    }
    useEffect(() => {
        setPawnData(sourcePawn);
    }, [open, sourcePawn]);

    const handleClose = async () => {
        onClose(pawnData);
    };
    const getCustomerDetails = async (pawn) => {
        const customerId = pawn.customer?.customerId || 0;
        try {
            if (customerId !== 0) {
                const response = await getCustomerDetailsById(customerId);
                setCustomerData(response);
                setOpenCustomerDialog(true);
            }
        } catch (error) {
            console.error("Error while fetching data: ", error);
            handleApiError(error, openAlert)
        }
    }
    const statusUpdateClick = async (pawnId) => {
        const item = await fetchPawnData(pawnId);
        if (item.visible) {
            setPawnData(prev => ({
                ...prev,
                visible: false,
            }));
            setStatusUpdateContent(`Đơn cầm [${item.pawnId}] đang ở trạng thái [hiển thị], Bạn muốn ẩn nó?`);
        } else {
            setPawnData(prev => ({
                ...prev,
                visible: true,
            }));
            setStatusUpdateContent(`Đơn cầm [${item.pawnId}] đang ở trạng thái [ẩn], Bạn muốn hiển thị nó?`)
        }
        onClose();
        setStatusUpdateDialog(true);
    }
    const handleActionClick = async (action) => {
        try {
            setLoading(true);
            const pawnId = pawnData.pawnId;
            await fetchSystemInfo(openAlert);
            switch (action) {
                case 'REDEEM':
                case 'FORFEIT':
                case 'EXTEND': {
                    const extendingPawn = (action === 'EXTEND');
                    const redeemData = await calculateRedeemPawn(pawnId, new Date(), extendingPawn);
                    switch (action) {
                        case 'REDEEM':
                            setRedeemPawnData(formattedPawnData(redeemData));
                            setOpenRedeemDialog(true);
                            break;
                        case 'FORFEIT':
                            setForfeitPawnData(formattedPawnData(redeemData));
                            setOpenForfeitDialog(true);
                            break;
                        default:
                            setRedeemPawnData(formattedPawnData(redeemData));
                            setOpenExtendPawnDialog(true);
                            break;
                    }
                    break;
                }
                case 'UPDATED':
                case 'RECEIPT':
                case 'REQ_MONEY': {
                    const fetchData = async () => {
                        const pawn = await fetchPawnData(pawnId);
                        setPawnData(formattedPawnData(pawn));
                    }
                    await fetchData();
                    switch (action) {
                        case 'UPDATED':
                            setOpenUpdatePawnDialog(true);
                            break;
                        case "RECEIPT": {
                            await handlePrinting({...pawnData});
                            break;
                        }
                        default:
                            setOpenRequestMoneyDialog(true);
                            break;
                    }
                    break;
                }
                case 'DELETE':
                case 'CANCEL': {
                    switch (action) {
                        case 'DELETE':
                            onClose();
                            setOpenDeletePawnsDialog(true);
                            break
                        default:
                            setOpenCancelPawnDialog(true);
                            break;
                    }
                    break;
                }
                case 'CUSTOMER': {
                    const pawn = await fetchPawnData(pawnId);
                    await getCustomerDetails(pawn);
                    break;
                }
                case 'UPDATED_STATUS': {
                    return await statusUpdateClick(pawnId);
                }
                default:
                    console.log('Không xác định');
                    break;
            }
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setLoading(false);
        }
    };
    const handleMenuItemClick = (event, selectedAction) => {
        setSelectedAction(selectedAction);
    };

    const formActions = (
        <>
            <DropDownButton
                fullWidth={true}
                disabled={false}
                options={getAvailableOptions(sourcePawn.pawnStatus)}
                action={selectedAction}
                handleActionClick={handleActionClick}
                handleMenuItemClick={handleMenuItemClick}
                isLoading={loading}
            />
        </>
    );
    const processDeletePawns = async () => {
        try {
            const selectedPawnIds = [pawnData.pawnId];
            await axios.delete(PAWNS_API_ENDPOINT, {
                data: selectedPawnIds,
            });
            handleSuccessMessage('Đơn cầm [' + selectedPawnIds + '] được xóa thành công', openAlert);
            await onClose();
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setOpenDeletePawnsDialog(false);
        }
    };
    const processUpdateStatus = async () => {
        try {
            await axios.patch(`${PAWNS_API_ENDPOINT}/${pawnData.pawnId}/visible`, pawnData);
            handleSuccessMessage(`Thay đổi thành công đơn cầm [${pawnData.pawnId}]`, openAlert);
            await onClose();
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setStatusUpdateDialog(false);
        }
    };
    const pageTitle = (
        <>
            {`Đơn cầm số: ${pawnData.pawnId}`}
            <br/>Tình trạng: {mapPawnStatusToText(pawnData.pawnStatus)}
        </>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <ViewPawn sourcePawn={pawnData} defExpanded={expanded} onChange={handleChangeAccordion(expanded)}/>
            </CustomDialog>
            <RedeemPawn open={openRedeemDialog} onClose={
                async () => {
                    setOpenRedeemDialog(false);
                    await handleClose();
                }} sourcePawn={redeemPawnData}/>
            <UpdatePawn open={openUpdatePawnDialog} onClose={async () => {
                setOpenUpdatePawnDialog(false);
                await handleClose();
            }} sourcePawn={pawnData}/>
            <ForfeitPawn open={openForfeitDialog} onClose={async () => {
                setOpenForfeitDialog(false);
                await handleClose();
            }} sourcePawn={forfeitPawnData}/>
            <RequestMoney open={openRequestMoneyDialog} onClose={async () => {
                setOpenRequestMoneyDialog(false);
                await handleClose();
            }} sourcePawn={pawnData}/>
            <ExtendPawn open={openExtendPawnDialog} onClose={async () => {
                setOpenExtendPawnDialog(false);
                await handleClose();
            }} sourcePawn={redeemPawnData}/>
            <YesNoAlertDialog open={openDeletePawnsDialog} onClose={() => {
                setOpenDeletePawnsDialog(false);
            }}
                              content={`Xóa đơn cầm: [${pawnData.pawnId}]?`}
                              onAccept={processDeletePawns}/>
            <CancelPawn
                open={openCancelPawnDialog}
                onClose={async () => {
                    setOpenCancelPawnDialog(false);
                    await handleClose();
                }}
                selectedPawnId={pawnData.pawnId}
            />
            <UpdateCustomer open={openCustomerDialog} onClose={async () => {
                setOpenCustomerDialog(false);
            }} sourceCustomer={customerData}/>
            <YesNoAlertDialog open={statusUpdateDialog} onClose={() => {
                setStatusUpdateDialog(false);
            }}
                              content={statusUpdateContent}
                              onAccept={processUpdateStatus}/>
        </div>
    );
};

export default ReviewPawn;
