import React from "react";
import {Box, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Slide} from "@mui/material";
import CustomLoadingButton from "./CustomLoadingButton";

const titleDefault = 'Thông báo!';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const YesNoAlertDialog = ({open = false, onClose, onAccept, title = titleDefault, content = ''}) => {
    const handleClose = async () => {
        await onClose();
    };
    const handleAcceptClick = async () => {
        await onAccept();
    };
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent sx={{minWidth: 300}}>
                    <DialogContentText id="alert-dialog-slide-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <Box p={2}>
                    <Grid spacing={2} container>
                        <Grid item xs={6}>
                            <CustomLoadingButton
                                fullWidth
                                color="primary"
                                variant="contained"
                                buttonLabel={"Huỷ"} onClick={handleClose}/>
                        </Grid>
                        <Grid item xs={6}>
                            <CustomLoadingButton
                                fullWidth
                                color="primary"
                                variant="contained"
                                buttonLabel={"Tiếp tục"} onClick={handleAcceptClick}/>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};
export default YesNoAlertDialog;
