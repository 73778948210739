import axios from "../axiosInstance";
import {API_ENDPOINT, convertDayjsDate, formatCustomerName} from "../constants";
import {
    createInvoice,
    deleteInvoice,
    loadInvoiceInProgress,
    loadInvoicesFailure,
    loadInvoicesSuccess,
    requestInvoiceSuccess,
    updateInvoice
} from "./invoiceActions";
import {fetchOrderDetails} from "../order/OrderConstant";
import {mapStatusToText} from "./InvoiceConstant";

const formatInvoiceFromResponse = (invoice) => {
    return {
        ...invoice,
        id: invoice.invoiceId,
        invoiceIssuedDate: invoice.invoiceIssuedDate,
        createdAt: invoice.createdAt,
        updatedAt: invoice.updatedAt,
        buyerName: invoice.buyerInfo ? invoice.buyerInfo.buyerName : '',
        buyerTaxCode: invoice.buyerInfo ? invoice.buyerInfo.buyerTaxCode : '',
        buyerLegalName: invoice.buyerInfo ? invoice.buyerInfo.buyerLegalName : '',
        buyerPhoneNumber: invoice.buyerInfo ? invoice.buyerInfo.buyerPhoneNumber : '',
        buyerEmail: invoice.buyerInfo ? invoice.buyerInfo.buyerEmail : '',
        invoiceStatus: mapStatusToText(invoice.status),
        status: invoice.status,
    };
}
export const loadInvoices = (pagination = {
    page: 0,
    pageSize: 100,
}, requestBody = {}) => async (dispatch, getState) => {
    try {
        dispatch(loadInvoiceInProgress());
        const response = await axios.post(API_ENDPOINT + `/invoices/find?page=${pagination.page}&size=${pagination.pageSize}&sort=invoiceId,desc`, requestBody);
        const invoices = await response.data.content.map((invoice) => (formatInvoiceFromResponse(invoice)));
        dispatch(loadInvoicesSuccess(invoices));
    } catch (e) {
        const error = {severity: 'error', message: 'Error while loading data!!!'};
        dispatch(loadInvoicesFailure(error));
    }
}

export const getInvoiceInfo = (invoiceId) => async (dispatch, getState) => {
    try {
        console.log('Invoice ID', invoiceId)
        dispatch(loadInvoiceInProgress());
        const response = await axios.get(API_ENDPOINT + `/invoices/${invoiceId}`);
        const invoice = {
            ...response.data,
            id: response.data.invoiceId,
        };
        dispatch(requestInvoiceSuccess());
        return invoice;
    } catch (e) {
        const error = {severity: 'error', message: 'Error while loading data!!!'};
        dispatch(loadInvoicesFailure(error));
        throw e;
    }
}

export const getOrderInfo = (orderId) => async () => {
    try {
        const order = await fetchOrderDetails(orderId);
        return {
            ...order,
            orderDate: convertDayjsDate(order.orderDate),
            customerName: formatCustomerName(order.customer),
            sellItems: order.sellItems?.map((item) => ({
                ...item,
                id: item.itemId
            })),
            buyItems: order.buyItems?.map((item) => ({
                ...item,
                id: item.itemId
            }))
        };

    } catch (e) {
        const error = {severity: 'error', message: 'Error while loading data!!!'};
        console.log("Error", error);
        throw e;
    }
}

export const createInvoiceRequest = invoice => async dispatch => {
    try {
        dispatch(loadInvoiceInProgress());
        const response = await axios.post(API_ENDPOINT + `/invoices`, invoice);
        dispatch(createInvoice(formatInvoiceFromResponse(response.data)));
    } catch (e) {
        console.log("Error while creating invoice", e);
        dispatch(loadInvoicesFailure(e));
        throw e;
    }
}

export const downloadInvoiceFile = (invoiceId) => async (dispatch, getState) => {
    try {
        dispatch(loadInvoiceInProgress());
        const response = await axios.get(API_ENDPOINT + `/invoices/${invoiceId}/downloadInvoicePdf`, {
            responseType: 'blob',
        });
        dispatch(requestInvoiceSuccess());
        return response;
    } catch (e) {
        const error = {severity: 'error', message: 'Error while loading data!!!'};
        dispatch(loadInvoicesFailure(error));
        throw e;
    }
}

export const updateInvoiceRequest = (invoiceId, invoice) => async dispatch => {
    try {
        dispatch(loadInvoiceInProgress());
        const response = await axios.put(API_ENDPOINT + `/invoices/${invoiceId}`, invoice);
        dispatch(updateInvoice(formatInvoiceFromResponse(response.data)));
    } catch (e) {
        dispatch(loadInvoicesFailure(e));
        throw e;
    }
}
export const deleteInvoiceRequest = (invoiceId) => async dispatch => {
    try {
        dispatch(loadInvoiceInProgress());
        await axios.delete(API_ENDPOINT + `/invoices/${invoiceId}`);
        dispatch(deleteInvoice(invoiceId));
    } catch (e) {
        dispatch(loadInvoicesFailure(e));
        throw e;
    }
}

export const sendEmailInvoiceRequest = (invoiceId) => async dispatch => {
    try {
        dispatch(loadInvoiceInProgress());
        await axios.post(API_ENDPOINT + `/invoices/${invoiceId}/send-email`);
    } catch (e) {
        dispatch(loadInvoicesFailure(e));
        throw e;
    }
}

export const updateAndCompleteInvoiceRequest = (invoiceId, invoice) => async dispatch => {
    try {
        dispatch(loadInvoiceInProgress());
        const response = await axios.post(API_ENDPOINT + `/invoices/${invoiceId}/update-complete`, invoice);
        dispatch(updateInvoice(formatInvoiceFromResponse(response.data)));
    } catch (e) {
        dispatch(loadInvoicesFailure(e));
        throw e;
    }
}
