import React, {useState} from "react";
import {vi_locale} from 'dayjs/locale/vi';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Grid,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import axios from '../axiosInstance';
import {
    calculateAge,
    CUSTOMERS_API_ENDPOINT,
    DEFAULT_CUSTOMER_DATA,
    geDefaultDateOfBirth,
    Gender
} from "./CustomerConstants";
import {
    convertDayjsDate,
    DATE_SHORT_FORMAT,
    getResponsiveWidth,
    handleApiError,
    handleSuccessMessage,
    setUserTime
} from "../constants";

import {useAlert} from "../common/AlertProvider";
import CustomLoadingButton from "../common/CustomLoadingButton";
import CustomDialog from "../common/CustomDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NationalCard from "./NationalCard";

const DEFAULT_PANEL = 'panel0';
const CreateCustomer = ({open, onClose}) => {
    const {openAlert} = useAlert();
    const [customerData, setCustomerData] = useState(DEFAULT_CUSTOMER_DATA);
    const [isLastNameValid, setLastNameValid] = useState(true);
    const [customerSuggestions, setCustomerSuggestions] = useState([]);
    const [expanded, setExpanded] = React.useState(DEFAULT_PANEL);
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const openDatePicker = () => {
        setCustomerData((prev) => ({
            ...prev,
            dateOfBirth: convertDayjsDate(geDefaultDateOfBirth()),
        }));
    }
    const fetchCustomerSuggestions = async (inputValue) => {
        try {
            const response = await axios.get(CUSTOMERS_API_ENDPOINT + "/search", {
                params: {query: inputValue},
            });
            return response.data;
        } catch (error) {
            return [];
        }
    };
    const handleCustomerInputChange = async (event, newInputValue) => {
        setCustomerData((prev) => ({
            ...prev,
            lastName: newInputValue,
        }));
        if (newInputValue && newInputValue.trim() !== '') {
            const suggestions = await fetchCustomerSuggestions(newInputValue);
            setCustomerSuggestions(suggestions);
        }
        setLastNameValid(true);
    };

    const onSave = async (customerData) => {
        try {
            const response = await axios.post(CUSTOMERS_API_ENDPOINT, customerData);
            handleSuccessMessage('Khách hàng [' + response.data.lastName + '] được tạo. mã khách hàng: ' + response.data.customerId, openAlert);
            onClose();
            setExpanded(DEFAULT_PANEL);
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };

    const handleCustomerSelect = (event, selectedCustomer) => {
        if (selectedCustomer) {
            setCustomerData((prevData) => ({
                ...prevData,
                lastName: selectedCustomer.lastName,
                phone: selectedCustomer.phone,
            }));
        }

    };
    const handleSave = async () => {
        let validated = true;
        if (customerData.lastName.trim() === "") {
            setLastNameValid(false);
            validated = false;
        } else {
            setLastNameValid(true);
        }

        if (validated) {
            await onSave(customerData);
            setCustomerData(DEFAULT_CUSTOMER_DATA);
            setLastNameValid(true);
        }
    };
    const handleClose = async () => {
        setExpanded(DEFAULT_PANEL);
        onClose();
    };
    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleSave}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Tạo khách hàng"/>
        </>
    );
    const pageTitle = (
        <>
            {`Tạo khách hàng mới`}
        </>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Accordion expanded={expanded === 'panel0'} onChange={handleChangeAccordion('panel0')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        Thông tin chung
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={getResponsiveWidth()}>
                                <Autocomplete
                                    freeSolo
                                    options={customerSuggestions.map((option) => ({
                                        label: option.lastName + (option.phone ? (" - " + option.phone) : ''),
                                        lastName: option.lastName,
                                        phone: option.phone,
                                        value: option.customerId,
                                    }))}
                                    getOptionLabel={(option) => (option ? option.label : '')}
                                    onInputChange={handleCustomerInputChange}
                                    onChange={handleCustomerSelect}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            value={customerData.lastName}
                                            label="Tên khách hàng"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                            error={!isLastNameValid}
                                        />
                                    )}
                                />
                            < /Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <TextField
                                    label="Số điện thoại"
                                    value={customerData.phone}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            phone: numericInput,
                                        }));
                                    }}
                                    inputProps={{maxLength: 11}}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <Select
                                    value={customerData.gender}
                                    fullWidth
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            gender: e.target.value,
                                        }))
                                    }
                                >
                                    {Object.values(Gender).map((gender) => (
                                        <MenuItem key={gender.code} value={gender.code}>
                                            {gender.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                                    <DatePicker label="Ngày sinh"
                                                value={customerData.dateOfBirth ? customerData.dateOfBirth : null}
                                                onChange={(date) => {
                                                    const selectedDate = setUserTime(date);
                                                    setCustomerData((prev) => ({
                                                        ...prev,
                                                        dateOfBirth: selectedDate,
                                                        age: calculateAge(selectedDate),
                                                    }));
                                                }}
                                                fullWidth
                                                format={DATE_SHORT_FORMAT}
                                                slotProps={{
                                                    field: {shouldRespectLeadingZeros: true, clearable: true},
                                                    textField: {fullWidth: true}
                                                }}
                                                disableFuture={true}
                                                onOpen={openDatePicker}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    label="Email"
                                    value={customerData.email}
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            email: e.target.value,
                                        }))
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    label="Sở thích"
                                    value={customerData.habit}
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            habit: e.target.value,
                                        }))
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    label="Ghi chú"
                                    value={customerData.description}
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            description: e.target.value,
                                        }))
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    label="Địa chỉ"
                                    value={customerData.address}
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            address: e.target.value,
                                        }))
                                    }
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        Thông tin CCCD
                    </AccordionSummary>
                    <AccordionDetails>
                        <NationalCard customerData={customerData} setCustomerData={setCustomerData}/>
                    </AccordionDetails>
                </Accordion>
            </CustomDialog>
        </div>
    )
};

export default CreateCustomer;



