import {API_ENDPOINT, convertDayjsDate, DATE_LONG_FORMAT, DATE_SHORT_FORMAT} from "../constants";
import axios from "../axiosInstance";
import {fetchCustomerPawns} from "../pawn/PawnConstants";
import {fetchCustomerOrders} from "../order/OrderConstant";

export const CUSTOMERS_API_ENDPOINT = API_ENDPOINT + '/customers';

export const DEFAULT_QUICK_CUSTOMER_DATA = {
    lastName: "",
    phone: "",
    gender: "UNKNOWN",
}
export const CustomerStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    DELETED: 'DELETED'
};

export const Gender = {
    MALE: {label: 'Nam', code: 'MALE'},
    FEMALE: {label: 'Nữ', code: 'FEMALE'},
    UNKNOWN: {label: 'Giới tính', code: 'UNKNOWN'}
};
export const mapGenderToText = (gender) => {
    switch (gender) {
        case Gender.MALE.code:
            return 'Nam';
        case Gender.FEMALE.code:
            return 'Nữ';
        case Gender.UNKNOWN.code:
            return 'Không xác định';
        default:
            return 'Không xác định';
    }
};
export const mapCustomerStatusToText = (customerStatus) => {
    switch (customerStatus) {
        case CustomerStatus.ACTIVE:
            return 'Đang hoạt động';
        case CustomerStatus.INACTIVE:
            return 'Chưa kích hoạt';
        case CustomerStatus.DELETED:
            return 'Đã xóa';
        default:
            return 'Không xác định';
    }
};
export const CUSTOMER_COLUMNS = [
    {field: 'customerId', headerName: 'Mã KH', width: 120},
    {field: 'lastName', headerName: 'Tên KH', width: 170},
    {
        field: 'phone', headerName: 'Số đt', width: 150,
        valueFormatter: (params) => formatPhoneNumber(params.value)
    },
    {
        field: 'gender', headerName: 'Giới tính', width: 150,
        valueFormatter: (params) => mapGenderToText(params.value)
    },
    {field: 'visitingGuest', headerName: 'Khách vãng lai?', width: 170},
    {field: 'age', headerName: 'Tuổi', width: 120},
    {
        field: 'dateOfBirth', headerName: 'Ngày sinh', width: 150,
        valueFormatter: (params) => params.value ? convertDayjsDate(params.value).format(DATE_SHORT_FORMAT) : ''
    },
    {field: 'email', headerName: 'Email', width: 150},
    {field: 'address', headerName: 'Địa chỉ', width: 150},
    {field: 'description', headerName: 'Mô tả', width: 200},
    {field: 'habit', headerName: 'Sở thích', width: 200},
    {
        field: 'status', headerName: 'Trạng thái', width: 140,
        valueFormatter: (params) => mapCustomerStatusToText(params.value)
    },
    {field: 'createdBy', headerName: 'Người tạo', width: 150},
    {
        field: 'createdAt', headerName: 'Ngày tạo', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
    {field: 'updatedBy', headerName: 'Người cập nhật', width: 150},
    {
        field: 'updatedAt', headerName: 'Ngày cập nhật', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
];

export function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';
    // Remove non-numeric characters from the input
    const numericOnly = phoneNumber.replace(/\D/g, '');
    // Use regular expressions to apply the desired formatting
    return numericOnly.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
}

export function calculateAge(dateOfBirth) {
    if (!dateOfBirth) return 0;
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - dob.getFullYear();
    if (
        currentDate.getMonth() < dob.getMonth() ||
        (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())
    ) {
        return age - 1;
    }
    return age;
}

export function geDefaultDateOfBirth() {
    // Get the current date
    const currentDate = new Date();
    // Subtract 20 years
    const twentyYearsAgo = new Date(currentDate);
    twentyYearsAgo.setFullYear(currentDate.getFullYear() - 20);
    return twentyYearsAgo;
}

export const DEFAULT_CUSTOMER_DATA = {
    customerId: 0,
    lastName: "",
    firstName: "",
    phone: "",
    age: 0,
    dateOfBirth: '',
    description: '',
    habit: '',
    address: '',
    email: '',
    status: CustomerStatus.ACTIVE,
    gender: Gender.UNKNOWN.code,
    visitingGuest: false,
    nationalNumber: '',
    nationalName: '',
    homeTown: '',
    residenceAddress: '',
    issuedDate: '',
    issuedBy: '',
};
export const getCustomerSummaryById = async (customerId) => {
    try {
        const response = await axios.get(
            `${CUSTOMERS_API_ENDPOINT}/${customerId}`
        );
        return {
            ...response.data,
            dateOfBirth: response.data.dateOfBirth ? convertDayjsDate(response.data.dateOfBirth) : '',
            issuedDate: response.data.issuedDate ? convertDayjsDate(response.data.issuedDate) : '',
            age: response.data.dateOfBirth ? calculateAge(response.data.dateOfBirth) : ''
        };
    } catch (error) {
        console.error("Error while fetching data: ", error);
        throw error;
    }
}
export const getCustomerDetailsById = async (customerId) => {
    try {
        const response = await getCustomerSummaryById(customerId);
        const pawns = await fetchCustomerPawns(customerId, 0, 50);
        const orders = await fetchCustomerOrders(customerId, 0, 50);
        return {
            ...response,
            pawns: pawns,
            orders: orders,
        };
    } catch (error) {
        console.error("Error while fetching data: ", error);
        throw error;
    }
}
