import {
    CALL_USER_REQUEST_FAILURE,
    CALL_USER_REQUEST_SUCCESS,
    CREATE_USER,
    LOAD_USERS_FAILURE,
    LOAD_USERS_IN_PROGRESS,
    LOAD_USERS_SUCCESS,
    REMOVE_USER
} from "./userActions";

export const isLoading = (state = false, action) => {
    const {type} = action;
    switch (type) {
        case LOAD_USERS_IN_PROGRESS:
            return true;
        case LOAD_USERS_SUCCESS:
        case LOAD_USERS_FAILURE:
        case CALL_USER_REQUEST_SUCCESS:
        case CALL_USER_REQUEST_FAILURE:
            return false;
        default:
            return state;
    }
}

export const users = (state = [], action) => {
    const {type, payload} = action;
    switch (type) {
        case CREATE_USER: {
            const {user} = payload;
            return state.concat(user);
        }
        case REMOVE_USER: {
            const {user: userToRemove} = payload;
            return state.filter(user => user.id !== userToRemove.id);
        }
        case LOAD_USERS_SUCCESS: {
            const {users} = payload;
            return users;
        }
        case LOAD_USERS_IN_PROGRESS:
        case LOAD_USERS_FAILURE:
        default:
            return state;
    }
}
export const error = (state = {}, action) => {
    const {type} = action;
    switch (type) {
        case LOAD_USERS_IN_PROGRESS:
        case LOAD_USERS_SUCCESS:
            return {};
        case LOAD_USERS_FAILURE:
            return state;
        default:
            return {};
    }
}

