import {
    CALL_REQUEST_SUCCESS,
    CREATE_INVOICE, DELETE_INVOICE,
    LOAD_INVOICES_FAILURE,
    LOAD_INVOICES_IN_PROGRESS,
    LOAD_INVOICES_SUCCESS, UPDATE_INVOICE
} from "./invoiceActions";

export const isInvoicesLoading = (state = false, action) => {
    const {type} = action;
    switch (type) {
        case LOAD_INVOICES_IN_PROGRESS:
            return true;
        case CALL_REQUEST_SUCCESS:
        case LOAD_INVOICES_SUCCESS:
        case LOAD_INVOICES_FAILURE:
            return false;
        default:
            return state;
    }
}

export const invoices = (state = [], action) => {
    const {type, payload} = action;
    switch (type) {
        case CREATE_INVOICE: {
            const {invoice} = payload;
            return [invoice, ...state];
        }
        case UPDATE_INVOICE: {
            const { invoice } = payload;
            return state.map(inv => inv.invoiceId === invoice.invoiceId ? invoice : inv);
        }
        case LOAD_INVOICES_SUCCESS: {
            const {invoices} = payload;
            return invoices;
        }
        case DELETE_INVOICE: {
            const { invoiceId } = payload;
            return state.filter(inv => inv.invoiceId !== invoiceId);
        }
        case CALL_REQUEST_SUCCESS:
        case LOAD_INVOICES_IN_PROGRESS:
        case LOAD_INVOICES_FAILURE:
        default:
            return state;
    }
}
export const errorInvoices = (state = {}, action) => {
    const {type} = action;
    switch (type) {
        case LOAD_INVOICES_IN_PROGRESS:
        case LOAD_INVOICES_SUCCESS:
            return {};
        case LOAD_INVOICES_FAILURE:
            return state;
        default:
            return {};
    }
}

