import React, {useEffect, useState} from "react";
import {vi_locale} from 'dayjs/locale/vi';
import {
    Autocomplete,
    Box,
    CircularProgress,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@mui/material";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import axios from '../axiosInstance';
import {
    calculatePawnDueDate,
    CUSTOMERS_API_ENDPOINT,
    initDefaultPawn,
    InterestTypes,
    PAWN_SUMMARY_COLUMNS,
    PAWNS_API_ENDPOINT,
    suggestPawnItemName,
    suggestPawnValue
} from "./PawnConstants";
import QuickCreateCustomer from "../customer/QuickCreateCustomer";
import {
    calculateGoldWeight,
    convertDayjsDate,
    DATE_SHORT_FORMAT,
    formatCustomerName,
    formatCustomerNameWithId,
    getGoldPrice,
    getResponsiveWidth,
    getSortedGoldBrand,
    getSortedGoldType,
    handleApiError,
    handleSuccessMessage,
    setCommonPawnProducts,
    setUserTime
} from "../constants";
import {handlePrinting} from "./PawnStamp";
import {useAlert} from "../common/AlertProvider";
import CustomDialog from "../common/CustomDialog";
import CustomLoadingButton from "../common/CustomLoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import GridSummary from "../common/GridSummary";

const CreatePawn = ({open, onClose}) => {
    const {openAlert} = useAlert();
    const [pawnData, setPawnData] = useState({});
    const [isItemNameValid, setItemNameValid] = useState(true);
    const [isCustomerIdValid, setCustomerIdValid] = useState(true);
    const [isItemWeightValid, setItemWeightValid] = useState(true);
    const [isPawnAmountValid, setPawnAmountValid] = useState(true);
    const [customerSuggestions, setCustomerSuggestions] = useState([]);
    const [isQuickCreateCustomerOpen, setQuickCreateCustomerOpen] = useState(false);
    const [loadingCustomer, setLoadingCustomer] = useState(false);

    useEffect(() => {
        setPawnData(initDefaultPawn());
    }, [open]);
    const fetchCustomerSuggestions = async (inputValue, visitingGuest = false) => {
        try {
            setLoadingCustomer(true);
            const response = await axios.get(CUSTOMERS_API_ENDPOINT + "/search", {
                params: {query: inputValue, guest: visitingGuest},
            });
            return response.data.map((customer) => ({
                ...customer,
                id: customer.customerId,
                pawns: customer.pawns ? customer.pawns.map((pawn) => ({
                    ...pawn,
                    id: pawn.pawnId
                })) : [],
            }));
        } catch (error) {
            console.error('Error while fetching customer suggestions:', error);
            return [];
        } finally {
            setLoadingCustomer(false);
        }
    };
    const handleCustomerInputChange = async (event, newInputValue) => {
        setPawnData((prev) => ({
            ...prev,
            customerName: newInputValue,
        }));
        if (!pawnData.visitingGuest && newInputValue && newInputValue.trim() !== '') {
            const suggestions = await fetchCustomerSuggestions(newInputValue, pawnData.visitingGuest);
            setCustomerSuggestions(suggestions);
        }
    };
    const onSavePawnData = async (pawnData) => {
        try {
            const response = await axios.post(PAWNS_API_ENDPOINT, pawnData);
            handleSuccessMessage('Đơn cầm số [' + response.data.pawnId + '] được tạo thành công', openAlert);
            onClose();
            return response.data;
        } catch (error) {
            handleApiError(error, openAlert);
            throw error;
        } finally {
            setCommonPawnProducts(pawnData.itemName);
        }
    };

    const handleCustomerSelect = (event, selectedValue) => {
        const selectedCustomerId = selectedValue ? selectedValue.value : null;
        setCustomerIdValid(!!selectedCustomerId);
        setPawnData((prevData) => ({
            ...prevData,
            customerId: selectedCustomerId,
        }));
    };
    const handleSaveButton = async () => {
        let validated = true;
        if (pawnData.itemWeight <= 0) {
            setItemWeightValid(false);
            validated = false;
        } else {
            setItemWeightValid(true);
        }
        if (pawnData.pawnAmount === 0) {
            setPawnAmountValid(false);
            validated = false;
        } else {
            setPawnAmountValid(true);
        }
        if (pawnData.visitingGuest === false) {
            if (pawnData.customerId === 0) {
                setCustomerIdValid(false);
                validated = false;
            } else {
                setCustomerIdValid(true);
            }

            if (pawnData.customerId === 0 && pawnData.customerName.trim() !== "") {
                setQuickCreateCustomerOpen(true);
            }
        } else if (pawnData.customerName === '') {
            setCustomerIdValid(false);
            validated = false;
        }
        if (pawnData.itemName.trim() === "") {
            setItemNameValid(false);
            validated = false;
        } else {
            setItemNameValid(true);
        }

        if (validated) {
            const savedPawn = await onSavePawnData(pawnData);
            setPawnData(initDefaultPawn());
            setCustomerIdValid(true);
            setItemNameValid(true);
            setItemWeightValid(true);
            setPawnAmountValid(true);
            return savedPawn;
        }
    };
    const handleSavePrintButton = async () => {
        const savedPawn = await handleSaveButton();
        if (savedPawn) {
            const pawn = {
                ...savedPawn,
                pawnDate: convertDayjsDate(savedPawn.pawnDate),
                pawnDueDate: convertDayjsDate(savedPawn.pawnDueDate),
                customerName: formatCustomerName(savedPawn.customer),
            };
            await handlePrinting(pawn);
        }
    }
    const handleQuickCreateCustomerSuccess = (createdCustomer) => {
        setPawnData((prevData) => ({
            ...prevData,
            customerId: createdCustomer.customerId,
        }));

        setCustomerIdValid(true);
        setQuickCreateCustomerOpen(false);
    };

    const calculateItemValue = (itemWeight, itemType) => {
        const selectedGoldType = Object.values(getGoldPrice()).find(
            (price) => price.code === itemType
        );
        if (selectedGoldType && itemWeight !== 0) {
            setPawnData((prev) => ({
                ...prev,
                itemValue: selectedGoldType?.pawn * prev?.itemWeight,
            }));
        }
    };
    const weightChanges = (totalWeight = 0, gemWeight = 0) => {
        const goldWeight = calculateGoldWeight(totalWeight, gemWeight);
        setPawnData((prev) => ({
            ...prev,
            itemWeight: goldWeight,
        }));
        calculateItemValue(goldWeight, pawnData.itemType);
    };
    const handleProductSelect = (event, selectedValue) => {
        setPawnData((prevData) => ({
            ...prevData,
            itemName: selectedValue,
        }));
    };
    const handlePawnAmountSelect = (event, selectedValue) => {
        setPawnData((prev) => ({
            ...prev,
            pawnAmount: selectedValue ? selectedValue.value : 0,
        }));
    };
    const handleClose = async () => {
        onClose();
    };
    const formActions = (
        <>
            <CustomLoadingButton sx={{minWidth: 170, marginBottom: 1}}
                                 fullWidth
                                 variant="contained"
                                 buttonLabel={"Lưu và in"} onClick={handleSavePrintButton}/>
            <CustomLoadingButton sx={{minWidth: 170, marginBottom: 1}}
                                 fullWidth
                                 variant="contained"
                                 buttonLabel={"Chỉ Lưu"} onClick={handleSaveButton}/>
        </>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title="Tạo đơn cầm đồ" dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 3)} justifyContent="center">
                        <FormControlLabel control={
                            <Switch
                                checked={pawnData.visitingGuest}
                                onChange={(e) => {
                                    setPawnData((prev) => ({
                                        ...prev,
                                        visitingGuest: e.target.checked,
                                    }));
                                    handleCustomerInputChange(e, pawnData.customerName);
                                }}/>
                        }
                                          label="Khách vãng lai?"/>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 9)}>
                        <Autocomplete
                            freeSolo
                            options={customerSuggestions.map((option) => ({
                                label: formatCustomerNameWithId(option),
                                value: option.customerId,
                                id: option.customerId,
                                pawns: option.pawns,
                            }))}
                            getOptionLabel={(option) => (option ? option.label : '')}
                            renderOption={(props, option) => {
                                const {key, ...optionProps} = props;
                                return (
                                    <Box
                                        key={key}
                                        {...optionProps}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {option.label}
                                            </Grid>
                                            {option.pawns.length > 0 && (
                                                <Grid item xs={12}>
                                                    <GridSummary rowData={option.pawns}
                                                                 columnData={PAWN_SUMMARY_COLUMNS}/>
                                                </Grid>)}
                                        </Grid>
                                    </Box>
                                );
                            }}
                            onChange={handleCustomerSelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e) => {
                                        handleCustomerInputChange(e, e.target.value);
                                    }}
                                    error={!isCustomerIdValid}
                                    label="Tên khách hàng"
                                    autoFocus
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingCustomer ? <CircularProgress color="inherit" size={20}/> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    < /Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <Autocomplete
                            freeSolo
                            onChange={handleProductSelect}
                            options={suggestPawnItemName().map((option) => option)}
                            renderInput={(params) =>
                                <TextField {...params}
                                           value={pawnData.itemName}
                                           onChange={(e) => {
                                               setPawnData((prev) => ({
                                                   ...prev,
                                                   itemName: e.target.value,
                                               }));
                                               setItemNameValid(true)
                                           }}
                                           label="Tên món hàng"
                                           InputProps={{
                                               ...params.InputProps,
                                               type: 'search',
                                           }}
                                           error={!isItemNameValid}
                                />}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Trọng lượng tổng"
                            value={pawnData.totalWeight}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                setPawnData((prev) => ({
                                    ...prev,
                                    totalWeight: numericInput,
                                }));
                                weightChanges(numericInput, pawnData.gemWeight);
                            }}
                            fullWidth
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),
                                inputMode: 'decimal',
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Trọng lượng hột"
                            value={pawnData.gemWeight}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                setPawnData((prev) => ({
                                    ...prev,
                                    gemWeight: numericInput,
                                }));
                                weightChanges(pawnData.totalWeight, numericInput);
                            }}
                            fullWidth
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),
                                inputMode: 'decimal',
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Trọng lượng VÀNG"
                            value={pawnData.itemWeight}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                setPawnData((prev) => ({
                                    ...prev,
                                    itemWeight: numericInput,
                                }));
                                setItemWeightValid(true);
                                calculateItemValue(numericInput, pawnData.itemType);
                            }}
                            fullWidth
                            InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                            error={!isItemWeightValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <Select
                            value={pawnData.itemType}
                            fullWidth
                            onChange={(e) => {
                                setPawnData((prev) => ({
                                    ...prev,
                                    itemType: e.target.value,
                                }));
                                calculateItemValue(pawnData.itemType, e.target.value);
                            }}
                        >
                            {getSortedGoldType().map((goldType) => (
                                <MenuItem key={goldType.code} value={goldType.code}>
                                    {goldType.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <Select
                            value={pawnData.itemBrand}
                            fullWidth
                            onChange={(e) =>
                                setPawnData((prev) => ({
                                    ...prev,
                                    itemBrand: e.target.value,
                                }))
                            }
                        >
                            {getSortedGoldBrand().map((goldBrand) => (
                                <MenuItem key={goldBrand.code} value={goldBrand.code}>
                                    {goldBrand.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Số tiền cầm tối đa"
                            readOnly disabled
                            value={Number(pawnData.itemValue).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                setPawnData((prev) => ({
                                    ...prev,
                                    itemValue: numericInput,
                                }))
                            }}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">vnđ</InputAdornment>
                                ),
                                inputMode: 'numeric',
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <Autocomplete
                            freeSolo
                            value={!isNaN(pawnData.pawnAmount) ? Number(pawnData.pawnAmount).toLocaleString() : '0'}
                            onChange={handlePawnAmountSelect}
                            options={suggestPawnValue(undefined, undefined, pawnData.itemValue).map((option) => ({
                                label: Number(option).toLocaleString(),
                                value: parseInt(option),
                            }))}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    value={pawnData.pawnAmount ? Number(pawnData.pawnAmount).toLocaleString() : '0'}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                        setPawnData((prev) => ({
                                            ...prev,
                                            pawnAmount: numericInput,
                                        }));
                                        setPawnAmountValid(true);
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            const processSubmitData = async () => {
                                                await handleSavePrintButton();
                                            }
                                            processSubmitData();
                                        }
                                    }}
                                    fullWidth
                                    label="Số tiền cầm"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                    error={!isPawnAmountValid}
                                />}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                            <DatePicker label="Ngày cầm"
                                        value={pawnData.pawnDate}
                                        onChange={(date) => {
                                            const selectedDate = setUserTime(date);
                                            setPawnData((prev) => ({
                                                ...prev,
                                                pawnDate: selectedDate,
                                                pawnDueDate: convertDayjsDate(calculatePawnDueDate(selectedDate)),
                                            }));
                                        }}
                                        fullWidth
                                        format={DATE_SHORT_FORMAT}
                                        slotProps={{
                                            field: {shouldRespectLeadingZeros: true},
                                            textField: {fullWidth: true}
                                        }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                            <DatePicker label="Ngày đến hạn"
                                        value={pawnData.pawnDueDate}
                                        onChange={(date) => setPawnData((prev) => ({
                                            ...prev,
                                            pawnDueDate: setUserTime(date),
                                        }))}
                                        format={DATE_SHORT_FORMAT}
                                        slotProps={{
                                            field: {shouldRespectLeadingZeros: true},
                                            textField: {fullWidth: true}
                                        }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Lãi suất"
                            value={pawnData.interestRate}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                setPawnData((prev) => ({
                                    ...prev,
                                    interestRate: numericInput,
                                }))
                            }}
                            fullWidth
                            InputProps={{endAdornment: (<InputAdornment position="end">%</InputAdornment>),}}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <Select
                            value={pawnData.interestDaysPerMonth}
                            fullWidth
                            onChange={(e) => {
                                setPawnData((prev) => ({
                                    ...prev,
                                    interestDaysPerMonth: e.target.value,
                                }));
                            }}
                        >
                            {Object.values(InterestTypes).map((interestType) => (
                                <MenuItem key={interestType.code} value={interestType.code}>
                                    {interestType.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Ghi chú"
                            value={pawnData.itemDescription}
                            onChange={(e) =>
                                setPawnData((prev) => ({
                                    ...prev,
                                    itemDescription: e.target.value,
                                }))
                            }
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </CustomDialog>
            <QuickCreateCustomer
                open={isQuickCreateCustomerOpen}
                onClose={async () => {
                    setQuickCreateCustomerOpen(false);
                }}
                onCreateSuccess={handleQuickCreateCustomerSuccess}
                inputValue={pawnData.customerName}
            />
        </div>
    )
};

export default CreatePawn;



