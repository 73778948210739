import React, {useEffect, useState} from "react";
import {useAlert} from "../common/AlertProvider";
import {formatCurrency, getResponsiveWidth, handleSuccessMessage, readGoldWeightStamp} from "../constants";
import {randomId} from "@mui/x-data-grid-generator";
import {
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbarContainer
} from "@mui/x-data-grid";
import {Box, Button, Grid} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import InvoiceItemDetails from "./InvoiceItemDetails";

const invoiceItemDefault = {
    itemId: 0,
    invoiceId: 0,
    lineNumber: 1,
    jewelryId: 0,
    itemName: '',
    itemCode: '',
    unit: 'Chỉ',
    unitPrice: 0,
    quantity: 0,
    discount: 0,
    totalAmountWithoutTax: 0,
    taxPercentage: 0,
    itemTotalAmountWithTax: 0,
}
const InvoiceItem = ({invoiceData, setInvoiceData, viewMode = false}) => {
    const [invoiceItemsModes, setInvoiceItemsModes] = useState({});
    const {openAlert} = useAlert();
    const [isEditable, setEditable] = useState(true);
    useEffect(() => {
        if (viewMode) {
            setEditable(false);
        } else {
            setEditable(true);
        }
    }, [viewMode]);

    function EditToolbar() {
        const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
        const addInvoiceItem = () => {
            setOpenDetailsDialog(true);
        };
        const handleAddItem = (item) => {
            const id = randomId();
            const indexNumber = invoiceData.itemInfo?.length + 1;
            const jewelryItem = {
                ...item,
                id,
                lineNumber: indexNumber,
                isNew: true
            }
            setInvoiceData((prevData) => ({
                ...prevData,
                itemInfo: [...prevData.itemInfo, jewelryItem],
            }))
            handleSuccessMessage(`Món hàng [${jewelryItem.itemName}] được thêm thành công`, openAlert);
        }
        return (
            <>
                <GridToolbarContainer>
                    <Grid container spacing={2}>
                        <Grid item container xs={getResponsiveWidth(12, 12)}>
                            <Button color="primary" size="small" onClick={() => addInvoiceItem()}
                                    disabled={!isEditable}>
                                Thêm món hàng mới
                            </Button>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
                <InvoiceItemDetails open={openDetailsDialog} onClose={() => setOpenDetailsDialog(false)}
                                    sourceItem={invoiceItemDefault} onItemAdded={handleAddItem}/>
            </>
        );
    }

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleSaveClick = (id) => () => {
        setInvoiceItemsModes({...invoiceItemsModes, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id) => () => {
        const curItems = invoiceData.itemInfo.filter((row) => row.id !== id);
        setInvoiceData((prev) => ({...prev, itemInfo: curItems}));
    };

    const handleCancelClick = (id) => () => {
        setInvoiceItemsModes({
            ...invoiceItemsModes,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = invoiceData.itemInfo.find((row) => row.id === id);
        if (editedRow.isNew) {
            const curItems = invoiceData.itemInfo.filter((row) => row.id !== id);
            setInvoiceData((prev) => ({...prev, itemInfo: curItems}));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        const curItems = invoiceData.itemInfo.map((row) => (row.id === newRow.id ? updatedRow : row));
        setInvoiceData((prev) => ({...prev, itemInfo: curItems}));
        return updatedRow;
    };

    const handleBuyItemRowModelChange = (newRowModesModel) => {
        setInvoiceItemsModes(newRowModesModel);
    };
    const itemColumns = [
        {
            field: 'actions',
            type: 'actions',
            align: 'center',
            headerName: 'Tùy chọn',
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = invoiceItemsModes[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Lưu"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Hủy"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Xóa"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        {field: 'lineNumber', headerName: 'Số TT', width: 100, editable: false},
        {field: 'itemName', headerName: 'Tên món hàng ', width: 200, editable: true},
        {
            field: 'quantity',
            headerName: 'TL Vàng',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            valueFormatter: (param) => {
                if (param.value === 0) {
                    return '';
                }
                return readGoldWeightStamp(param.value ? param.value : 0);
            },
        },
        {
            field: 'unit',
            headerName: 'Đ.vị tính',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            valueFormatter: (param) => {
                if (param.value === '') {
                    return '';
                }
                return 'Chỉ';
            },
        },
        {
            field: 'unitPrice',
            headerName: 'Đơn giá(vnđ/chỉ)',
            type: 'number',
            width: 180,
            align: 'left',
            headerAlign: 'left',
            valueFormatter: (param) => {
                if (param.value === 0) {
                    return '';
                }
                return formatCurrency(param.value ? param.value : 0);
            },
        },
        {
            field: 'itemTotalAmountWithTax',
            headerName: 'Thành tiền',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            valueFormatter: (param) => {
                return formatCurrency(param.value ? param.value : 0);
            },
        },
    ];
    const getItemColumns = () => {
        if (isEditable) return itemColumns;
        else return itemColumns.filter(column => column.field !== 'actions');
    };
    return (
        <>
            <Box sx={{width: 1}}>
                <DataGrid
                    rows={invoiceData.itemInfo}
                    columns={getItemColumns()}
                    editMode="row"
                    rowModesModel={invoiceItemsModes}
                    onRowModesModelChange={handleBuyItemRowModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    slots={{
                        toolbar: EditToolbar,
                    }}
                />
            </Box>
        </>
    );
}
export default InvoiceItem;
