import {Box, Button, Divider, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {fetchSystemInfo, getShopInfo} from "../constants";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import {isMobile} from "react-device-detect";
import AccountMenu from "../auth/AccountMenu";
import {usePermissions} from "../route/PermissionProvider";
import {hasPermission} from "../auth/auth";

const AppToolbar = ({menuItems, isAuthenticated, onDrawerToggle: handleDrawerToggle, onLogout: handleLogout}) => {
    const navigate = useNavigate();
    const permissions = usePermissions();
    const navigateDashboard = async () => {
        await fetchSystemInfo();
        navigate('/dashboard');
    };
    return (
        <>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{mr: 2, display: {sm: 'none'}}}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography variant={(isMobile) ? "h6" : "h4"} component="div" sx={{flexGrow: 1}}>
                    <span onClick={navigateDashboard}
                          style={{
                              textTransform: 'uppercase',
                              cursor: 'pointer'
                          }}>{getShopInfo().name ? getShopInfo().name : 'QUẢN LÝ TIỆM VÀNG'}</span>
                </Typography>
                {isAuthenticated && (<AccountMenu onLogout={handleLogout}/>)}
                <Divider/>
                <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                    {isAuthenticated && menuItems.filter(item => hasPermission(permissions, item.path)).map((item) => (
                        <Button key={item.label} color="inherit" component={Link} to={item.path}>
                            {item.label}
                        </Button>
                    ))}
                    {isAuthenticated && (
                        <Button color="inherit" onClick={handleLogout}>Thoát</Button>
                    )}
                </Box>
            </Toolbar>
        </>
    )
}

export default AppToolbar;
