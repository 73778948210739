import React, {useEffect, useState} from "react";
import axios from '../axiosInstance';
import {Box, Button, ButtonGroup, Grid} from "@mui/material";
import CreatePawn from "./CreatePawn";
import UpdatePawn from "./UpdatePawn";
import {
    calculateRedeemPawn,
    calculateSummary,
    exportPawns,
    findPawns,
    formattedPawnData,
    initDefaultForfeitedPawn,
    initDefaultPawn,
    initDefaultRedeemPawn,
    PAWN_ACTIONS,
    PAWN_COLUMNS,
    PAWN_DEFAULT_FILTER,
    PAWNS_API_ENDPOINT,
} from "./PawnConstants";
import CancelPawn from "./CancelPawn";
import RedeemPawn from "./RedeemPawn";
import ForfeitPawn from "./ForfeitPawn";
import PawnReceipt from "./PawnReceipt";
import RequestMoney from "./RequestMoney";
import ExtendPawn from "./ExtendPawn";
import {
    ADMINISTRATOR_USER_NAME,
    fetchSystemInfo,
    formatCustomerName,
    formatDateTime,
    getCurrentUser,
    getResponsiveWidth,
    handleApiError,
    handleErrorMessage,
    handleSuccessMessage,
    QUICK_ACTION_CREATE_PAWN,
    UNKNOWN_ACTIONS
} from "../constants";
import {handlePrinting} from "./PawnStamp";
import ReviewPawn from "./ReviewPawn";
import {useAlert} from "../common/AlertProvider";
import CustomDataGrid from "../common/CustomDataGrid";
import DropDownButton from "../common/DropDownButton";
import {useLocation, useNavigate} from "react-router-dom";
import {usePermissions} from "../route/PermissionProvider";
import UpdateCustomer from "../customer/UpdateCustomer";
import {DEFAULT_CUSTOMER_DATA, getCustomerDetailsById} from "../customer/CustomerConstants";
import YesNoAlertDialog from "../common/YesNoAlertDialog";

const PawnGrid = () => {
    const {openAlert} = useAlert();
    const [pawns, setPawns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [selectedPawn, setSelectedPawn] = useState({});
    const [openUpdatePawnDialog, setOpenUpdatePawnDialog] = useState(false);
    const [openDeletePawnsDialog, setOpenDeletePawnsDialog] = useState(false);
    const [openCancelPawnDialog, setOpenCancelPawnDialog] = useState(false);
    const [openForfeitDialog, setOpenForfeitDialog] = useState(false);
    const [openRedeemDialog, setOpenRedeemDialog] = useState(false);
    const [openPrintPawnReceiptDiaLog, setOpenPrintPawnReceiptDiaLog] = useState(false);
    const [openRequestMoneyDialog, setOpenRequestMoneyDialog] = useState(false);
    const [openExtendPawnDialog, setOpenExtendPawnDialog] = useState(false);
    const [pawnData, setPawnData] = useState(initDefaultPawn());
    const [redeemPawnData, setRedeemPawnData] = useState(initDefaultRedeemPawn());
    const [forfeitPawnData, setForfeitPawnData] = useState(initDefaultForfeitedPawn());
    const permissions = usePermissions();
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 100,
        totalRowCount: 100,
        summary: {},
    });
    const location = useLocation();
    const navigate = useNavigate();
    const [customerData, setCustomerData] = useState(DEFAULT_CUSTOMER_DATA);
    const handleSetSelectedRow = async (selectedRow, rows) => {
        try {
            const selectedPawnId = selectedRow.length === 1 ? selectedRow[0] : 0;
            const rowData = rows.find((row) => row.pawnId === selectedPawnId);
            setSelectedPawn(rowData);
        } catch (error) {
            console.log("Error at process selected item", selectedRow);
        }
    };
    const [selectedAction, setSelectedAction] = React.useState('VIEW');
    const [openReviewDialog, setOpenReviewDialog] = useState(false);
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [statusUpdateDialog, setStatusUpdateDialog] = useState(false);
    const [statusUpdateContent, setStatusUpdateContent] = useState('');
    const calculateForfeitDetails = async (pawnId) => {
        try {
            const redeemData = await calculateRedeemPawn(pawnId, new Date());
            setForfeitPawnData(formattedPawnData(redeemData));
            setOpenForfeitDialog(true);
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const getPawnByPawnId = async (pawnId) => {
        const response = await axios.get(`${PAWNS_API_ENDPOINT}/${pawnId}`);
        const pawn = formattedPawnData(response.data);
        setPawnData(pawn);
        return pawn;
    };
    const fetchPawnDetails = async (pawnId) => {
        try {
            setLoading(true);
            return getPawnByPawnId(pawnId);
        } catch (error) {
            handleApiError(error, openAlert)
        } finally {
            setLoading(false);
        }
    };
    const statusUpdateClick = async (pawnId) => {
        const item = await fetchPawnDetails(pawnId)
        if (item.visible) {
            setPawnData(prev => ({
                ...prev,
                visible: false,
            }));
            setStatusUpdateContent(`Đơn cầm [${item.pawnId}] đang ở trạng thái [hiển thị], Bạn muốn ẩn nó?`);
        } else {
            setPawnData(prev => ({
                ...prev,
                visible: true,
            }));
            setStatusUpdateContent(`Đơn cầm [${item.pawnId}] đang ở trạng thái [ẩn], Bạn muốn hiển thị nó?`)
        }
        setStatusUpdateDialog(true);
    }
    const fetchPawns = async (page, pageSize, reqFilter = undefined) => {
        try {
            setLoading(true);
            const searchRequest = reqFilter ? reqFilter : PAWN_DEFAULT_FILTER;
            const response = await findPawns({page: page, pageSize: pageSize}, searchRequest, undefined)
            const formattedPawns = response.content.map((pawn) => ({
                ...pawn,
                id: pawn.pawnId,
                itemWeight: pawn.itemWeight,
                gemWeight: pawn.gemWeight,
                interestRate: pawn.interestRate,
                pawnDate: pawn.pawnDate,
                pawnDueDate: pawn.pawnDueDate,
                pawnStatus: pawn.pawnStatus,
                status: pawn.pawnStatus,
                itemType: pawn.itemType,
                itemBrand: pawn.itemBrand,
                pawnAmount: pawn.pawnAmount,
                customerName: formatCustomerName(pawn.customer),
                customerId: pawn.customer?.customerId ? pawn.customer?.customerId : 0,
                itemValue: pawn.itemValue,
                createdAt: pawn.createdAt,
                updatedAt: pawn.updatedAt,
                interestDayOfMonth: pawn.interestDayOfMonth,
            }))
            await setPawns(formattedPawns);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalRowCount: response.totalElements,
                pageSize: response.size,
                totalPages: response.totalPages,
                currentPage: response.number,
                summary: calculateSummary(response.content, response.totalElements),
            }));
            return response;
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        const {quickAction} = location.state || {};
        if (quickAction === QUICK_ACTION_CREATE_PAWN) {
            const openCreatePawn = async () => {
                await fetchSystemInfo(openAlert);
                setOpenAddDialog(true);
                navigate(location.state, {quickAction: undefined});
            };
            openCreatePawn().then(() => console.log("create pawn actioned!"));
        }
        // eslint-disable-next-line
    }, [location.state]);

    useEffect(() => {
        const {filter} = location.state || {};
        const fetchPawnData = async () => {
            const requestBody = filter ? filter : PAWN_DEFAULT_FILTER;
            await fetchPawns(pagination.page, pagination.pageSize, requestBody);
        };
        fetchPawnData().then(() => console.log("pawns fetched!"));
        // eslint-disable-next-line
    }, [pagination.page, pagination.pageSize, location.state]);

    const handleFindById = async (pawnId) => {
        try {
            setLoading(true);
            return await getPawnByPawnId(pawnId);
        } catch (error) {
            if (error.response.status === 404) {
                handleErrorMessage(`Không tìm thấy đơn cầm nào có số: ${pawnId}`, openAlert);
            } else {
                handleApiError(error, openAlert);
            }
        } finally {
            setLoading(false);
        }
    };
    const handleSearch = async (searchFilter) => {
        navigate(location.state, {filter: undefined});
        const searchWord = searchFilter.searchWord;
        const isNumber = !isNaN(searchWord);
        if (isNumber) {
            const pawn = await handleFindById(searchWord);
            if (pawn) {
                setOpenReviewDialog(true);
                return;
            }
        }
        await fetchPawns(pagination.page, pagination.pageSize, searchFilter);
    };

    const handleRedeemClick = async (pawnId) => {
        try {
            const redeemData = await calculateRedeemPawn(pawnId, new Date());
            await setRedeemPawnData(redeemData);
            setOpenRedeemDialog(true);
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };

    const handleChangePage = (page, event) => {
        setPagination((prevPage) => ({
            ...prevPage,
            page: page?.page,
            pageSize: page?.pageSize,
        }));
    };

    const handleExtendPawnClick = async (pawnId) => {
        try {
            const redeemData = await calculateRedeemPawn(pawnId, new Date(), true);
            setRedeemPawnData(formattedPawnData(redeemData));
            setOpenExtendPawnDialog(true);
        } catch (error) {
            handleApiError(error, openAlert);
        }

    };
    const getCustomerDetails = async (pawn) => {
        const customerId = pawn.customer?.customerId || 0;
        setLoading(true);
        try {
            if (customerId !== 0) {
                const response = await getCustomerDetailsById(customerId);
                setCustomerData(response);
                setOpenCustomerDialog(true);
            }
        } catch (error) {
            console.error("Error while fetching data: ", error);
            handleApiError(error, openAlert)
        } finally {
            setLoading(false);
        }
    }
    const handleActionClick = async (action) => {
        try {
            setLoading(true);
            if (!selectedPawn) return;
            const pawnId = selectedPawn.pawnId;
            await fetchSystemInfo(openAlert);
            switch (action) {
                case 'UPDATED': {
                    await fetchPawnDetails(pawnId);
                    setOpenUpdatePawnDialog(true);
                    break;
                }
                case 'DELETE': {
                    setOpenDeletePawnsDialog(true);
                    break;
                }
                case 'CANCEL': {
                    setOpenCancelPawnDialog(true);
                    break;
                }
                case 'REDEEM': {
                    return await handleRedeemClick(pawnId);
                }
                case 'FORFEIT': {
                    return await calculateForfeitDetails(pawnId);
                }
                case 'EXTEND': {
                    return await handleExtendPawnClick(pawnId);
                }
                case 'RECEIPT': {
                    const response = await fetchPawnDetails(pawnId)
                    return await handlePrinting({...response});
                }
                case 'REQ_MONEY': {
                    await fetchPawnDetails(pawnId)
                    setOpenRequestMoneyDialog(true);
                    break;
                }
                case 'CUSTOMER': {
                    await getCustomerDetails(selectedPawn);
                    break;
                }
                case 'UPDATED_STATUS': {
                    return await statusUpdateClick(pawnId);
                }
                case 'UNKNOWN': {
                    break;
                }
                default: {
                    await fetchPawnDetails(pawnId);
                    setOpenReviewDialog(true);
                }
            }
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setLoading(false);
        }
    };

    const handleMenuItemClick = (event, selectedAction) => {
        setSelectedAction(selectedAction);
    };
    const getAvailableOptions = () => {
        const pawnStatus = selectedPawn ? selectedPawn.status : undefined;
        if (!pawnStatus || !permissions || !permissions['PAWN']) {
            return UNKNOWN_ACTIONS;
        }
        const actions = PAWN_ACTIONS.filter(action =>
                action.actStatus.includes(pawnStatus)
                && (
                    (permissions.PAWN?.includes(action.permission) &&
                        (!action.administration || (action.administration && ADMINISTRATOR_USER_NAME === getCurrentUser())))
                    || permissions.CUSTOMER?.includes(action.customerPermission)
                )
        );
        if (actions.length > 0 && !actions.find(action => action.code === selectedAction)) {
            setSelectedAction(actions[0].code);
        } else if (actions.length === 0) {
            return UNKNOWN_ACTIONS;
        }
        return actions;
    }
    const disableButton = () => {
        const pawnStatus = selectedPawn ? selectedPawn.status : undefined;
        return !pawnStatus;
    }
    const exportExcelFile = async (page, pageSize, reqFilter = undefined) => {
        try {
            const searchRequest = reqFilter ? reqFilter : {pawnStatuses: ["PAWNED"]}
            const response = await exportPawns({page: page, pageSize: pageSize}, searchRequest, undefined)
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pawn_data_${formatDateTime()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            handleErrorMessage("Không thể xuất dữ liệu Excel lúc này. Thử lại sau!", openAlert);
        }
    };
    const onClose = async () => {
        await fetchPawns(pagination.page, pagination.pageSize);
    }
    const processUpdateStatus = async () => {
        try {
            await axios.patch(`${PAWNS_API_ENDPOINT}/${pawnData.pawnId}/visible`, pawnData);
            handleSuccessMessage(`Thay đổi thành công đơn cầm [${pawnData.pawnId}]`, openAlert);
            await onClose();
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setStatusUpdateDialog(false);
        }
    };
    const processDeletePawns = async () => {
        try {
            const selectedPawnIds = selectedPawn ? [selectedPawn.pawnId] : [];
            await axios.delete(PAWNS_API_ENDPOINT, {
                data: selectedPawnIds,
            });
            handleSuccessMessage('Đơn cầm [' + selectedPawnIds + '] được xóa thành công', openAlert);
            await onClose();
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setOpenDeletePawnsDialog(false);
        }
    };
    return (
        <div>
            <Box>
                <Grid spacing={2} sx={{paddingBottom: 2}} container>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        {permissions.PAWN?.includes('CREATE') && (
                            <ButtonGroup variant="contained" fullWidth sx={{minWidth: 170, marginBottom: 1}}>
                                <Button onClick={async () => {
                                    await fetchSystemInfo(openAlert);
                                    setOpenAddDialog(true)
                                }} fullWidth>
                                    Tạo mới
                                </Button>
                            </ButtonGroup>)}
                    </Grid>

                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <DropDownButton
                            fullWidth={true}
                            disabled={disableButton()}
                            options={getAvailableOptions()}
                            action={selectedAction}
                            handleActionClick={handleActionClick}
                            handleMenuItemClick={handleMenuItemClick}
                            isLoading={loading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <CustomDataGrid
                rows={pawns}
                pagination={pagination}
                loading={loading}
                setSelectedRows={handleSetSelectedRow}
                columns={PAWN_COLUMNS}
                onSearch={handleSearch}
                onPaginationModelChange={handleChangePage}
                onFindById={handleFindById}
                pageSummary={pagination.summary}
                onExportData={exportExcelFile}
            />
            <CreatePawn open={openAddDialog} onClose={async () => {
                setOpenAddDialog(false);
                await fetchPawns(pagination.page, pagination.pageSize);
            }}/>
            <UpdatePawn open={openUpdatePawnDialog} onClose={async () => {
                setOpenUpdatePawnDialog(false);
                await fetchPawns(pagination.page, pagination.pageSize);
            }} sourcePawn={pawnData}/>
            <PawnReceipt open={openPrintPawnReceiptDiaLog} onClose={() => {
                setOpenPrintPawnReceiptDiaLog(false);
            }} sourcePawn={pawnData}/>
            <CancelPawn
                open={openCancelPawnDialog}
                onClose={async () => {
                    setOpenCancelPawnDialog(false);
                    await fetchPawns(pagination.page, pagination.pageSize);
                }}
                selectedPawnId={(selectedPawn ? [selectedPawn.pawnId] : [])}
            />
            <ForfeitPawn open={openForfeitDialog} onClose={async () => {
                setOpenForfeitDialog(false);
                await fetchPawns(pagination.page, pagination.pageSize);
            }} sourcePawn={forfeitPawnData}/>
            <RedeemPawn open={openRedeemDialog} onClose={async () => {
                setOpenRedeemDialog(false);
                await fetchPawns(pagination.page, pagination.pageSize);
            }} sourcePawn={redeemPawnData}/>
            <RequestMoney open={openRequestMoneyDialog} onClose={async () => {
                setOpenRequestMoneyDialog(false);
                await fetchPawns(pagination.page, pagination.pageSize);
            }} sourcePawn={pawnData}/>
            <ExtendPawn open={openExtendPawnDialog} onClose={async () => {
                setOpenExtendPawnDialog(false);
                await fetchPawns(pagination.page, pagination.pageSize);
            }} sourcePawn={redeemPawnData}/>
            <ReviewPawn open={openReviewDialog} onClose={async () => {
                setOpenReviewDialog(false);
                await fetchPawns(pagination.page, pagination.pageSize);
            }} sourcePawn={pawnData}/>
            <UpdateCustomer open={openCustomerDialog} onClose={async () => {
                setOpenCustomerDialog(false);
            }} sourceCustomer={customerData}/>
            <YesNoAlertDialog open={openDeletePawnsDialog} onClose={() => {
                setOpenDeletePawnsDialog(false);
            }}
                              content={`Xóa đơn cầm: [${(selectedPawn ? [selectedPawn.pawnId] : []).join(", ")}]?`}
                              onAccept={processDeletePawns}/>
            <YesNoAlertDialog open={statusUpdateDialog} onClose={() => {
                setStatusUpdateDialog(false);
            }}
                              content={statusUpdateContent}
                              onAccept={processUpdateStatus}/>
        </div>
    );
}
export default PawnGrid;
