import {connect} from "react-redux";
import {loadRecentActivities} from "./dashboardThunks";
import React, {useEffect} from "react";
import {endOfToday, startOfToday} from "date-fns";
import Typography from "@mui/material/Typography";
import {convertDayjsDate, DATE_FULL_FORMAT, formatCurrency, readGoldWeightUnits} from "../constants";
import {CircularProgress} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Divider from "@mui/material/Divider";

const translateActivityDetails = (activities) => {
    return activities.map(activity => {
        const formattedDate = convertDayjsDate(activity.createdAt).format(DATE_FULL_FORMAT);
        const activityDetailMap = {
            PAWN_CREATED: `Người dùng '${activity.username}' tạo đơn cầm '${activity.refId}', số tiền '${formatCurrency(activity.amount)}', lúc '${formattedDate}'.`,
            PAWN_EXTEND: `Người dùng '${activity.username}' gia hạn đơn cầm '${activity.refId}', số tiền '${formatCurrency(activity.amount)}', lúc '${formattedDate}'.`,
            PAWN_CANCEL: `Người dùng '${activity.username}' hủy đơn cầm '${activity.refId}', số tiền '${formatCurrency(activity.amount)}', lúc '${formattedDate}'.`,
            PAWN_FORFEIT: `Người dùng '${activity.username}' thanh lý đơn cầm '${activity.refId}', số tiền '${formatCurrency(activity.amount)}', lúc '${formattedDate}'.`,
            PAWN_REQUEST_MONEY: `Người dùng '${activity.username}' yêu cầu tiền cho đơn cầm '${activity.refId}', số tiền '${formatCurrency(activity.amount)}', lúc '${formattedDate}'.`,
            PAWN_REDEEMED: `Người dùng '${activity.username}' chuộc đơn cầm '${activity.refId}', số tiền '${formatCurrency(activity.amount)}', lúc '${formattedDate}'.`,
            PAWN_UPDATED: `Người dùng '${activity.username}' cập nhật đơn cầm '${activity.refId}', số tiền '${formatCurrency(activity.amount)}', lúc '${formattedDate}'.`,
            ORDER_CREATED: `Người dùng '${activity.username}' tạo đơn hàng '${activity.refId}', lúc '${formattedDate}'.`,
            ORDER_UPDATED: `Người dùng '${activity.username}' cập nhật đơn hàng '${activity.refId}', lúc '${formattedDate}'.`,
            JEWELRY_UPDATED: `Người dùng '${activity.username}' cập món hàng '${activity.refId}', trọng lượng '${readGoldWeightUnits(activity.weight)}', lúc '${formattedDate}'.`,
            JEWELRY_CREATED: `Người dùng '${activity.username}' tạo món hàng '${activity.refId}', trọng lượng '${readGoldWeightUnits(activity.weight)}', lúc '${formattedDate}'.`,
            CUSTOMER_CREATED: `Người dùng '${activity.username}' tạo khách hàng '${activity.refId}', lúc '${formattedDate}'.`,
            CUSTOMER_UPDATED: `Người dùng '${activity.username}' cập nhật khách hàng '${activity.refId}', lúc '${formattedDate}'.`,
        };
        return activityDetailMap[activity.type] || `Người dùng '${activity.username}' thực hiện hoạt động không xác định với ID '${activity.refId}' lúc '${formattedDate}'`;
    });
};

const RecentActivity = ({activities = [], isLoading, startLoadingData}) => {
    useEffect(() => {
        const requestBody = {fromDate: startOfToday(), toDate: endOfToday()}
        startLoadingData({
            page: 0,
            pageSize: 10,
        }, requestBody);
    }, [startLoadingData]);
    const translatedActivities = translateActivityDetails(activities);
    return (
        <>
            <Typography variant="h6" gutterBottom>
               Hoạt động gần đây
            </Typography>
            {(isLoading) ? (
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            ) : (<>
                    {translatedActivities.map((detail, index) => (
                        <React.Fragment key={index}>
                            <Typography display="block">{detail}</Typography>
                            <Divider/>
                        </React.Fragment>
                    ))}
                </>
            )}

        </>
    )
}

const mapStateToProps = state => ({
    isLoading: state.isLoading,
    activities: state.activities,
});

const mapDispatchToProps = dispatch => ({
    startLoadingData: pagination => dispatch(loadRecentActivities(pagination)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RecentActivity);
