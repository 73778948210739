import axios from "../axiosInstance";
import {API_ENDPOINT} from "../constants";
import {
    loadRecentActivitiesFailure,
    loadRecentActivitiesInProgress,
    loadRecentActivitiesSuccess
} from "./dashboardActions";


export const loadRecentActivities = (pagination = {
    page: 0,
    pageSize: 100,
}, requestBody = {}) => async (dispatch, getState) => {
    try {
        dispatch(loadRecentActivitiesInProgress());
        const response = await axios.post(API_ENDPOINT + `/activities/find?page=${pagination.page}&size=${pagination.pageSize}&sort=id,desc`, requestBody);
        const activities = await response.data.content.map((activity) => ({
            ...activity,
        }));
        dispatch(loadRecentActivitiesSuccess(activities));
    } catch (e) {
        const error = {severity: 'error', message: 'Error while loading data!!!'};
        dispatch(loadRecentActivitiesFailure(error));
    }
}
