import Snackbar from "@mui/material/Snackbar";
import {AUTO_HIDE_DURATION} from "../constants";
import MuiAlert from "@mui/material/Alert";
import React, {useEffect, useState} from "react";

const SystemAlert = ({
                         open = false,
                         severity = 'success',
                         message = 'Dữ liệu được cập nhật thành công!',
                         origin = {vertical: 'top', horizontal: 'center'},
                         hideDuration = AUTO_HIDE_DURATION,
                         onClose = () => {
                         }
                     }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(open);
    const [snackbarSeverity, setSnackbarSeverity] = useState(severity);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    useEffect(() => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(open);
    }, [open, severity, message]);

    return (
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={hideDuration}
            onClose={onClose}
            anchorOrigin={origin}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={onClose}
                severity={snackbarSeverity}
            >
                {snackbarMessage}
            </MuiAlert>
        </Snackbar>
    )
}
export default SystemAlert;
