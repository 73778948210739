import {IconButton, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {useRef, useState} from "react";
import CachedIcon from "@mui/icons-material/Cached";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {Html5QrcodeScanner} from "html5-qrcode";

const SearchToolbar = ({onSearch: onSearchFunction, onFindById: onFindByIdFunction}) => {
    const [filter, setFilter] = useState({searchWord: ''});
    const [isScanning, setIsScanning] = useState(false);
    const cameraRef = useRef(null);
    const handleSearchClick = async () => {
        await onSearchFunction(filter);
    };
    const handleScanClick = () => {
        setIsScanning(true);
        const qrCodeScanner = new Html5QrcodeScanner(
            "camera-container",
            {
                fps: 10,
                qrbox: 250,
                disableFlip: false,
                rememberLastUsedCamera: true,
                aspectRatio: 1.0,
                videoConstraints: {
                    facingMode: "environment",
                },
            },
        );
        qrCodeScanner.render(
            async (text) => {
                qrCodeScanner.clear().then(() => {
                    setIsScanning(false);
                }).catch((err) => {
                    console.error(`Error stopping scanner: ${err}`);
                    setIsScanning(false);
                });
                setFilter((prev) => ({
                    ...prev,
                    searchWord: text,
                }));
                await onSearchFunction({searchWord: text});
            },
            (error) => {
                //Do nothing
            }
        );
    };
    const handleReloadClick = () => {
        window.location.reload();
    };
    return (
        <div>
            <TextField
                label="Nhập vào để tìm kiếm"
                type="search"
                size="small"
                variant="standard"
                value={filter.searchWord}
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        const fetchData = async () => {
                            await handleSearchClick();
                        }
                        fetchData();
                    }
                }}
                onChange={(e) => {
                    setFilter((prev) => ({
                        ...prev,
                        searchWord: e.target.value,
                    }));
                }}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon style={{cursor: 'pointer'}} onClick={handleSearchClick}/>
                            <IconButton onClick={isScanning ? handleReloadClick : handleScanClick}>
                                {isScanning ? <CachedIcon/> : <QrCodeIcon/>}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <div id="camera-container" ref={cameraRef} style={{width: '100%', height: isScanning ? 'auto' : '0'}}/>
        </div>

    )
}
export default SearchToolbar;
